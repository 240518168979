export function BigTitle(props : {title: string, color?: string}) : JSX.Element {
    return <>
        <div className={"text-"+(props.color ? props.color : "succ2 ")
            +" font-medium font-roboto md:text-5xl text-3xl flex place-content-center mb-5 md:mb-10 mt-5"}>
                {props.title ? props.title : " "}
        </div>
    </>
}

export function PageTitle(props: any): JSX.Element {
    return <div className="text-succ2 text-4xl md:text-5xl lg:text-6xl text-center mt-10 mb-10">
        {props.children}
    </div>
}

export function PageDescription(props : {title: string | JSX.Element, description: string | JSX.Element, 
        titleColor?: string, titleSize?: string, descrSize?: string}) : JSX.Element {
    
    let titleColor = props.titleColor && props.titleColor !== "" ? props.titleColor : "succ2"
    let titleSize = props.titleSize && props.titleSize !== "" ? "text-"+props.titleSize : "text-4xl md:text-6xl"
    let descrSize = props.descrSize && props.descrSize !== "" ? "text-"+props.descrSize : "text-lg md:text-xl"

    return <>
        <div className="font-roboto font-thin text-left">
            <div className={"text-"+titleColor+" "+titleSize+""}>{props.title}</div>
            <div className={"text-tertiary text-"+descrSize+""}>{props.description}</div>
        </div>
    </>
}

export function DexxSubBalance(props: {label: string, color: string, balance: string}): JSX.Element {
    return <>
        <div>
            <div className={"text-"+props.color+" uppercase"}>
                {props.label}
            </div>
            <DexxBalance className="text-2xl">
                &euro;{props.balance}
            </DexxBalance>
        </div>
    </>
}

export function TitleColor1(props: {children?: any, className?: string}) {
    return <div className={"text-succ2 font-roboto "+(props.className ? props.className : "")}>{props.children ? props.children : ""}</div>
}

export function TitleColorWarning(props: {children?: any, className?: string}) {
    return <div className={"text-warning font-roboto "+(props.className ? props.className : "")}>{props.children ? props.children : ""}</div>
}

export function TabTitle(props: {children?: any, className?: string}) {
    return <div className={"text-gray6 uppercase font-roboto font-medium md:text-xl text-lg "+
        (props.className ? props.className : "")}>{props.children ? props.children : " "}</div>
}

export function FaqQuestion(props: {content?: any, className?: string}) {
    return <div className={"text-succ2 text-base md:text-xl md:mt-10 md:mb-3 mt-4 mb-1"+
        (props.className ? props.className : "")}>{props.content ? props.content : ""}</div>
}

export function FaqAnswer(props: {content?: any, className?: string}) {
    return <div className={"text-white text-base md:text-xl mt-3 "+
        (props.className ? props.className : "")}>{props.content ? props.content : ""}</div>
}

export function DexxBalance(props: {children?: any, className?: string}) {
    return <div className={"font-urbanist text-white "+
        (props.className ? props.className : "")}>{props.children ? props.children : ""}</div>
}