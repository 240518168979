import { useEffect, useState } from "react";
import DexxCard from "./DexxCard";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import Cookies from 'universal-cookie';
import { OptimizedImage, SortedImages, sortImages } from "../../functions/images";
import { euroFormat } from "../../utils";

const cookies = new Cookies();

interface AssetInfo {
    issuer_name: string
    asset_type: string
    extra_info: {
        art_investing?: any
        art_lending?: any
    }
}

export default function SecondaryMarketCard(props: { token: any, hide: boolean }): JSX.Element {
    const token = props.token
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [tokenInfo, setTokenInfo] = useState<any>()
    const [assetInfo, setAssetInfo] = useState<AssetInfo>({ issuer_name: "", extra_info: {}, asset_type: "" })
    const [media, setMedia] = useState<any>()
    const [cardImage, setCardImage] = useState<OptimizedImage>()
    const [cardImageURLs, setCardImageURLs] = useState<OptimizedImage>()
    const isLogged = cookies.get("logged_in")

    useEffect(() => {
        actions.getTokenInfoByTokenUUID(token.token_uuid, setTokenInfo)
    }, [])

    useEffect(() => {
        if (tokenInfo)
            actions.getAssetInfo(tokenInfo.asset_uuid, setAssetInfo, isLogged)
    }, [tokenInfo])

    useEffect(() => {
        if (tokenInfo)
            actions.getAssetMedia(tokenInfo.asset_uuid, setMedia)
    }, [tokenInfo])

    useEffect(() => {
        if (media) {
            let sortedImages: SortedImages = sortImages(media.filter((m: any) => m.media_type_name === "Image"))
            if (sortedImages.sorted_images.length > 0)
                setCardImage(sortedImages.sorted_images[0])
            else if (sortedImages.others.length > 0)
                setCardImage({
                    filename_big: sortedImages.others[0],
                    filename_small: "", name: sortedImages.others[0],
                    url_big: "", url_small: ""
                })
            else
                setCardImage(undefined)
            actions.getImagesURL(cardImage, setCardImageURLs)
        }
    }, [media])

    useEffect(() => {
        if (cardImage)
            actions.getImagesURL(cardImage, setCardImageURLs)
    }, [cardImage])

    return <>
        {props.hide ? <></> : <>
            <DexxCard titles={{
                top: (assetInfo ? (token.token_category === "ART" ? (
                    assetInfo.asset_type === "ART Investing" ?
                        assetInfo.extra_info.art_investing?.sub_assets[0]?.artist :
                        assetInfo.extra_info.art_lending?.sub_assets[0]?.artist
                ) :
                    (assetInfo.issuer_name ? assetInfo.issuer_name : <br />)) : <br />),
                middle: token.token_name,
                bottom: token.token_ticker
            }} topContent={{
                leftTop: token.token_category,
                leftBottom: {
                    content: tokenInfo?.lp_flag ? "LP: active" : "LP: inactive",
                    color: tokenInfo?.lp_flag ? "green-500" : "error1"
                },
                rightTop: {
                    content: <br/>,
                    color: ""
                },
                rightBottom: {
                    content: tokenInfo?.p2p_flag ? "P2P: active" : "P2P: inactive",
                    color: tokenInfo?.p2p_flag ? "green-500" : "error1"
                },
            }} bottomContent={{
                leftTop: "LAST PRICE",
                leftBottom: tokenInfo?.price ? euroFormat(tokenInfo?.price) + " EUR" : "N/A"
            }} linkTo={"/token/" + token.token_uuid}
                buttonSpecs={{
                    labelActive: "BUY/SELL",
                    active: true,
                    labelInactive: ""
                }}
                cardImageURLs={cardImageURLs} />
        </>}</>
}
