import GenericTab from "../utils/GenericTab";
import TabInnerTitle from "../utils/TabInnerTitle";
import ListOfInformation from "../utils/ListOfInformation";
import { euroFormat, euroQtyFormat, formatDate, formatDatetime } from "../../../utils";
import TableShadow from "../../light/TableShadow";
import { DexxButton } from "../../light/LightButton";
import { Link } from "react-router-dom";

export default function IssuanceStatusTab(props: { tokenInfo: any, stats: any, primarySales: any[], offset: number }): JSX.Element {
    var assetLabels = [], assetContents = []
    var tokenLabels = [], tokenContents = []
    if (props.stats?.asset_history) {
        assetLabels = props.stats.asset_history.map((item: any) => item.status)
        assetContents = props.stats.asset_history.map((item: any) => formatDatetime(item.timestamp))
    } else {
        assetLabels = [""]
        assetContents = ["No data"]
    }
    if (props.stats?.token_history) {
        tokenLabels = props.stats.token_history.map((item: any) => item.status)
        tokenContents = props.stats.token_history.map((item: any) => formatDate(item.timestamp, props.offset))
    } else {
        tokenLabels = [""]
        tokenContents = ["No data"]
    }
    const hdsPrimarySales = ["ticker", "status", "start", "end", "listed supply",
        "circulating supply", "on sale", "price"]
    const psContent = props.primarySales ?
        props.primarySales.map(t => [t.token_ticker, t.status, formatDate(t.date_start, props.offset),
            formatDate(t.date_end, props.offset), euroQtyFormat(t.total_supply), euroQtyFormat(t.shares_issued),
            euroQtyFormat(t.total_supply - t.shares_issued), euroFormat(t.price),
            <Link to={"/issuer/primary-sale/"+t.uuid}><DexxButton label={"PS Details"} noBorder 
                color1="succ2" color2="primary" color3="succ4" color4="white"
                className="rounded-lg px-2 bg-succ2 font-normal border-0" /></Link>]
        ) : []

    return <>
        <GenericTab>
            <div className="grid md:grid-cols-2 gap-4">
                <div className="col-span-2 md:col-span-1">
                    <TabInnerTitle title={"Asset History"} />
                    <div className="gap-2 grid">
                        <ListOfInformation labels={assetLabels} contents={assetContents} />
                    </div>
                </div>
                <div className="col-span-2 md:col-span-1">
                    <TabInnerTitle title={"Token History"} />
                    <div className="gap-2 grid">
                        <ListOfInformation labels={tokenLabels} contents={tokenContents} />
                    </div>
                </div>
                <div className="col-span-2">
                    <TabInnerTitle title={"Primary Sales"} />
                    <TableShadow headers={hdsPrimarySales} rows={psContent} />
                </div>
            </div>
        </GenericTab>
    </>
}