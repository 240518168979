import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { defaultBalance, defaultTransactionsList, defaultBankTransactionsList } from "../PoolContext";
import { actionCreators, State } from "../state";
import { euroFormat, euroQtyFormat, euroFeesFormat, formatDate, formatDatetimeOffset, quantityFormat } from "../utils";
import DepositWithdrawInterface from "../components/DepositWithdrawInterface";
import { DexxTableTitle } from "../components/HeaderButton";
import { BackButton, DexxButton } from "../components/light/LightButton";
import { Link } from 'react-router-dom'
import { DexxPopup } from "../components/Popup";
import { BlueLine, DexxPieChart } from "../components/utils/DexxElements";
import { DexxBalance, DexxSubBalance } from "../components/typography/Typogtaphy";
import TableAndCSVDownload from "../components/TableAndCSVDownload";
import { Cell, nextSortStatus, SortingMethod, SortStatus } from "../components/TransactionTables/TransactionTypes";
import { TransactionTable } from "../components/TransactionTables/TransactionTable";
import TransactionContent from "../components/TransactionTables/TransactionContent";

export default function PortfolioRoute(): JSX.Element {
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const transactionsListState = useSelector((state: State) => state.transactions)
    const [primarySaleList, setPrimarySaleList] = useState<any>(defaultTransactionsList.primary_sales)
    const [liquidationsList, setLiquidationsList] = useState<any>(defaultTransactionsList.buybacks)
    const [liquidityPoolsList, setLiquidityPoolsList] = useState<any>(defaultTransactionsList.liquidity_pools)
    const [p2pList, setP2pList] = useState<any>(defaultTransactionsList.peer_to_peer)
    const [closeList, setCloseList] = useState<any>(defaultTransactionsList.close_all)
    const [bankTxnList, setBankTxnList] = useState(defaultBankTransactionsList.bank_transactions)
    const [balance, setBalance] = useState(defaultBalance)
    const [shares, setShares] = useState<any[]>([])
    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [resetAmountPopup, setResetAmountPopup] = useState<boolean>(false)
    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const userCtx = detailAccountState.payload
    let isDefaultPopup = userCtx?.popup_withdraw_type ? false : true
    const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0
    
    const [rsSecurities, setRsSecurities] = useState<any[]>([])
    const [rsBank, setRsBank] = useState<any[]>([])
    const [rsLiquidated, setRsLiquidated] = useState<any[]>([])
    const [rsLiquidity, setRsLiquidity] = useState<any[]>([])

    const [rsSecuritiesFormatted, setRsSecuritiesFormatted] = useState<any[]>([])
    const [rsBankFormatted, setRsBankFormatted] = useState<any[]>([])
    const [rsLiquidatedFormatted, setRsLiquidatedFormatted] = useState<any[]>([])
    const [rsLiquidityFormatted, setRsLiquidityFormatted] = useState<any[]>([])

    const [sortBankBy, setSortBanktBy] = useState<number>(0)
    const [sortBankDESC, setSortBankDESC] = useState<boolean>(true)

    const [callsControl, setCallsControl] = useState<boolean>(false)

    const [sortBy, setSortBy] = useState<{ id: string, mode: SortStatus }>({ id: "date", mode: "normal" })
    const [filters, setFilters] = useState<{ id: string, value: string }[]>([])
    const [hiddenColumns, setHiddenColumns] = useState<boolean>(true)
    const [tableRows, setTableRows] = useState<any[][]>([])

    const headersSec = ["Asset name", "Cat", "Ticker", "Total Quantity", "Available Quantity", "Price", "Value", "Portfolio weight", ""/*, ""*/]
    const alignmentSec = ["left", "left", "center", "right", "right", "right", "right", "right", "center"/*, ""*/]
    const formattedHeaderTransactions = ["Date", "Status", "Response", "Cat", "Type of Security Token", "Token name", "Ticker",
        "N. of Tokens", "Price", "Fees", "Fees %", "Total", "Trans Type", "Counterpart", "Hash on Blockchain",]
    const headersLiquidations = ["Date", "Status", "Cat", "Type of Security Token", "Token name", "Ticker",
        "N. of Tokens", "Price per unit", "Fees", "Value", "Total", "Trans Type", "Counterpart", "Hash on Blockchain", ""]
    const alignmentLiquidations = ["center", "left", "left", "left", "left", "center",
        "right", "right", "right", "right", "right", "left", "left", "left", "center"]

    const headersBankTxns = [
        { name: "Trans Type", sort: () => { sortRows((a: any, b: any) => ((a[0] > b[0]) ? 1 : -1), 0, rsBank, setRsBank, sortBankBy, setSortBanktBy, sortBankDESC, setSortBankDESC, [{ index: 2, format: "date" }, { index: 4, format: "date" }]) } },
        { name: "Status", sort: () => { sortRows((a: any, b: any) => ((a[1] > b[1]) ? 1 : -1), 1, rsBank, setRsBank, sortBankBy, setSortBanktBy, sortBankDESC, setSortBankDESC, [{ index: 2, format: "date" }, { index: 4, format: "date" }]) } },
        { name: "Date Request", sort: () => { sortRows((a: any, b: any) => new Date(a[2]) < new Date(b[2]) ? 1 : -1, 2, rsBank, setRsBank, sortBankBy, setSortBanktBy, sortBankDESC, setSortBankDESC, [{ index: 2, format: "date" }, { index: 4, format: "date" }]) } },
        "Requested Amount",
        { name: "Date Executed", sort: () => { sortRows((a: any, b: any) => ((b[4] && b[4] !== "-") ? ((new Date(a[4]) < new Date(b[4])) ? 1 : ((new Date(b[4]) < new Date(a[4])) ? -1 : 0)) : -1), 4, rsBank, setRsBank, sortBankBy, setSortBanktBy, sortBankDESC, setSortBankDESC, [{ index: 2, format: "date" }, { index: 4, format: "date" }]) } },
        "Minted Amount", "Hash on Blockchain"]
    const formattedHeaderBankTransactions = ["Trans Type", "Status", "Date Request", "Requested Amount", "Date Executed", "Minted Amount", "Hash on Blockchain"]
    const alignmentBankTxns = ["left", "left", "center", "right", "center", "right", "left"]

    const headersShares = ["LP name", "Shares", "% Shares", ""]
    const alignmentShares = ["left", "right", "right", "center"]

    const rowsSec: any[] = [], rowsBankTxns: any[] = [], rowLiquidated: any[] = [], rowShares: any[] = []
    const formattedSec: any[] = [], formattedBankTxn: any[] = [], formattedLiquidations: any[] = [], formattedShares: any[] = []

    useEffect(() => {
        if (!callsControl) {
            actions.getTransactions()
            actions.getBankTransactions(setBankTxnList)
            actions.getActualBalance(setBalance)
            actions.getInvestorShares("", true, setShares)
            setCallsControl(true)
        }
    }, [])

    useEffect(() => {
        if (transactionsListState.payload) {
            setPrimarySaleList(transactionsListState.payload.primary_sales ? transactionsListState.payload.primary_sales : [])
            setLiquidationsList(transactionsListState.payload.buybacks ? transactionsListState.payload.buybacks : [])
            setLiquidityPoolsList(transactionsListState.payload.liquidity_pools ? transactionsListState.payload.liquidity_pools : [])
            setP2pList(transactionsListState.payload.peer_to_peer ? transactionsListState.payload.peer_to_peer : [])
            setCloseList(transactionsListState.payload.close_all ? transactionsListState.payload.close_all : [])
        }
    }, [transactionsListState.payload])

    useEffect(() => {
        if (balance) {
            if (balance.balance) {
                for (let r of balance.balance.security_entries) {
                    for (let e of r.class_balance) {
                        rowsSec.push([e.asset_name, r.class, e.symbol, euroQtyFormat(e.quantity),
                        euroQtyFormat(e.available_quantity), euroFormat(e.current_price) + " " + e.currency,
                        euroFormat(parseFloat(e.value)), euroFeesFormat(e.pct) + "%",
                        <Link className="grid justify-center" to={(!e.lp_flag && !e.p2p_flag) ?
                            "primary_sale/" + e.active_primary_sale : "/token/" + e.token_uuid}>
                            <DexxButton label={"Go to the Market"} className="px-2 rounded-lg "
                                fontClasses="text-xs" color2="primary" color1="white" />
                        </Link>])
                        formattedSec.push([e.asset_name, r.class, e.symbol, euroQtyFormat(e.quantity),
                        euroQtyFormat(e.available_quantity), euroFormat(e.current_price) + " " + e.currency,
                        euroFormat(parseFloat(e.value)), euroFeesFormat(e.pct) + "%"])
                        if (rowsSec.length) setRsSecurities(rowsSec)
                        if (formattedSec.length) setRsSecuritiesFormatted(formattedSec)
                    }
                }
            }
        }
    }, [balance])

    useEffect(() => {
        if (bankTxnList) {
            for (let t of bankTxnList) {
                rowsBankTxns.push([t.transaction_type, t.status, /*formatDate(t.requested_at, offset)*/t.requested_at, "€" + euroFormat(t.requested_amount),
                /*t.executed_at ? formatDate(t.executed_at, offset) : "-"*/t.executed_at, t.minted_amount ? "€" + euroFormat(t.minted_amount) : "-",
                <Link to={"/blockchain/transaction/" + t.trx_chain_identifier}>{t.trx_chain_identifier}</Link>])

                formattedBankTxn.push([t.transaction_type, t.status, formatDate(t.requested_at, offset), "€" + euroFormat(t.requested_amount),
                t.executed_at ? formatDate(t.executed_at, offset) : "-", t.minted_amount ? "€" + euroFormat(t.minted_amount) : "-", t.trx_chain_identifier])
            }

            rowsBankTxns.forEach((obj: any) => {
                obj[Object.keys(obj)[2]] = new Date(formatDate(obj[Object.keys(obj)[2]], offset)).toLocaleString().split(", ")[0];
                if (obj[Object.keys(obj)[4]] && obj[Object.keys(obj)[4]] !== "-")
                    obj[Object.keys(obj)[4]] = new Date(formatDate(obj[Object.keys(obj)[4]], offset)).toLocaleString().split(", ")[0];
                else obj[Object.keys(obj)[4]] = "-"
            });
            formattedBankTxn.forEach((obj: any) => {
                obj[Object.keys(obj)[2]] = new Date(formatDate(obj[Object.keys(obj)[2]], offset)).toLocaleString().split(", ")[0];
                if (obj[Object.keys(obj)[4]] !== "-") obj[Object.keys(obj)[4]] = new Date(formatDate(obj[Object.keys(obj)[4]], offset)).toLocaleString().split(", ")[0];
            });
            if (rowsBankTxns.length) setRsBank(rowsBankTxns)
            if (formattedBankTxn.length) setRsBankFormatted(formattedBankTxn)
        }
    }, [bankTxnList])

    useEffect(() => {
        if (liquidationsList) {
            for (let t of liquidationsList) {
                rowLiquidated.push([formatDate(t.timestamp, offset), t.status, t.asset_category, t.asset_type, t.asset_name, t.token_ticker,
                euroQtyFormat(t.quantity), "€" + euroFormat(t.price_per_unit), "€" + euroFormat(t.fees),
                "€" + euroFormat(t.gross_amount),
                t.txn_operation, t.txn_category, <Link to={"/blockchain/transaction/" + t.trx_identifier}>{t.trx_identifier}</Link>,
                <Link to={"/asset/" + t.asset_uuid} className="grid justify-center">
                    <DexxButton label={"details"} className="px-2 rounded-lg "
                        fontClasses="text-xs" color2="primary" color1="white" />
                </Link>])
                formattedLiquidations.push([formatDate(t.timestamp, offset), t.status, t.asset_category, t.asset_type, t.asset_name, t.token_ticker,
                euroQtyFormat(t.quantity), "€" + euroFormat(t.price_per_unit), "€" + euroFormat(t.fees),
                "€" + euroFormat(t.gross_amount),
                t.txn_operation, t.txn_category, t.trx_identifier])
            }

            rowLiquidated.forEach((obj: any) => {
                obj[Object.keys(obj)[0]] = new Date(obj[Object.keys(obj)[0]]).toLocaleString().split(", ")[0];
            });
            formattedLiquidations.forEach((obj: any) => {
                obj[Object.keys(obj)[0]] = new Date(obj[Object.keys(obj)[0]]).toLocaleString().split(", ")[0];
            });
            if (rowLiquidated.length) setRsLiquidated(rowLiquidated)
            if (formattedLiquidations.length) setRsLiquidatedFormatted(formattedLiquidations)
        }
    }, [liquidationsList])

    useEffect(() => {
        if (shares) {
            for (let t of shares) {
                rowShares.push([t.LPWE.lp_name, t.LPWE.qty_lp_token,
                euroFormat(100 * t.LPWE.qty_lp_token / t.LP.lp_token_supply) + "%",
                <Link to={"/token/" + t.LP.token_a_uuid + "/lp"} className="grid justify-center">
                    <DexxButton label={"details"} className="px-2 rounded-lg "
                        fontClasses="text-xs" color2="primary" color1="white" /></Link>])
                formattedShares.push([t.LPWE.lp_name, t.LPWE.qty_lp_token,
                euroFormat(100 * t.LPWE.qty_lp_token / t.LP.lp_token_supply) + "%"])
                if (rowShares.length) setRsLiquidity(rowShares)
                if (formattedShares.length) setRsLiquidityFormatted(formattedShares)
            }
        }
    }, [shares])

    function updateSortState(id: string) {
        var status: SortStatus = "normal"
        if (id === sortBy.id)
            status = nextSortStatus(sortBy.mode)
        setSortBy({ id: id, mode: status })
    }

    const transactionTable = new TransactionTable([
        {
            id: "date",
            header: "Date",
            content: (t: any, _: any) => formatDatetimeOffset(t.timestamp, offset),
            sort: () => { updateSortState("date") },
            alignment: "right"
        },
        {
            id: "status",
            header: "Status",
            content: (t: any, _: any) => t.status,
            sort: () => { updateSortState("status") },
            alignment: "left",
        },
        {
            id: "response",
            header: "Response",
            content: (t: any, type: any) => (type === "liq") ?
                (t.status === "Executed" ? "Success" : "Failed") :
                (t.response.OK === true ? "Success" :
                    (t.response.ERROR ? t.response.ERROR : "Failed")),
            sort: () => { updateSortState("response") },
            alignment: "left",
            hide: true
        },
        {
            id: "cat",
            header: "Cat",
            content: (t: any, type: any) => (type === "lp") ?
                t.asset_a_category : t.asset_category,
            sort: () => { updateSortState("cat") },
            alignment: "left"
        },
        {
            id: "typeToken",
            header: "Type of Sec. Token",
            content: (t: any, type: any) => (type === "lp") ?
                t.asset_a_type : t.asset_type,
            sort: () => { updateSortState("typeToken") },
            alignment: "left"
        },
        {
            id: "name",
            header: "Token name",
            content: (t: any, type: any) => (type === "lp") ?
                t.asset_a_name : t.asset_name,
            sort: () => { updateSortState("name") },
            alignment: "left",
            hide: true
        },
        {
            id: "ticker",
            header: "Ticker",
            content: (t: any, _: any) => t.token_ticker ?
                t.token_ticker : t.token_a_ticker,
            sort: () => { updateSortState("ticker") },
            alignment: "center"
        },
        {
            id: "nTokens",
            header: "N. of tokens",
            content: (t: any, type: any) => {
                switch (type) {
                    case "ps":
                        if (t.txn_operation === "buy")
                            return quantityFormat(t.quantity)
                        return ""
                    case "lp":
                        if (t.status === 'Executed') {
                            var amount_a
                            switch (t.txn_operation) {
                                case "swap buy":
                                case "swap sell":
                                    amount_a = t.response?.INFO?.quote_result?.amount_a
                                    break
                                case "add":
                                    amount_a = t.response?.INFO?.add_liquidity?.amount_a
                                    break
                                case "remove":
                                    amount_a = t.response?.INFO?.remove_liquidity?.amount_a
                                    break
                            }
                            return amount_a ? euroQtyFormat(amount_a) : ""
                        }
                        return ""
                    case "liq":
                        return t.quantity ? quantityFormat(t.quantity) : ""
                    case "p2p":
                        if (t.txn_operation === "fill")
                            return euroQtyFormat(t.amount_filled)
                        return quantityFormat(t.token_total_amount)
                }
                return ""
            },
            alignment: "right"
        },
        {
            id: "price",
            header: "Price",
            content: (t: any, type: any) => {
                switch (type) {
                    case "ps":
                    case "liq":
                        return "€" + euroFormat(t.price_per_unit)
                    case "lp":
                        var amount_b
                        switch (t.txn_operation) {
                            case "swap buy":
                            case "swap sell":
                                return (t.response?.INFO?.quote_result?.amount_b &&
                                    t.response?.INFO?.quote_result?.amount_a) ?
                                    "€" + euroFormat(parseFloat(t.response.INFO.quote_result.amount_b)
                                        / parseFloat(t.response.INFO.quote_result.amount_a)) : ""
                                break
                            case "add":
                                amount_b = t.response?.INFO?.add_liquidity?.amount_b ? t.response.INFO.add_liquidity.amount_b : ""
                                break
                            case "remove":
                                amount_b = t.response?.INFO?.remove_liquidity?.amount_b ? t.response.INFO.remove_liquidity.amount_b : ""
                                break
                        }
                        return amount_b !== "" ? "€" + euroFormat(amount_b) : ""
                    case "p2p":
                        return t.price_per_unit ? "€" + euroFormat(t.price_per_unit) : ""
                }
                return ""
            },
            alignment: "right"
        },
        {
            id: "fees",
            header: "Fees",
            content: (t: any, type: any) => {
                switch (type) {
                    case "ps":
                        return "€" + euroFormat(t.response.FEES?.buyer?.amount)
                    case "liq":
                        return "€" + euroFormat(t.fees)
                    case "lp":
                        if (t.status === 'Executed') {
                            switch (t.txn_operation) {
                                case "swap buy":
                                case "swap sell":
                                    var dexx = t.response?.INFO?.quote_result?.dexx_fees
                                    var lp = t.response?.INFO?.quote_result?.lp_fees
                                    return (dexx || lp) ? "€" + euroFormat(parseFloat(dexx) + parseFloat(lp)) : ""
                                case "add":
                                    return "€" + euroFormat("0")
                            }
                        }
                        return
                    case "p2p":
                        if (t.txn_operation === "fill") {
                            return t.response.OK === true ?
                                t.response.FEES?.buyer?.amount ?
                                    "€" + euroFormat(t.response.FEES?.buyer?.amount) :
                                    "€" + euroFormat(t.response.FEES?.seller?.amount)
                                : ""
                        }
                        return
                }
                return ""
            },
            alignment: "left",
            hide: true
        },
        {
            id: "feesPerc",
            header: "Fees %",
            content: (t: any, type: any) => {
                switch (type) {
                    case "ps":
                        return t.response.FEES?.buyer?.pct + "%"
                    case "liq":
                        return
                    case "lp":
                        if (t.status === 'Executed') {
                            switch (t.txn_operation) {
                                case "swap buy":
                                case "swap sell":
                                    return t.response.OK === true ? (parseFloat(t.response.FEES?.dexx_fee?.pct) +
                                        parseFloat(t.response.FEES?.lp_fee?.pct)) + "%" : ""
                            }
                        }
                        return
                    case "p2p":
                        if (t.txn_operation === "fill") {
                            return t.response.OK === true ?
                                t.response.FEES?.buyer?.pct ?
                                    t.response.FEES?.buyer?.pct + "%" :
                                    t.response.FEES?.seller?.pct + "%"
                                : ""
                        }
                        return
                }
                return ""
            },
            alignment: "left",
            hide: true
        },
        {
            id: "total",
            header: "Total",
            content: (t: any, type: any) => {
                switch (type) {
                    case "ps":
                    case "liq":
                        return "€" + euroFormat(t.gross_amount)
                    case "lp":
                        if (t.status === 'Executed') {
                            switch (t.txn_operation) {
                                case "swap buy":
                                case "swap sell":
                                    return t.response.INFO.quote_result.gross_amount ?
                                        "€" + euroFormat(t.response.INFO.quote_result.gross_amount) : ""
                                case "add":
                                    var amount_b = t.response?.INFO?.add_liquidity?.amount_b ?
                                        t.response.INFO.add_liquidity.amount_b : ""
                                    return amount_b !== "" ? "€" + euroFormat(parseFloat(amount_b) * 2) : ""
                                case "remove":
                                    var ab = t.response?.INFO?.remove_liquidity?.amount_b ?
                                        t.response.INFO.remove_liquidity.amount_b : ""
                                    return ab !== "" ? "€" + euroFormat(parseFloat(ab) * 2) : ""

                            }
                        }
                        return
                    case "p2p":
                        if (t.txn_operation === "fill") {
                            return t.response.OK === true ? "€" + euroFormat(t.gross_amount) : ""
                        }
                        return
                }
                return ""
            },
            alignment: "right"
        },
        {
            id: "transType",
            header: "Trans type",
            content: (t: any, type: any) => {
                if (type === "p2p" && t.txn_operation === "fill")
                    return ((t.is_client && !t.is_owner ?
                        "buyer - " :
                        !t.is_client && t.is_owner ?
                            "seller - " :
                            t.is_client && t.is_owner ?
                                "by myself - " :
                                !t.is_client && !t.is_owner ?
                                    "nobody - " : "error")
                        +
                        t.txn_operation + "(" + t.p2p_type + ")").toLowerCase()
                return ((t.txn_operation).replace("swap ", "") + 
                    (type === "p2p" ? "(" + t.p2p_type + ")" : "")).toLowerCase()
            },
            sort: () => { updateSortState("transType") },
            alignment: "left"
        },
        {
            id: "counterpart",
            header: "Counterpart",
            content: (t: any, _: any) => (t.txn_category).toLowerCase(),
            sort: () => { updateSortState("counterpart") },
            alignment: "left",
            hide: true
        },
        {
            id: "hash",
            header: "Hash on blockchain",
            content: (t: any, _: any) =>
                <Link to={"/blockchain/transaction/" + t.trx_identifier}>
                    {t.trx_identifier}
                </Link>,
            alignment: "left",
            toCsv: (t: any, _: any) => t.trx_identifier
        }

    ]);

    let tableContent2: any[][] = [
        ...transactionTable.getAllContent(primarySaleList, "ps", hiddenColumns),
        ...transactionTable.getAllContent(p2pList, "p2p", hiddenColumns),
        ...transactionTable.getAllContent(closeList, "close", hiddenColumns),
        ...transactionTable.getAllContent(liquidationsList, "liq", hiddenColumns),
        ...transactionTable.getAllContent(liquidityPoolsList, "lp", hiddenColumns)
    ]
    const sortingMethods: { id: string, sort: SortingMethod }[] = [
        {
            id: "date",
            sort: (a: Cell[], b: Cell[]) => {
                return (new Date(formatDatetimeOffset(a[tableContent.getIndex("date")], 0)) <
                    new Date(formatDatetimeOffset(b[tableContent.getIndex("date")], 0))) ? -1 : 1
            }
        },
        {
            id: "status",
            sort: (a: Cell[], b: Cell[]) => {
                const index: number = tableContent.getIndex("status")
                return (a[index] > b[index]) ? 1 : -1
            }
        },
        {
            id: "response",
            sort: (a: Cell[], b: Cell[]) => {
                const index: number = tableContent.getIndex("response")
                return (b[index]) ? ((a[index] > b[index]) ? 1 :
                    ((b[index] > a[index]) ? -1 : 0)) : -1
            }
        },
        {
            id: "cat",
            sort: (a: Cell[], b: Cell[]) => {
                const index: number = tableContent.getIndex("cat")
                return (a[index] > b[index]) ? 1 : -1
            }
        },
        {
            id: "typeToken",
            sort: (a: Cell[], b: Cell[]) => {
                const index: number = tableContent.getIndex("typeToken")
                return (a[index] > b[index]) ? 1 : -1
            }
        },
        {
            id: "name",
            sort: (a: Cell[], b: Cell[]) => {
                const index: number = tableContent.getIndex("name")
                return (a[index] > b[index]) ? 1 : -1
            }
        },
        {
            id: "ticker",
            sort: (a: Cell[], b: Cell[]) => {
                const index: number = tableContent.getIndex("ticker")
                return (a[index] > b[index]) ? 1 : -1
            }
        },
        {
            id: "transType",
            sort: (a: Cell[], b: Cell[]) => {
                const index: number = tableContent.getIndex("transType")
                return (a[index] > b[index]) ? 1 : -1
            }
        },
        {
            id: "counterpart",
            sort: (a: Cell[], b: Cell[]) => {
                const index: number = tableContent.getIndex("counterpart")
                return (a[index] > b[index]) ? 1 : -1
            }
        }
    ]

    let tableContent = new TransactionContent(tableContent2, sortingMethods)

    function sortRows(sortMethod: any, index: number,
        recordset: any[], setRecordset: any,
        sortBy: number, setSortBy: any,
        sortDESC: boolean, setSortDESC: any,
        arrayDates: any[]) {

        var tmp = [...recordset]

        var italianFormat = false
        const test = "02-13-2018"
        var check = new Date(test).toLocaleString();
        if (check.split("/")[0] === "13") italianFormat = true

        if (italianFormat) {
            arrayDates.forEach((el: any) => {
                tmp.forEach((obj: any) => {
                    if (obj[Object.keys(obj)[el.index]] && obj[Object.keys(obj)[el.index]] !== "-") {
                        if (el.format === "datetime") {
                            var dateSplit = obj[Object.keys(obj)[el.index]].split(", ")
                            var dateSplit2 = dateSplit[0].split("/")
                            var dateSplit3 = dateSplit[1].split(":")
                            var dateObject = new Date(parseInt(dateSplit2[2]), parseInt(dateSplit2[1]) - 1, parseInt(dateSplit2[0]), parseInt(dateSplit3[0]), parseInt(dateSplit3[1]), parseInt(dateSplit3[2]));
                        } else {
                            var dateSplit = obj[Object.keys(obj)[el.index]].split("/")
                            var dateObject = new Date(parseInt(dateSplit[2]), parseInt(dateSplit[1]) - 1, parseInt(dateSplit[0]));
                        }
                        obj[Object.keys(obj)[el.index]] = dateObject

                    } else obj[Object.keys(obj)[el.index]] = "-"
                });
            });
        }

        tmp.sort(sortMethod)

        if (index === sortBy) {
            if (sortDESC) tmp.reverse()
            setSortDESC(!sortDESC)
        } else {
            setSortBy(index)
            setSortDESC(true)
        }

        arrayDates.forEach((el: any) => {
            tmp.forEach((obj: any) => {
                if (obj[Object.keys(obj)[el.index]] && obj[Object.keys(obj)[el.index]] !== "-") {
                    if (el.format === "datetime")
                        obj[Object.keys(obj)[el.index]] = new Date(formatDatetimeOffset(obj[Object.keys(obj)[el.index]], 0/*offset*/)).toLocaleString();
                    else if (el.format === "date")
                        obj[Object.keys(obj)[el.index]] = new Date(formatDate(obj[Object.keys(obj)[el.index]], 0/*offset*/)).toLocaleString().split(", ")[0];
                } else obj[Object.keys(obj)[el.index]] = "-"
            });
        });
        setRecordset(tmp)


    }

    const headers = ['Asset / Security tokens', 'liquidated tokens', 'liquidity pool position',
        'transaction history', 'bank transaction history']
    const [toBeShown, setToBeShown] = useState(headers.map((_, i) => false))
    function handleClick(id: number) {
        var tmp = toBeShown.map((b, i) => i === id ? !b : b)
        setToBeShown(tmp)
    }

    let tableContentToCsv: any[][] = [
        ...transactionTable.getAllContent(primarySaleList, "ps", true, true),
        ...transactionTable.getAllContent(p2pList, "p2p", true, true),
        ...transactionTable.getAllContent(closeList, "close", true, true),
        ...transactionTable.getAllContent(liquidationsList, "liq", true, true),
        ...transactionTable.getAllContent(liquidityPoolsList, "lp", true, true)]

    useEffect(() => {
        let tableContent2: any[][] = [
            ...transactionTable.getAllContent(primarySaleList, "ps", hiddenColumns),
            ...transactionTable.getAllContent(p2pList, "p2p", hiddenColumns),
            ...transactionTable.getAllContent(closeList, "close", hiddenColumns),
            ...transactionTable.getAllContent(liquidationsList, "liq", hiddenColumns),
            ...transactionTable.getAllContent(liquidityPoolsList, "lp", hiddenColumns)]

        let tableContent = new TransactionContent(tableContent2, sortingMethods)
        setTableRows(tableContent.getRows(sortBy, filters))
    }, [hiddenColumns, sortBy, toBeShown, filters])

    return <>
        <DexxPopup show={showPopUp} title={""} msg={""} btnOk={false}
            close={() => { setShowPopUp(false); setResetAmountPopup(true) }}
            body={<DepositWithdrawInterface reset={resetAmountPopup} isDefault={isDefaultPopup}
                closeFunction={() => { setShowPopUp(false); setResetAmountPopup(true) }} />} />

        <div className="flex place-content-center">
            <div className="min-w-2/3">
                <BlueLine className="hidden md:block" />
                <div className="grid md:flex gap-8 justify-center">
                    <DexxPieChart data={[
                        {
                            title: 'Asset/Security Tokens', value: parseFloat(balance?.balance.total),
                            color: '#80C8C4'
                        },
                        {
                            title: 'Liquidity Pool Position', value: parseFloat(balance?.liquidity.total),
                            color: '#6772CD'
                        },
                        {
                            title: 'FIMART Euro Voucher', value: parseFloat(balance?.payment_tokens.total),
                            color: '#4494CE'
                        },
                    ]} />
                    <div className="w-full text-white font-normal align-middle grid">
                        <div className="md:flex justify-evenly items-center mb-4 md:mb-0">
                            <div className="py-4">My portfolio
                                <DexxBalance className="text-3xl md:text-4xl lg:text-5xl">
                                    &euro;{balance ? euroFormat(balance.total) : euroFormat(0)}
                                </DexxBalance>
                            </div>
                            <div className="flex items-end justify-center md:h-14 lg:h-16">
                                <DexxButton label={"Deposit / Withdraw"} borderColor="dark_blue"
                                    className=" rounded-full px-6 bg-dark_blue font-semibold"
                                    onClick={() => { setShowPopUp(true); setResetAmountPopup(false) }} />
                            </div>
                        </div>
                        <BlueLine />
                        <div className="md:flex justify-evenly md:gap-8 gap-4 grid mt-4 md:mt-0 md:flex-wrap">
                            <DexxSubBalance label={"Asset / Security tokens"} color={"succ1"}
                                balance={euroFormat(balance?.balance.total)} />
                            <DexxSubBalance label={"Liquidity pool position"} color={"succ5"}
                                balance={euroFormat(balance?.liquidity.total)} />
                            <DexxSubBalance label={"FIMART Euro Voucher"} color={"succ4"}
                                balance={euroFormat(balance?.payment_tokens.total)} />
                        </div>
                    </div>
                </div>
                <BlueLine className="hidden md:block" />
            </div>
        </div>

        <div className=" mx-neg40 mt-5">
            <div className="">
                <DexxTableTitle label={"asset / security tokens (" + rsSecurities.length + ")"}
                    active={toBeShown[0]} className="pl-8 md:pl-16"
                    onClick={() => handleClick(0)} />
                {toBeShown[0] ?
                    <TableAndCSVDownload fileName={"securities.csv"} TableHeaders={headersSec}
                        TableFormatted={rsSecuritiesFormatted} TableRows={rsSecurities} AlignContent={alignmentSec} />
                    : <></>}
                <DexxTableTitle label={"Liquidated tokens (" + rsLiquidated.length + ")"}
                    active={toBeShown[1]} dark className="pl-8 md:pl-16"
                    onClick={() => handleClick(1)} />
                {toBeShown[1] ?
                    <TableAndCSVDownload fileName={"liquidated-tokens.csv"} TableHeaders={headersLiquidations}
                        TableFormatted={rsLiquidatedFormatted} TableRows={rsLiquidated}
                        AlignContent={alignmentLiquidations} colors={"dark"}/>
                    : <></>}
                <DexxTableTitle label={"Liquidity pool position (" + rsLiquidity.length + ")"}
                    active={toBeShown[2]} className="pl-8 md:pl-16"
                    onClick={() => handleClick(2)} />
                {toBeShown[2] ?
                    <TableAndCSVDownload fileName={"pool-liquidity.csv"} TableHeaders={headersShares}
                        TableFormatted={rsLiquidityFormatted} TableRows={rsLiquidity}
                        AlignContent={alignmentShares} />
                    : <></>}
                <DexxTableTitle label={"transaction history (" + tableContent.length + ")"}
                    active={toBeShown[3]} dark className="pl-8 md:pl-16"
                    onClick={() => handleClick(3)} />
                {toBeShown[3] ?
                    <TableAndCSVDownload fileName={"history-transactions.csv"}
                        TableHeaders={transactionTable.getHeaders(hiddenColumns, true)}
                        TableFormatted={tableContentToCsv} TableRows={tableRows}
                        AlignContent={transactionTable.getAlignments(hiddenColumns)}
                        FormattedHeader={formattedHeaderTransactions}
                        filters={{ value: filters, fun: setFilters }} colors={"dark"}
                        extend={{ value: hiddenColumns, fun: () => setHiddenColumns(!hiddenColumns) }} />
                    : <></>}
                <DexxTableTitle label={"bank transaction history (" + rsBank.length + ")"}
                    active={toBeShown[4]} className="pl-8 md:pl-16"
                    onClick={() => handleClick(4)} />
                {toBeShown[4] ?
                    <TableAndCSVDownload fileName={"history-bank-transactions.csv"} TableHeaders={headersBankTxns}
                        TableFormatted={rsBankFormatted} TableRows={rsBank}
                        AlignContent={alignmentBankTxns} FormattedHeader={formattedHeaderBankTransactions} />
                    : <></>}
            </div>
        </div>
        <div className="flex justify-center mt-5">
            <BackButton />
        </div>
    </>
}