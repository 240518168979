import { PieChart } from "react-minimal-pie-chart";
import { euroFormat, euroQtyFormat } from "../../../utils";
import { ListedLPTab } from "./ListedLPTab";
import { DexxButton } from "../../light/LightButton";
import { DexxPopup } from "../../Popup";
import { useEffect, useState } from "react";
import AddLPPopup from "../../popup/AddLPPopup";
import RemoveLPPopup from "../../popup/RemoveLPPopup";
import { Link } from "react-router-dom";
import GenericTab from "../utils/GenericTab";
import { TitleColor1 } from "../../typography/Typogtaphy";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../state";
import { bindActionCreators } from "@reduxjs/toolkit";
import CentralTabContent from "../utils/CentralTabContent";


export default function LiquidityPoolTab(props: {
    isLogged: boolean; reload: any; lpInfo: any; lpCount: number,
    origin: string, tokenInfo: any
}): JSX.Element {

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [showAddLPPopUp, setShowAddLPPopUp] = useState<boolean>(false)
    const [showRemoveLPPopUp, setShowRemoveLPPopUp] = useState<boolean>(false)
    const [showActualLP, setShowActualLP] = useState<string>("")
    const [shares, setShares] = useState<any[]>([])
    const [arrayShares, setArrayShares] = useState<any[]>([])

    useEffect(() => {
        if (props.isLogged) {
            if (props.lpInfo && props.lpInfo.length > 0) {
                props.lpInfo.forEach((lp: any, i: number) => {
                    if (lp?.uuid !== undefined) {
                        actions.getInvestorShares(lp.uuid, true, setShares)
                    }
                });
            }
        }
    }, [props.lpInfo])

    useEffect(() => {
        if (shares[0] !== undefined) setArrayShares([...arrayShares, shares[0].LPWE])
    }, [shares])

    return <>
        <GenericTab>
            {
                props.lpCount <= 0 ? <>
                    <CentralTabContent className="text-white my-20">
                        No Liquidity Pools created for the token
                    </CentralTabContent>
                    </>
                    :
                    (props.origin === "1m" ?
                        <ListedLPTab tokenInfo={props.tokenInfo} />
                        :
                        <div className="">
                            <DexxPopup show={showAddLPPopUp} title={"Add Liquidity"}
                                msg={<AddLPPopup closeFunction={() => { setShowAddLPPopUp(false); if (props.reload) props.reload() }} uuidLP={showActualLP} />}
                                type="success"
                                helpMsg={
                                    <div className="p-5 font-roboto font-semibold text-primary text-base">
                                        <div className=" font-bold text-primary text-lg mb-5">
                                            Add funds to a liquidity pool by entering the XEUR you will allocate. The number of asset tokens required will be calculated for you.
                                        </div>
                                        <div className=" font-bold text-primary text-lg mb-5">
                                            The transaction has a tolerance of 1% meaning that the price of the asset token can increase up to 1% by the time you tap Submit.
                                        </div>
                                        <div className=" font-bold text-primary text-lg">
                                            For more detailed information <Link to={"/support"} className="text-succ2">click here</Link>.
                                        </div>
                                    </div>
                                }
                                btnConfirm={false} close={() => { setShowAddLPPopUp(false); if (props.reload) props.reload() }} btnOk={false} />

                            <DexxPopup show={showRemoveLPPopUp} title={"Remove Liquidity"}
                                msg={<RemoveLPPopup closeFunction={() => { setShowRemoveLPPopUp(false); if (props.reload) props.reload() }} uuidLP={showActualLP} />}
                                type="alternate"
                                btnConfirm={false} close={() => { setShowRemoveLPPopUp(false); if (props.reload) props.reload() }} btnOk={false} />

                            {props.lpInfo.map((lp: any, i: number) => {
                                let filtered_shares = arrayShares.filter((s: any) => s.lp_uuid === lp.uuid)
                                return (
                                    <div className="flex cover flex-wrap gap-x-20 justify-center items-center " key={i}>
                                        <div className=" text-white bg-white_lightest rounded-lg  p-4 shadow-xl shadow-black/50">
                                            <div className="">
                                                <TitleColor1 className="md:text-xl text-lg">Asset name</TitleColor1>
                                                <div className="md:text-xl text-base">{lp.asset_a_name}</div>
                                                <TitleColor1 className="md:text-xl text-lg mt-2">Liquidity pool name</TitleColor1>
                                                <div className="md:text-xl text-base">{lp.lp_name}</div>
                                                <TitleColor1 className="md:text-xl text-lg mt-2">Add / Remove Funds</TitleColor1>

                                                <div className="flex justify-center  my-4 flex-wrap ">
                                                    {
                                                        filtered_shares[0]?.qty_lp_token && parseFloat(filtered_shares[0].qty_lp_token) > 0 ? (
                                                            <DexxButton label={"Remove"} color1="succ2" color2="primary" noBorder={true}
                                                                className="rounded-full w-32 py-2 font-bold text-xs md:text-sm bg-succ2"
                                                                onClick={() => { setShowRemoveLPPopUp(true); setShowActualLP(lp.uuid) }} />
                                                        ) : (
                                                            <DexxButton label={"Remove"} noCursor={true} noBorder={true} noHoverOnSelected={true}
                                                                color1="succ2" color2="primary"
                                                                className="rounded-full w-32 py-2 font-bold text-xs md:text-sm bg-succ2_lighter" />
                                                        )
                                                    }

                                                    <DexxButton label={"Add"} className="rounded-full w-32 py-2 ml-10 font-bold text-xs md:text-sm bg-succ2"
                                                        color1="succ2" color2="primary" noBorder={true}
                                                        onClick={() => { setShowAddLPPopUp(true); setShowActualLP(lp.uuid) }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap gap-x-20 items-center justify-center">
                                            <div className="flex place-content-center">
                                                <div className="w-48 md:mb-12 grid justify-items-center text-white self-center">
                                                    <div className={"relative text-5xl font-base inset-0 top-30"}>
                                                        LP
                                                    </div>
                                                    <PieChart className=""
                                                        data={[
                                                            { title: lp.token_a_ticker, value: parseFloat(lp.amount_a), color: '#80C8C4' },
                                                            { title: lp.token_b_ticker, value: parseFloat(lp.amount_b), color: '#4494CE' },
                                                        ]}
                                                        lineWidth={4}
                                                        paddingAngle={10}
                                                        animate={true}
                                                        animationDuration={1000}
                                                    />
                                                </div>
                                            </div>
                                            <div className="font-urbanist uppercase text-white my-10 md:text-3xl text-2xl px-4 rounded-lg
                                                    flex flex-col text-left place-content-center items-center bg-white_lightest 
                                                    shadow-xl shadow-black/50">
                                                <div>
                                                    <div className=" my-4">{euroQtyFormat(lp.amount_a)}
                                                        <div className="text-seafoam text-lg md:text-xl">{lp.token_a_ticker}</div>
                                                    </div>
                                                    <div className="my-4">{euroFormat(lp.amount_b)}
                                                        <div className="text-succ4 text-lg md:text-xl">{lp.token_b_ticker}</div>
                                                    </div>
                                                    <div className="my-4">{euroQtyFormat(lp.lp_token_supply)}
                                                        <div className="text-succ5 text-lg md:text-xl">Total LP Shares</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )
            }
        </GenericTab>
    </>

}