import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { defaultSecurityToken } from "../PoolContext"
import { actionCreators } from "../state"
import { BackButton } from "../components/light/LightButton"
import { BigTitle } from "../components/typography/Typogtaphy"
import IssuerTokenBody from "../components/IssuerTokenBody";
import TokenGeneralDetails from "../components/tokenDetails/TokenGeneralDetails"

export default function TokenDetailsRoute(props: any): JSX.Element {

    const uuid = props.match.params.uuid
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const [tokenInfo, setTokenInfo] = useState(defaultSecurityToken)
    const [media, setMedia] = useState("")

    useEffect(() => {
        actions.getTokenInfo(uuid, setTokenInfo)
        actions.getAssetMedia(uuid, setMedia)
    }, [])

    if (!tokenInfo.uuid || tokenInfo.uuid === "0") {
        <div className="grid justify-center text-white text-4xl font-urbanist">Token not found</div>
    }

    return <>
        <div className="">


            <BigTitle title={tokenInfo.asset_name ? tokenInfo.asset_name : "Not found"}/>

            <TokenGeneralDetails info={{type: "sc", content: tokenInfo}} />

            <IssuerTokenBody tokenInfo={tokenInfo} media={media}/>

            <div className="grid justify-center mt-10">
                <div className="grid justify-center mb-5">
                    <BackButton />
                </div>
            </div>
        </div>
    </>
}