import { url } from "inspector";
import { DexxButton } from "../light/LightButton"
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useState } from "react";
import { Link } from "react-router-dom";

export type Content = string | JSX.Element | number;

export default function DexxCard(props: {
    titles: { top: Content, middle: Content, bottom: Content }
    topContent: { leftTop: Content, leftBottom: { content: Content, color?: string }, 
        rightTop: { content: Content, color: string }, rightBottom: { content: Content, color?: string }}
    bottomContent: { leftTop: Content, leftBottom: Content }
    cardImageURLs?: { url_big: string, url_small: string }, shortName?: string,
    linkTo: string, buttonSpecs: { labelActive: string, active: boolean, labelInactive: string }
}): JSX.Element {
    let cardImageURLs = props.cardImageURLs
    const [onHoverProps, setOnHoverProps] = useState(" ")

    return <>
        <Link to={props.linkTo}>
            <div className={" m-1 bg-white1 font-roboto py-4 pr-6 w-96"}
                onMouseEnter={() => {
                    setOnHoverProps(" invisible")
                }}
                onMouseLeave={() => {
                    setOnHoverProps(" ")
                }}>
                <div className="flex justify-between">
                    <div className="text-black font-medium text-sm ml-6 mb-2">{props.topContent.leftTop}</div>
                    <div className={"font-medium uppercase text-" + props.topContent.rightTop.color}>
                        {props.topContent.rightTop.content}
                    </div>
                </div>

                <div className="text-navy_blue flex justify-between font-semibold text-xl ml-6 mb-6">
                    <div>{props.topContent.leftBottom.color? 
                        <div className={"font-medium uppercase text-base text-" + props.topContent.leftBottom.color}>
                            {props.topContent.leftBottom.content}
                        </div> : 
                        props.topContent.leftBottom.content}</div>
                    <div>{props.topContent.rightBottom.color? 
                        <div className={"font-medium uppercase text-base text-" + props.topContent.rightBottom.color}>
                            {props.topContent.rightBottom.content}
                        </div> : 
                        props.topContent.rightBottom.content}</div>
                </div>

                {cardImageURLs ? <>
                    <div className="w-80 h-40 rounded-tr-full rounded-br-full
                                    align-middle z-10 relative object-cover overflow-hidden">
                        <LazyLoadImage src={cardImageURLs.url_big} className="w-80 h-40 rounded-tr-full 
                                    rounded-br-full align-middle z-10 relative object-cover"
                            effect="blur" placeholderSrc={cardImageURLs.url_small} treshold={1000} />
                    </div>
                    <div className={"w-80 h-40 rounded-tr-full rounded-br-full mt-neg160 z-20 relative " +
                        " bg-cards3_light flex " + onHoverProps}>
                    </div>
                </>
                    :
                    <div className={"w-80 h-40 bg-gradient-to-r " +
                        "rounded-tr-full rounded-br-full flex realtive z-10 from-cards1 to-cards2" //+ onHoverClasses
                    }>
                        <div className={"text-7xl font-medium tracking-wider_plus self-center ml-6 leading-11 "}>
                            {props.shortName ? props.shortName : ""}</div>
                    </div>
                }
                <div className="grid content-between w-full h-72">
                    <div>
                        <div className="text-black font-medium text-medium ml-6 mt-6 mb-4">
                            {props.titles.top}
                        </div>
                        <div className="pl-6 text-4xl font-light leading-10 my-5 tracking-wide">
                            {props.titles.middle}
                        </div>
                        <div className="pl-6 text-black font-medium">
                            {props.titles.bottom}
                        </div>
                    </div>
                    <div className=" flex justify-between pl-6">
                        <div>
                            <div className="font-light tracking-wide">{props.bottomContent.leftTop}</div>
                            <div className="font-medium tracking-wide">{props.bottomContent.leftBottom}</div>
                        </div>
                        <div>
                            {props.buttonSpecs.labelActive === "" ? <></> :
                                (props.buttonSpecs.active ?
                                    <DexxButton label={props.buttonSpecs.labelActive} color1="succ1" color2="white"
                                        className=" px-10 rounded-full bg-succ1 font-medium tracking-wide"
                                        fontClasses="md:text-xl" /> :
                                    <DexxButton label={props.buttonSpecs.labelInactive} color1="gray4" color2="white"
                                        className=" px-10 rounded-full bg-gray4 font-medium tracking-wide"
                                        fontClasses="md:text-xl" />)
                            }

                        </div>
                    </div>
                </div>
            </div>
        </Link>
    </>
}