export default function PrettyJSON(props: { json: object }) {
    return <>

        <div className="bg-white_lighter rounded-lg shadow-xl shadow-black/50 text-white 
            font-semibold p-5 font-code-pro overflow-auto h-screen overflow-x-auto">
            <p className="whitespace-pre-wrap max-w-3xl">
                {JSON.stringify(props.json, null, 4)}
            </p>
        </div>

    </>
}