export default function TableShadow(props: { headers: any, rows: any }): JSX.Element {
    const nCols = (props.rows && props.rows[0]) ? props.rows[0].length  : 0
    if(nCols === 0) return <></>
    return <>
        <div className="w-full ">
            {props.rows.map((row: any, rowIndex: number) => <div key={rowIndex.toString() + row}>
                <div className={"bg-white_lighter text-white rounded-lg my-2 grid table-fixed grid-flow-cols break-words gap-1 " +
                    "  justify-between font-semibold items-center shadow-md shadow-black/50 py-2 px-4 grid-cols-2 md:grid-cols-"+nCols}>
                    {row.map((cell: any, colIndex: number) => 
                        <div key={rowIndex - colIndex + cell}> 
                            <div className="font-normal capitalize text-sm">{props.headers[colIndex]}</div>
                            <div>{cell}</div>
                        </div>)}
                </div>
            </div>)}
        </div>
    </>
}