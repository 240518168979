import infoAggregator from "./InfoAggregator"

export default function TokenGeneralDetails({ info }: { info: any }): JSX.Element {
    var aggregatedInfo: { label: string, content: string, color?: string }[] = infoAggregator(info)
    return <>
        <div className="bg-white_lighter flex justify-between text-white gap-3 
            text-base md:text-xl leading-7 md:py-6 md:px-8 p-2 flex-wrap rounded-md
            shadow-xl shadow-black/50">
            {aggregatedInfo.map((i, idx) => <div key={idx} className="flex flex-col flex-auto">
                <div className="text-xs">{i.label}</div>
                <div className={"font-semibold text-" + i.color}>{i.content}</div>
            </div>)}
        </div>
    </>
}