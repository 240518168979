import { useState } from 'react'
import { State } from '../state'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { useSelector } from 'react-redux'
import { actionCreators } from '../state'
import Popup from './Popup'
import { defaultUserState } from '../UserContext'
import GenericPopup, { PopupLabelAndValueInline, PopupText } from './popup/GenericPopup'
import { DexxNumericInput, DexxSelectInput } from "./utils/Input"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export default function DepositWithdrawInterface(props: {reset: boolean, 
        closeFunction: Function, isDefault: boolean}) {
    useEffect(() => {
        actions.getBalance()
    }, [])
    const detailAccountState = useSelector((state: State) => state.basicAccount)
    const [user, setUser] = useState<any>(defaultUserState)
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [amountD, setAmountD] = useState<number>(0)
    const [amountW, setAmountW] = useState<number>(0)
    const [iban, setIban] = useState("")
    const [swift, setSwift] = useState("")
    const [bankName, setBankName] = useState("")
    const [bankAdd, setBankAdd] = useState("")
    const [isDeposit, setIsDeposit] = useState<boolean>(true)
    let isDefault = props.isDefault
    
    // Response popup
    const [popUpResponse, setPopUpResponse] = useState<boolean>(false)
    const [responseMsg, setResponseMsg] = useState<any>("")
    const [responseTitle, setResponseTitle] = useState<string>("")
    const [response, setResponse] = useState<any[]>([])
    const [popUpConfirmation, setPopUpConfirmation] = useState<boolean>(false)
    const [confirmationTitle, setConfirmationTitle] = useState<any>("")
    const [confirmationMsg, setConfirmationMsg] = useState<any>("")
    const [confirmation, setConfirmation] = useState<any>("")

    const [paymentType, setPaymentType] = useState<boolean>(false)
    const [currency, setCurrency] = useState<string>("EUR")
    const [responsePBL, setResponsePBL] = useState<any[]>([])
    const [paymentFees, setPaymentFees] = useState<any[]>([])
    
    useEffect(() => {
        if(detailAccountState.payload) {
            setUser(detailAccountState.payload)
        }
    }, [detailAccountState])

    useEffect(() => {
        actions.getAllPaymentFees(amountD, setPaymentFees)
    }, [amountD])

    function handleDeposit() {
        let a = Number((amountD+"").replace(",", "."))
        let msgFees = paymentFees.map((payment, i) => 
            <div key={i.toString()}
                className={"border-solid border-1 border-white py-1 text-sm font-normal"}>
                    {"€"+payment.fees+" for "+payment.payment_method.name}</div>
        )
        if(a > 0) {
            setPopUpConfirmation(true)
            setConfirmationTitle("Confirm your action")
            if(!paymentType)
                setConfirmationMsg("You are notifying DEXX of a deposit of "+a.toString()+" Euros to be applied as FIMART XEUR tokens once received and verified.")
            else
                setConfirmationMsg(
                        <div>
                            You are requesting a deposit €{a.toString()} to your DEXX wallet using one of the following payment methods. 
                            The following fees apply:
                            {msgFees}
                            DEXX charges no fees on a wire deposit though fees from your bank may apply.
                            Tap the "HERE" link in the next popup window to go to the payment page.
                        </div>)
            setConfirmation(a.toString())
        } else {
            setResponseTitle("Sorry!")
            setResponseMsg("Please fill in a valid amount.")
            setPopUpResponse(true)
        }
    }
    
    function handleWithdraw() {
        let a = Number(amountW.toString().replace(",", "."))
        let s = swift.trim()
        let i = iban.trim()
        let n = bankName.trim()
        let add = bankAdd.trim()
        if(a > 0 && s && i){
            setPopUpConfirmation(true)
            setConfirmationTitle("Confirm your action")
            setConfirmationMsg(<>You are requesting the withdrawl of {a.toString()} FIMART XEUR tokens to be sent, 
                upon balance verification, to <div className='text-sm'>SWIFT: {s} </div><div className='text-sm'>IBAN: {i} </div>{isDefault ? <></> : 
                    <><div className='text-sm'>BANK NAME: {n}</div> <div className='text-sm'>BANK ADDRESS: {add}</div></>}
                    Outbound wire fee will be deducted from amount sent</>)
            if(isDefault) 
                setConfirmation({"amount": a.toString(), "swift": swift, "iban": iban})
            else
                setConfirmation({"amount": a.toString(), "swift": swift, "iban": iban, 
                    "bank_name": bankName, "bank_address": bankAdd})
        }
            //actions.bankWithdraw({"amount": a.toString(), "swift": swift, "iban": iban}, setResponse)
    }

    function handlePayByLink(){
        let a = Number((amountD+"").replace(",", "."))
        if(a > 0) {
            
            actions.getPayByLink({"amount": a.toString(), "currency": currency !== "" ? currency : "EUR"}, setResponsePBL) 
        } else {
            setResponseTitle("Sorry!")
            setResponseMsg("Please fill in a valid amount.")
            setPopUpResponse(true)
        }
    }

    function handleSetAmountW(amount: any) {
        let a: number = 0
        let b = false
        try {
            if(amount.length > 0){
                a = (Number(amount.replace(",", ".")))
                b = true
            }
        } catch(e) {}
        if((a && b) || a === 0)
            setAmountW(amount)
    }

    useEffect(() => {
        if(response.length > 0) {
            switch(response[0]) {
                case 200:
                case 204:
                    setResponseTitle("Success")
                    setResponseMsg("Your request has been sent successfully.")
                    setPopUpResponse(true)
                    setResponse([])
                    break
                case 400:
                case 403:
                    setResponseTitle("Sorry!")
                    //setResponseMsg(response[1].error)
                    setResponseMsg("We encountered an error saving your data.")
                    setPopUpResponse(true)
                    setResponse([])
                    break
                default:
                    setResponseTitle("Sorry!")
                    setResponseMsg("We encountered an error saving your data.")
                    setPopUpResponse(true)
                    setResponse([])
            }
        }
        
    }, [response])

    useEffect(() => {
        if(responsePBL.length > 0) {
            switch(responsePBL[0]) {
                case 200:
                case 204:
                    let link = (responsePBL && responsePBL[1]) ? responsePBL[1].link : ""
                    setResponseTitle("Ready to make the deposit!")
                    setResponseMsg(<div>Click <a className="text-succ1 font-bold" href={link} target='_blank'>HERE</a> to be redirected to a secure platform to proceed with the deposit.</div> )
                    setPopUpResponse(true)
                    setResponsePBL([])
                    break
                default:
                    setResponseTitle("Sorry!")
                    setResponseMsg("We encountered an error providing the link. Please try again.")
                    setPopUpResponse(true)
                    setResponsePBL([])
            }
        }
    }, [responsePBL])

    useEffect(() => {
        if(props.reset){
            setAmountD(0)
            setAmountW(0)
        }
    }, [props.reset])

    const handlePayment = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentType(!paymentType);
    };
        
    return (<>
        <Popup show={popUpResponse} title={responseTitle} msg={responseMsg} btnOk={true} close={() => {setPopUpResponse(false)}}></Popup>
        <Popup show={popUpConfirmation} title={confirmationTitle} msg={confirmationMsg} btnOk={true} btnConfirm={true} 
            close={() => {setPopUpConfirmation(false)}} maxHeight={"520px"} contentCenter
            handleConfirm={() => {
                setPopUpConfirmation(false); 
                isDeposit && !paymentType ? 
                    actions.bankDeposit(confirmation, setResponse) 
                : isDeposit && paymentType ? 
                    handlePayByLink()
                :
                actions.bankWithdraw(confirmation, setResponse);
                setAmountD(0); setAmountW(0)}} />
        <GenericPopup handleSubmit={()=>{isDeposit ? handleDeposit() : handleWithdraw()}} closeFunction={props.closeFunction}>
            <div className='mt-neg60 pb-8 pt-16 text-base flex bg-transparent
                    uppercase font-semibold '>
                <div className={'rounded-full w-1/2 bg-white mt-neg40 pl-8 py-4 cursor-pointer '+
                        (isDeposit ? "bg-white text-primary " : "bg-gray7 text-gray8 ")}
                    onClick={() => setIsDeposit(true)}>
                    Deposit</div>
                <div className={'rounded-full w-1/2 bg-white mt-neg40 pl-8 py-4 cursor-pointer '+
                        (isDeposit ? "bg-gray7 text-gray8 " : "bg-white text-primary ")}
                    onClick={() => setIsDeposit(false)}>
                    Withdraw</div>
            </div>
            <div className='mb-6 pt-2'>
                <PopupText className='px-12 pb-3'>
                    FIMART Euro Voucher can only be used to purchase the tokens marketed and traded on the platform
                </PopupText>

                {isDeposit ? 

                    <PopupText className="mr-10 px-12">
                        <div className='text-primary font-semibold'>Select the payment type</div>

                        <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={paymentType}
                            onChange={handlePayment}
                        >
                            <FormControlLabel value={false} control={<Radio />} label="Wire Transfer" />
                            <FormControlLabel value={true} control={<Radio />} label="Credit Card (Pay By Link)" />
                            
                        </RadioGroup>
                        </FormControl>
                    </PopupText>
                : <></> }

                <div className='bg-gradient-to-r from-navy_blue via-moonstone_blue to-white px-12
                        py-6 gap-8 grid grid-cols-2'>
                    <div className=' place-content-center'>
                        <div className='text-white font-bold text-xl tracking-wider'>Amount</div>
                        {isDeposit ?
                                <DexxNumericInput value={amountD} fun={setAmountD} 
                                    className='text-white text-2xl'/> :
                                <DexxNumericInput value={amountW} fun={handleSetAmountW} 
                                    className='text-white text-2xl'/>
                        }
                    </div>
                    
                    

                    {
                        isDeposit && paymentType?
                        <div className='place-content-center'>
                            <div className="text-white font-bold text-xl tracking-wider">Desired Currency
                                <DexxSelectInput value={currency} fun={setCurrency}
                                    items={[/*"",*/"EUR"]}
                                    className="w-44 text-primary text-sm bg-transparent focus:bg-transparent"/>
                            </div>
                        </div>
                        : 
                        isDeposit ? 
                        <div className='text-gray_lines grid place-content-center cols-span-2'>
                            <div className='font-bold text-lg tracking-wider text-white'>Unique Code: <span className='font-medium text-base tracking-wider'>{user.unique_code}</span></div>
                        </div> 
                    : <></>
                    }

                    {/*isDeposit ? 
                        <div className='text-gray_lines grid place-content-center cols-span-2'>
                            <div className='font-bold text-lg tracking-wider text-white'>Unique Code: <span className='font-medium text-base tracking-wider'>{user.unique_code}</span></div>
                        </div> 
                    : <></>*/}
                </div>

                


                <PopupText className="pt-4 mr-10 px-12">
                    <div className='text-primary font-semibold'>How to proceed:</div>
                    {isDeposit && !paymentType ? 
                    <><li>Copy your unique code</li>
                    <li>Select an amount and confirm</li>
                    <li>Send the requested amount specifying the unique code to our bank:</li>
                    <br></br></> : 
                    
                    isDeposit && paymentType ? 
                    <>
                    <li>Select the amount and currency then confirm</li>
                    <li>A deposit link will be generated</li>
                    <li>Click on the link and complete the payment with our payment service provider</li>
                    <li>Return to your DEXX account to see your deposit added to your balance in your Investor wallet.</li>
                    <br></br>
                    </> :
                    
                    <><li>Fill the form with the required information</li>
                    <li>Confirm the requested operation</li>
                    <li>We will let you know when the operation is executed</li>
                    <div className='text-primary font-semibold'>Outbound wire fee will be deducted from amount sent</div>
                    <br></br></>}
                    
                </PopupText>
                
                {isDeposit && !paymentType ?
                <PopupText className={'px-12 grid  align-middle '+(isDefault ? ' h-24 ':' h-24 ')}>
                    <div className='flex flex-col gap-1'>
                        <PopupLabelAndValueInline label={'Swift'} value={"BAPPIT21484"} />
                        <PopupLabelAndValueInline label={'iban'} value={"IT02X0503411501000000014130"} />
                        <PopupLabelAndValueInline label={'beneficiary'} value={"FIMART ITALIA SRL"} />
                        <PopupLabelAndValueInline label={'bank'} value={"BANCO BPM S.P.A. Piazza Martiri di Belfiore, 7 46100 Mantova, Italy "} />
                    </div>
                </PopupText> : (!isDeposit ?

                <div className={'grid px-12 mt-1 w-full text-sm '+(isDefault ? ' h-40 ':' h-60 ')}>
                    {isDefault ? <></> : <><PopupText className='w-full'>
                        <div className='text-primary font-semibold pl-3'>Bank Name:</div>
                        <input className='bg-gray7 rounded-full border-0 w-full py-2 pl-3 text-xs'
                            value={bankName} onChange={e => setBankName(e.target.value)}></input>
                    </PopupText>
                    <PopupText className='w-full'>
                        <div className='text-primary font-semibold pl-3'>Bank Address:</div>
                        <input className='bg-gray7 rounded-full border-0 w-full py-2 pl-3 text-xs'
                            value={bankAdd} onChange={e => setBankAdd(e.target.value)}></input>
                    </PopupText></>}
                    <PopupText className=''>
                        <div className='text-primary font-semibold pl-3'>SWIFT code:</div>
                        <input className='bg-gray7 rounded-full border-0 w-32 py-2 pl-3 text-xs'
                            value={swift} onChange={e => setSwift(e.target.value)}></input>
                    </PopupText>
                    <PopupText className='w-full'>
                        <div className='text-primary font-semibold pl-3'>IBAN:</div>
                        <input className='bg-gray7 rounded-full border-0 w-full py-2 pl-3 text-xs'
                            value={iban} onChange={e => setIban(e.target.value)}></input>
                    </PopupText>
                    
                </div> : <div className='h-14'></div>)}
            </div>
        </GenericPopup>
    </>)
}
