import { LiquidityPool } from "../../PoolContext";
import PrimaryMarketCard from "../cards/PrimaryMarketCard";
import SecondaryMarketCard from "../cards/SecondaryMarketCard";
import StaticCard from "../cards/StaticCard";
import { CardEarnLiquidityPool, CardInvestLiquidityPool } from "./CardLiquidityPool";

export default function CardsCollectionPrimaryMarket(props: {tokensList: any[], hide: any[], categoryName?: string, isLogged: boolean}) : JSX.Element {

    const list = props.tokensList ? props.tokensList : []
    let cat = props.categoryName ? props.categoryName : "All"
    
    return <>
        {list.length > 0 ?
        <div className="flex flex-wrap place-content-center p-2 gap-2">
            {list.map((m: any, i: number) => 
                <PrimaryMarketCard assetInfo={m} key={i} id={i} className={props.hide.includes(i) ? " hidden" : ""}/>)}
            {cat === "All" || cat === "SPORTEX" ? 
                <StaticCard title="Coming soon" category="SPORTEX"/> : <></>}
            {props.isLogged && (cat === "All" || cat === "AIF") ? 
                <StaticCard title="Coming soon" category="AIF"/> : <></>}
            {cat === "All" || cat === "COLLECTABLES" ? 
                <StaticCard title="Coming soon" category="CLLCT"/> : <></>}
            {cat === "All" || cat === "ART" ? 
                <StaticCard title="Coming soon" category="ART"/> : <></>}
        </div> : 
        <div className="p-3"><div className=" flex place-content-center p-2 text-tertiary font-roboto font-thin text-xl h-96">
                No primary sales available
        </div></div>}
            
    </>
}

export function CardsCollectionInvestLiquidityPool(props: {lpList: LiquidityPool[]}) : JSX.Element {
    return <>
        {props.lpList.length > 0 ? 
        <div className="flex flex-wrap place-content-center p-2 gap-2">
            {props.lpList.map((lp: LiquidityPool, i:number) => <CardInvestLiquidityPool 
                    info={lp} key={i}/>)}</div> : 
                <div className="p-3"><div className=" flex place-content-center p-2 
                        text-tertiary font-roboto font-thin text-xl h-96">
                No Liquidity pools available
            </div></div>
        }
        
    </>
}

export function CardsCollectionEarnLiquidityPool(props: {lpList: LiquidityPool[], hide: any[], categoryName?: string}) : JSX.Element {
    
    let cat = props.categoryName ? props.categoryName : "All"

    return <>
        <div className={"flex flex-wrap place-content-center p-2 gap-2"}>
            {props.lpList.map((lp: LiquidityPool, i:number) => <CardEarnLiquidityPool 
                    info={lp} key={i} className={props.hide.includes(i) ? " hidden" : ""}/>)}
            {cat === "All" || cat === "SPORTEX" ? 
                <StaticCard title="Coming soon" category="SPORTEX"/> : <></>}
            {cat === "All" || cat === "AIF" ? 
                <StaticCard title="Coming soon" category="AIF"/> : <></>}
            {cat === "All" || cat === "COLLECTABLES" ? 
                <StaticCard title="Coming soon" category="CLLCT"/> : <></>}
            {cat === "All" || cat === "ART" ? 
                <StaticCard title="Coming soon" category="ART"/> : <></>}
        </div>
    </>
}

export function CardsCollectionInvestSecondaryMarket(props: {uniqueTokens: any[], hide: any[], categoryName?: string, isLogged: boolean}) : JSX.Element {
    
    const list = props.uniqueTokens ? props.uniqueTokens : []
    let cat = props.categoryName ? props.categoryName : "All"
    
    return <>
        {list.length > 0 ? 

        <div className="flex flex-wrap place-content-center p-2 gap-2">
            {list.map((m: any, i: number) =>
                <SecondaryMarketCard token={m} key={i} hide={props.hide.includes(i)}/>)}
            {props.isLogged && (cat === "All" || cat === "AIF") ? <StaticCard title="Coming soon" 
                category="AIF"/> : <></>}
            
        </div> : <div className="p-3"><div className=" flex place-content-center p-2 text-tertiary font-roboto font-thin text-xl h-96">
            No tokens in secondary market available
        </div></div>}
    </>
}