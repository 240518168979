import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { actionCreators } from "../../state"
import { euroFormat } from "../../utils"
import Cookies from 'universal-cookie';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { OptimizedImage, SortedImages, sortImages } from "../../functions/images"
import DexxCard from "./DexxCard"
import { Countdown } from "../utils/Countdown"

const cookies = new Cookies();

export default function PrimaryMarketCard(props: { assetInfo: any, id: string | number, className: string }): JSX.Element {

    interface AssetInfo {
        issuer_name: string
        asset_type: string
        extra_info: {
            art_investing?: any
            art_lending?: any
        }
    }
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const asset = props.assetInfo
    const [assetInfo, setAssetInfo] = useState<AssetInfo>({ issuer_name: "", extra_info: {}, asset_type: "" })
    const [media, setMedia] = useState<any>()
    const [cardImage, setCardImage] = useState<OptimizedImage>()
    const [cardImageURLs, setCardImageURLs] = useState<OptimizedImage>()
    const isLogged = cookies.get("logged_in")

    useEffect(() => {
        actions.getAssetInfo(asset.asset_uuid, setAssetInfo, isLogged)
    }, [])

    useEffect(() => {
        actions.getAssetMedia(asset.asset_uuid, setMedia, isLogged)
    }, [asset.id])

    useEffect(() => {
        if (media) {
            let sortedImages: SortedImages = sortImages(media.filter((m: any) => m.media_type_name === "Image"))
            if (sortedImages.sorted_images.length > 0)
                setCardImage(sortedImages.sorted_images[0])
            else if (sortedImages.others.length > 0)
                setCardImage({
                    url_big: "",
                    url_small: "", name: sortedImages.others[0],
                    filename_big: sortedImages.others[0], filename_small: ""
                })
            else
                setCardImage(undefined)
            actions.getImagesURL(cardImage, setCardImageURLs)
        }
    }, [media])

    useEffect(() => {
        if (cardImage)
            actions.getImagesURL(cardImage, setCardImageURLs)
    }, [cardImage])

    let cdStart = new Countdown(new Date(asset.date_start), "Started")
    let cdEnds = new Countdown(new Date(asset.date_end), "Ended")

    var days180 = new Date(asset.date_end)
    days180.setDate(days180.getDate() + 180)

    var days30 = new Date(asset.date_end)
    days30.setDate(days30.getDate() + 30)

    const [cdS, setCdS] = useState(cdStart.getCountdownNoSec())
    const [cdE, setCdE] = useState(cdEnds.getCountdownNoSec())

    useEffect(() => {
        const interval = setInterval(() => {
            setCdS(cdStart.getCountdownNoSec())
            setCdE(cdEnds.getCountdownNoSec())
        }, 1000);

        return () => clearInterval(interval);

    }, [cdEnds, cdStart])

    let colorStatus = "primary"
    let labelCountdown: any = ""
    let countDown: any = ""
    switch (asset.status) {
        case "Initialized":
            colorStatus = "succ4"
            labelCountdown = "Coming Soon"
            countDown = cdS
            break
        case "Stopped":
            colorStatus = "error2"
            labelCountdown = <br />
            countDown = <br />
            break
        case "Started":
            colorStatus = "green-500"
            labelCountdown = "Ends"
            countDown = cdE
            break
        case "Ended":
            colorStatus = "error1"
            labelCountdown = "Completed"
            countDown = <br />
            break
    }

    return <>
        <DexxCard
            titles={{
                top: (assetInfo ? (asset.asset_category === "ART" ? (
                    assetInfo.asset_type === "ART Investing" ?
                        assetInfo.extra_info.art_investing?.sub_assets[0]?.artist :
                        assetInfo.extra_info.art_lending?.sub_assets[0]?.artist
                ) :
                    (assetInfo.issuer_name ? assetInfo.issuer_name : <br />)) : <br />),
                middle: (assetInfo ? (asset.asset_category === "ART" ? (
                    assetInfo.asset_type === "ART Investing" ?
                        assetInfo.extra_info.art_investing?.sub_assets[0]?.artwork_title :
                        assetInfo.extra_info.art_lending?.sub_assets[0]?.artwork_title
                ) :
                    (asset.asset_name ? asset.asset_name : <br />)) : <br />),
                bottom: asset.token_ticker ? asset.token_ticker : <br />
            }}
            topContent={{
                rightTop: {
                    content: (asset.pct_sold < 100 ? asset.status : "Sold Out"),
                    color: colorStatus
                },
                rightBottom: {content: (asset.pct_sold < 100 ? countDown : <br />)},
                leftTop: asset.asset_category,
                leftBottom: {content: (asset.pct_sold < 100 ? labelCountdown : <br />)}
            }}
            bottomContent={{
                leftTop: "TOKEN PRICE",
                leftBottom: euroFormat(asset.price) + " EUR",
            }}
            linkTo={"/primary_sale/" + asset.uuid}
            cardImageURLs={cardImageURLs}
            buttonSpecs={{
                active: asset.status === "Started",
                labelActive: "BUY",
                labelInactive: "DETAILS"
            }} />
    </>
}