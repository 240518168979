import TextOnlyPage from "../components/pageBuilders/TextOnlyPage";
import { FaqQuestion, FaqAnswer, PageTitle } from "../components/typography/Typogtaphy";

export default function AboutRoute(): JSX.Element {

    return <>
        <TextOnlyPage>
            <PageTitle>About DEXX</PageTitle>
            <FaqQuestion content="What" />
            We are building the leading European platform specialized
            in the tokenization of alternative assets (both existing and digital-native),
            this way allowing their fractional ownership as well as secondary market trading,
            with the goal of letting alternative investing become liquid and democratized.
            <FaqQuestion content="How" />
            This is made possible by combining (i) the endorsement from
            regulators and (ii) our deep understanding of financial market dynamics with (iii)
            the possibilities deriving from the use of three innovative technological enablers:
            blockchain, security tokens and smart contracts.
            <FaqQuestion content="Who" />
            DEXX is a Swiss-based FinTech platform promoted by a team of leading
            international investment, legal and technology experts. Look Lateral Inc and Colombo
            & Partners are founding partners of this venture.
            <FaqQuestion content="Why" />
            Coherently with the strong digitalization trends reshaping the entire
            financial sector, traditional financial instruments will be progressively upgraded/replaced
            by digital securities (security tokens), expanding investment opportunities and increasing
            security, automation and efficiency.
        </TextOnlyPage>
    </>
}
