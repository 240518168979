import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../state'
import { Authenticator, useAuthenticator, CheckboxField } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { ActionType } from "../state/action-types";
import { useHistory } from "react-router";
import { Link } from "react-router-dom"
import Cookies from 'universal-cookie';
import { fetchAuthSession } from '@aws-amplify/auth';


export default function SignInRoute(): JSX.Element {
  interface User {
    attributes?: any
    username?: any
    storage?: Storage
    signInUserSession?: any
  }

  const cookies = new Cookies();
  const { user } = useAuthenticator((context) => [context.user])
  const { authStatus } = useAuthenticator((context) => [context.authStatus])
  const [userS, setUserS] = useState<User>()
  const [session, setSession] = useState<string | null>()
  const dispatch = useDispatch()
  const isSignUp: boolean = window.location.pathname === "/account/signup"
  let history = useHistory()
  const [signupEnabled, setSignupEnabled] = useState(cookies.get("signup_enabled"))

  const actions = bindActionCreators(actionCreators, dispatch)

  const getIdToken = async () => {
    try {
      const session2 = await fetchAuthSession();
      setSession(session2.tokens?.idToken?.toString())
    }
    catch (e) { console.log(e); }
  };

  useEffect(() => {
    if (authStatus === "authenticated") {
      getIdToken()
      if (session && !cookies.get("logged_in") && cookies.get("session_expired") !== "true") {
        dispatch({ type: ActionType.SIGNIN_USER_ACCOUNT_SUCCESS })
        actions.signInCallback(session)
        cookies.set('logged_in', true, { path: "/", sameSite: "strict" })
      }
      cookies.set('token_id', session, { path: "/", sameSite: "strict" })
      actions.getBasicAccountProfile()
    }
  }, [session, authStatus])

  useEffect(() => {
    setUserS(user)
  }, [user])

  useEffect(() => {
    actions.getSignupEnabled(setSignupEnabled)
  }, [])

  useEffect(() => {
    let tmp = cookies.get("signup_enabled") + ""
    setSignupEnabled(tmp)
  }, [cookies])

  return <>
    <div className="flex h-full place-content-center flex-1">
      {cookies.get("signup_enabled") === "false" ? <>
        <div className="text-tertiary flex justify-center mb-10 text-center">
          <div>We reached the maximum number of users. <br />Come back soon if you want to sign up!</div>
        </div>
      </> : <></>}
      {signupEnabled && signupEnabled !== "undefined" ?
        <Authenticator loginMechanisms={['email']} signUpAttributes={[]}
          socialProviders={[]} initialState={isSignUp ? "signUp" : "signIn"}
          hideSignUp={signupEnabled === "false"}
          formFields={
            {}
          }
          components={{
            SignUp: {
              FormFields() {
                const { validationErrors } = useAuthenticator();
                return (
                  <>
                    <Authenticator.SignUp.FormFields />
                    <CheckboxField
                      errorMessage={validationErrors.acknowledgement as string}
                      hasError={!!validationErrors.acknowledgement}
                      name="acknowledgement"
                      value="yes"
                      label={<>I agree with the <Link className="text-succ6"
                        to="/privacy-policy" target="_blank">
                        Terms & Conditions</Link></>}
                      
                    />
                    <CheckboxField
                      errorMessage={validationErrors.acknowledgement_2 as string}
                      hasError={!!validationErrors.acknowledgement_2}
                      name="acknowledgement_2"
                      value="yes"
                      label={<>I agree that DEXX is not supervised by FINMA and
                        that the money deposited is not protected by deposit guarantee</>}
                      
                    />
                  </>);
              },
            },
            
            ConfirmSignIn: {
              Header() {
                return <>
                  <div className="capitalize font-normal text-xl">
                    Confirm TOTP Code</div>
                  <div className="font-thin text-lg">Open your authenticator app
                    to generate a Time-based One-Time Password (TOTP).
                    Enter this code here to access the platform securely.</div>
                </>
              },
            }
          }}
          services={{
            async validateCustomSignUp(formData) {
              if (!formData.acknowledgement || !formData.acknowledgement_2) {
                return {
                  acknowledgement: formData.acknowledgement ? '' : 'You must agree to the Terms & Conditions',
                  acknowledgement_2: formData.acknowledgement_2 ? '' : 'You must agree to the condition',
                };
              }
            },
          }}>
          {({ signOut, user }) => {
            
            if (cookies.get("logged_in") && authStatus === "authenticated" && cookies.get("token_id")) {
              cookies.set("session_expired", false, { path: "/", sameSite: "strict" })
              cookies.set("username", user?.username, { path: "/", sameSite: "strict" })
              cookies.set("user_email", user?.signInDetails?.loginId, { path: "/", sameSite: "strict" })              
              history.push('/menu')
            }
            if (cookies.get("session_expired") === "true" || cookies.get("session_expired")) {
              cookies.remove("session_expired", { path: "/", sameSite: "strict" })
              localStorage.clear()
              if (signOut) signOut()
            }


            return <>
              <div className="grid place-content-center">
                <div className="col-span-2 font-thin font-roboto tracking-wide md:pl-20 pl-2 text-tertiary">
                  <div className="text-succ2 text-2xl">Loading... </div>
                </div>
              </div>
            </>
          }}
        </Authenticator> : <div className="grid place-content-center">
          <div className="col-span-2 font-thin font-roboto tracking-wide md:pl-20 pl-2 text-tertiary">
            <div className="text-succ2 text-2xl">...</div>
          </div>
        </div>}
    </div>
  </>
}