import { Link } from "react-router-dom";

export function ListedLPTab(props: {tokenInfo: any}): JSX.Element {
    return <>
        <div className="">
            <div className="mt-5 text-xl font-normal text-tertiary justify-center flex flex-wrap">
                This token is now listed in the secondary market, click 
                <Link to={"/token/"+props.tokenInfo.uuid} className="mx-2 text-succ2">
                HERE
                </Link>
                to see the market and purchase it.
            </div>        
        </div>
    </>
}