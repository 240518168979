import DexxCard from "./DexxCard";

export default function StaticCard(props: {title: string, category: string}) {
    let shortName = props.category
    if (props.category && props.category === "SPORTEX")
        shortName = "SPORT"

    return <>
        <DexxCard titles={{
            top: "New token",
            middle: "Coming soon",
            bottom: ""
        }} topContent={{
            leftTop: props.category,
            leftBottom: {content: <br />},
            rightTop: {
                content: "",
                color: ""
            },
            rightBottom: {
                content: ""
            }
        }} bottomContent={{
            leftTop: "",
            leftBottom: ""
        }} linkTo={""} buttonSpecs={{
            labelActive: "",
            active: false,
            labelInactive: ""
        }}
        shortName={shortName} />
    </>
}