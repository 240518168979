import { BackButton } from "../light/LightButton";

export default function TextOnlyPage(props: {children: any, customButton?: JSX.Element}): JSX.Element {
    return <>
        <div className="md:px-10 lg:mx-20 font-thin text-white text-base md:text-xl">
            {props.children}
        </div>
        <div className="flex justify-center mb-2 mt-20 gap-4">
            {props.customButton}
            <BackButton />
        </div>
    </>
}