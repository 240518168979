import { bindActionCreators } from "@reduxjs/toolkit"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { BackButton, DexxButton, DisabledButton } from "../components/light/LightButton"
import { defaultLiquidityPool, defaultSecurityToken, defaultBalance } from "../PoolContext"
import { actionCreators } from "../state"
import { euroFormat, euroQtyFormat } from "../utils"
import { BigTitle } from "../components/typography/Typogtaphy"
import { PieChart } from "react-minimal-pie-chart";
import LightTable from "../components/light/LightTable";
import Popup, { DexxPopup } from '../components/Popup'
import AddLPPopup from "../components/popup/AddLPPopup";
import RemoveLPPopup from "../components/popup/RemoveLPPopup";
import { Link } from "react-router-dom"
import { useHistory } from "react-router-dom"
import TokenGeneralDetails from "../components/tokenDetails/TokenGeneralDetails"
import TabInnerTitle from "../components/tokenBody/utils/TabInnerTitle"
import TableShadow from "../components/light/TableShadow"
import { InlineContent } from "../components/tokenBody/issuerTabs/TokenControlFunctionsTab"

export default function IssuerLiquidityPoolRoute(props: any): JSX.Element {
    const uuid = props.match.params.uuid
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const [token_a, setToken_a] = useState(defaultSecurityToken)
    const [token_b, setToken_b] = useState(defaultSecurityToken)
    const [liquidityPoolDetails, setLPDetails] = useState(defaultLiquidityPool)
    const [balance, setBalance] = useState(defaultBalance)

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    const [investorsShares, setInvestorsShares] = useState<any[]>([])
    const [showAddLPPopUp, setShowAddLPPopUp] = useState<boolean>(false)
    const [showRemoveLPPopUp, setShowRemoveLPPopUp] = useState<boolean>(false)
    const [showConfirmDestroyPopUp, setShowConfirmDestroyPopUp] = useState<boolean>(false)
    const [responseDestroy, setResponseDestroy] = useState<any[]>([0, undefined])
    const hdsSingleLiquidityPool = ["investor", "LP shares %"]

    let history = useHistory()

    function handleClosePopUp() {
        setTitlePopUp("")
        setMsgPopUp("")
        setShowPopUp(false)
        history.push("/issuer/dashboard/#Tokens")
    }

    function investorSharesRows(l: any[], btnUrls: string[], btnLabels: string[]): any[] {
        return l ?
            l.map(t => [
                t.Investor.email, liquidityPoolDetails.lp_token_supply && liquidityPoolDetails.lp_token_supply !== "0" ? (100 * parseInt(t.LPWE.qty_lp_token) / parseInt(liquidityPoolDetails.lp_token_supply)) + "%" : "NONE"
            ]
            ) : []
    }

    useEffect(() => {
        actions.getLiquidityPool(uuid, setLPDetails)
        actions.getActualBalance(setBalance)
    }, [])

    useEffect(() => {

        if (liquidityPoolDetails.uuid !== '') {
            actions.getTokenInfoByTokenUUID(liquidityPoolDetails.token_a_uuid, setToken_a)
            actions.getTokenInfoByTokenUUID(liquidityPoolDetails.token_b_uuid, setToken_b)
            actions.getInvestorShares(uuid, false, setInvestorsShares)
        }
    }, [liquidityPoolDetails])

    useEffect(() => {
        if (responseDestroy[0] !== 0) {
            if (responseDestroy[0] === 204) {
                setTitlePopUp("Success")
                setMsgPopUp("The liquidity pool was destroyed successfully")
                setShowPopUp(true)
            } else {
                setTitlePopUp("Sorry!")
                setMsgPopUp("Liquidity Pool has not been destroyed")
                setShowPopUp(true)
            }
        }
    }, [responseDestroy])

    return (<>
        <DexxPopup show={showAddLPPopUp} title={"Add Liquidity"}
            msg={<AddLPPopup closeFunction={() => { setShowAddLPPopUp(false); actions.getLiquidityPool(uuid, setLPDetails) }} uuidLP={uuid} />}
            helpMsg={
                <div className="p-5 font-roboto font-semibold text-primary text-base">
                    <div className=" font-bold text-primary text-lg mb-5">
                        Add funds to a liquidity pool by entering the XEUR you will allocate. The number of asset tokens required will be calculated for you.
                    </div>
                    <div className=" font-bold text-primary text-lg mb-5">
                        The transaction has a tolerance of 1% meaning that the price of the asset token can increase up to 1% by the time you tap Submit.
                    </div>
                    <div className=" font-bold text-primary text-lg">
                        For more detailed information <Link to={"/support"} className="text-succ2">click here</Link>.
                    </div>
                </div>
            }
            btnConfirm={false} close={() => { setShowAddLPPopUp(false); actions.getLiquidityPool(uuid, setLPDetails) }} btnOk={false} />

        <DexxPopup show={showRemoveLPPopUp} title={"Remove Liquidity"}
            type="alternate"
            msg={<RemoveLPPopup closeFunction={() => {
                setShowRemoveLPPopUp(false); actions.getLiquidityPool(uuid, setLPDetails)
            }} uuidLP={uuid} />}
            btnConfirm={false} close={() => {
                setShowRemoveLPPopUp(false); actions.getLiquidityPool(uuid, setLPDetails)
            }} btnOk={false} />

        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp}></Popup>
        <div>
            <BigTitle title={liquidityPoolDetails.lp_name ? liquidityPoolDetails.lp_name : ""} />

            <TokenGeneralDetails info={{ type: "create", content: token_a }} />
        </div>

        <div className="md:mx-20">
            <div className="grid lg:grid-cols-4 cover my-10 xl:px-30">
                <div></div>
                <div className="flex place-content-center xl:ml-20">
                    <div className="w-48 grid justify-items-center text-tertiary self-center">
                        <div className={"relative text-5xl font-base inset-0 top-30"}>
                            LP
                        </div>
                        <PieChart className=""
                            data={[
                                { title: liquidityPoolDetails.token_a_ticker, value: parseFloat(liquidityPoolDetails.amount_a), color: '#80C8C4' },
                                { title: liquidityPoolDetails.token_b_ticker, value: parseFloat(liquidityPoolDetails.amount_b), color: '#4494CE' },
                            ]}
                            lineWidth={12}
                            paddingAngle={10}
                            animate={true}
                            animationDuration={3000}
                        />
                    </div>
                </div>

                <div className="font-urbanist uppercase text-tertiary my-10 text-4xl 
                        flex flex-col text-left place-content-center items-center">
                    <div>
                        <div className=" my-5">{euroQtyFormat(liquidityPoolDetails.amount_a)}
                            <div className="text-seafoam text-xl">{liquidityPoolDetails.token_a_ticker}</div>
                        </div>
                        <div className="my-5">{euroFormat(liquidityPoolDetails.amount_b)}
                            <div className="text-succ4 text-xl">{liquidityPoolDetails.token_b_ticker}</div>
                        </div>
                        <div className="my-5">{euroFormat(liquidityPoolDetails.lp_token_supply)}
                            <div className="text-succ5 text-xl">Total LP Shares</div>
                        </div>
                    </div>
                </div>

                <div></div>
            </div>

            <div className="grid mt-5">
                <div className="">
                    <TabInnerTitle title={"Liquidity Pool Providers"} />
                    <TableShadow headers={hdsSingleLiquidityPool} rows={investorSharesRows(investorsShares ? investorsShares : [], [], [])} />
                    <TabInnerTitle title="Actions" />
                    <div className="grid gap-2">
                        <InlineContent label={"Add / Remove Funds"} content={<>
                            <div className="flex gap-4">
                                <DexxButton label={"Remove"} className="rounded-full px-10 py-2 bg-succ2 "
                                    color1="succ2" color2="primary" color3="transparent" noBorder
                                    onClick={() => { setShowRemoveLPPopUp(true); }} />
                                <DexxButton label={"Add"} className="rounded-full px-10 py-2 bg-succ2 "
                                    color1="succ2" color2="primary" color3="transparent" noBorder
                                    onClick={() => { setShowAddLPPopUp(true) }} />
                            </div>
                        </>} />
                        <InlineContent label={"Destroy Liquidity Pool"} content={
                            liquidityPoolDetails.lp_token_supply === "0" ?
                                <DexxButton label={"Destroy"} className="rounded-full px-10 py-2  bg-succ2"
                                    color1="succ2" color2="primary" color3="transparent" noBorder
                                    onClick={() => { setShowConfirmDestroyPopUp(true) }} />
                                :
                                <DexxButton label={"Destroy"} className="rounded-full px-10 py-2  bg-white_lighter "
                                    color1="succ2" color2="primary" noBorder noCursor noHoverOnSelected
                                    onClick={() => { setShowAddLPPopUp(true) }} />
                        } />
                    </div>
                </div>
            </div>
            <div className="my-10 flex place-content-center">
                <BackButton />
            </div>
        </div>

    </>)
}
