export const makeCurrency = (n: number) => n.toLocaleString('en-US', {
  style: 'currency',
  currency: 'USD'
})

export function euroFormat(num: any, floor?: boolean): string {
  if (typeof num == "string") {
    let n = floor ? parseFloat(num) : (Math.ceil(parseFloat(num)*100)/100)
    let r = n.toLocaleString('de-DE', 
      { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
    return r === "NaN" ? "0,00" : r 
  }
  if (typeof num == "number") {
    let n = floor ? num : (Math.ceil(num*100)/100)
    let r = n.toLocaleString('de-DE', 
      { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    return r === "NaN" ? "0,00" : r
  }
  return "0,00"
}

export function euroFeesFormat(num: any): string {
  if (typeof num == "string") {
    return (Math.ceil(parseFloat(num)*100)/100).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }
  if (typeof num == "number") {
    return (Math.ceil(num*100)/100).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }
  return ""
}

export function euroQtyFormat(num: any): string {
  if (typeof num == "string") {
    return parseFloat(num).toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
  }
  if (typeof num == "number") {
    return num.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
  }
  return ""
}

export function convertCurrency(curr: string): string {
    if(curr === 'EUR') return '€';
    else if(curr === 'USD') return '$';
    else return ""
}

export function quantityFormat(num: any): string {
  if(num === "undefined" || num === "")
    return ""
  if (typeof num == "string") {
    let r = parseFloat(num).toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 5 })
    return r === "NaN" ? "0" : r
  }
  if (typeof num == "number") {
    let r = num.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 5 }) 
    return r === "NaN" ? "0" : r
  }
  return "0"
}

export function roundIfNumber(num: string, nDecimals: number): string {
  try {
    return parseFloat(num).toFixed(nDecimals)
  } catch (e) {
    return num
  }
}

export function checkRegexEmail(email: string) {
  let reg_exp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (reg_exp.test(email)) return true;
  else return false;
}

export function checkStrongPassword(email: string, password: string) {
  let count_strong = 0;
  let surname = email.substring(0, email.indexOf('@'))

  if (password.includes(surname)) return false;
  if (password.length < 8) return false;

  if (/[a-z]/.test(password)) count_strong++;
  if (/[A-Z]/.test(password)) count_strong++;
  if (/[0-9]/.test(password)) count_strong++;
  if (/(?=.*[!@#$%^&*])/.test(password)) count_strong++;

  if (count_strong >= 3) return true;
  else return false;
}

export function addTimezoneOffset(isoDate: string, offset: number): Date {
  if(offset) {
    var targetTime = new Date(isoDate);
    var tzDifference = offset * 60 + targetTime.getTimezoneOffset();
    var newDate = new Date(targetTime.getTime() + tzDifference * 60 * 1000);
    return newDate
  }
  return new Date(isoDate)
}

export function removeTimezoneOffset(isoDate: string, offset: number): Date {
  if(offset) {
    var targetTime = new Date(isoDate);
    var tzDifference = offset * 60 + targetTime.getTimezoneOffset();
    var newDate = new Date(targetTime.getTime() - tzDifference * 60 * 1000);
    return newDate
  }
  return new Date(isoDate)
}

function addLeadingZero(s: number): string {
  return (s+"").length > 1 ? s+"" : "0"+s
}

export function formatDate(isoDate: string, offset: number){
  const date = addTimezoneOffset(isoDate, offset);
  date.setDate(date.getDate())
  return date.toLocaleDateString("en-US")
}

export function convertToSimpleDate(isoDate: string){
  let date = new Date(isoDate)
  return date.toLocaleDateString("en-US")
}

export function formatDatetime(isoDate: any){
  const date = new Date(isoDate);
  date.setDate(date.getDate())
  return date.toLocaleDateString("en-US") + " " + date.toLocaleTimeString()
}

export function formatDatetimeOffset(isoDate: any, offset: number){
  const date = addTimezoneOffset(isoDate, offset);
  date.setDate(date.getDate())
  return date.toLocaleDateString("en-US") + " " + date.toLocaleTimeString()
}

export function formatExpiringDate(isoDate: any, offset: number){
  const date = addTimezoneOffset(isoDate, offset);
  date.setDate(date.getDate() + 30)
  return date.toLocaleDateString("en-US")
}

export function getHourFromDate(isoDate: any, offset: number) {
  const date = addTimezoneOffset(isoDate, offset);
  date.setDate(date.getDate())
  return (addLeadingZero(date.getHours()) + ":" + addLeadingZero(date.getMinutes()))
}

export function convertDateToUTC(dateTime: any, offset: number){
  if(dateTime === "") return ""
  const date = addTimezoneOffset(dateTime, offset);
  return date.toISOString()
}

export function getProfileUrl(basicAccountState : any, detailAccountState: any): string {
  if(basicAccountState.payload && basicAccountState.payload.kyc_submitted)
      return "/profile"
  else if(detailAccountState.payload && detailAccountState.payload.last_name && detailAccountState.payload.last_name?.length > 0) {
      return "/account/almost-done"
  }       
  return "/account/additional-info"
}

function LittleButton(props: { label: String, onClick?: any, alternate?: boolean, className?: string, fontClasses?: string }): JSX.Element {
  var isAlternate = false
  if (props.alternate)
    isAlternate = props.alternate

  return <>
    <div className={"font-urbanist border border-solid" +
      " not-italic text-center uppercase  cursor-pointer " +
      " w-fit " +
      (props.fontClasses ? props.fontClasses : " text-xl ") +
      (isAlternate ? " text-white border-white hover:bg-background hover:text-blue hover:border-blue " :
        " border-blue text-blue hover:bg-primary hover:text-white ") +
      (props.className ? props.className : "")}
      onClick={props.onClick}>{props.label}</div>
  </>
}

/*    FORM VALIDATION UTILS    */

export function formValidationClearErrors(){
  var elems = document.querySelectorAll(".error")
    elems.forEach(el=> {
        el.classList.remove("error");
    });
}

function isInvalidDate(d: any) {
  return (d instanceof Date) && isNaN(d.getTime())
}

export function formValidationCheckObject(obj: any, type?: string){
  let count = 0
  Object.entries(obj).forEach(([key, value]) => {
      var camelCased = key.replace(/_([a-z])/g, function (g) { return g[1].toUpperCase(); });
      
      if(value === '' //|| value === "1999-10-15T00:00:00Z"  why this check???
        || isInvalidDate(value) || 
        /*(key === "market_value" && value === 0) || */
        (key === "year" && value === 0) ){ 
        
        
        if(key === 'investment_period'){
          camelCased = type === "overview" ? "overviewInvestmentPeriod" : "fundTermsInvestmentPeriod"
        }
        
        if(document && document.getElementById(camelCased)){
          document.getElementById(camelCased)!.classList.add('error')
          count ++
        }
      }

      if(typeof value === "object" && key === "odometer"){
        let tmp:any = value  
        if(tmp?.odometer_unit === ""){
          if(document && document.getElementById("odometerUnit")){
            document.getElementById("odometerUnit")!.classList.add('error')
            count ++
          }
        }
        
        /* commented because odometer value can be 0 (new car)*/
        /*if(tmp?.odometer_value === ""){
          if(document && document.getElementById("odometerValue")){
            document.getElementById("odometerValue")!.classList.add('error')
            count ++
          }
        }*/
      }
  })
  return count
}

export function formValidationCheckObjectTokenization(obj: any, type?: string){
  let count = 0
  Object.entries(obj).forEach(([key, value]) => {
      var camelCased = key.replace(/_([a-z])/g, function (g) { return g[1].toUpperCase(); });
      if(value === '' || 
        (key === "total_supply" && value === 0) || 
        (key === "proposed_price" && value === 0) ||
        (key === "pct_tokenized" && value === 0)){ 

            if(document && document.getElementById(camelCased)){
              document.getElementById(camelCased)!.classList.add('error')
              count ++
          }
      }
  })
  return count
}

export function isValidHttpUrl(string: string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export function convertLegalFrameworkForFE(str: string) {
  if(str === "coownership") return "Asset Co-Ownership Token"
  else if(str === "security") return "Security Token"
  else return "Token's type not found"
}

export function sanitizeInputField(text: string) {
  
  if(!text || text === "") return ""
  
  //return string.replace(/[^\x00-\x7F]/g, "");
  // strip control chars. optionally, keep surrogates and formats
  /*if(stripSurrogatesAndFormats) {
    text = text.replace(/\p{C}/gu, '');
  } else {*/
    text = text.replace(/\p{Cc}/gu, '');
    text = text.replace(/\p{Co}/gu, '');
    text = text.replace(/\p{Cn}/gu, '');
  //}

  // other common tasks are to normalize newlines and other whitespace

  // normalize newline
  text = text.replace(/\n\r/g, '\n');
  text = text.replace(/\p{Zl}/gu, '\n');
  text = text.replace(/\p{Zp}/gu, '\n');

  // normalize space
  text = text.replace(/\p{Zs}/gu, ' ');

  return text;
}