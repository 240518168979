import GenericTab from "../utils/GenericTab";

export default function LoggedOutTab(): JSX.Element {
    return <>
        <GenericTab>
            <div className="text-center text-tertiary uppercase tracking-wide font-base text-base md:text-xl pt-10 pb-20">
                Please login or register to see this section.
            </div>
        </GenericTab>
    </>
}