import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { BackButton, DexxButton } from "../components/light/LightButton";
import { euroFormat, quantityFormat, formatDatetimeOffset, euroQtyFormat, euroFeesFormat } from "../utils";
import { actionCreators, State } from "../state";
import { defaultBalance, defaultIssuerTransactionsList } from "../PoolContext";
import { DexxTableTitle } from "../components/HeaderButton";
import { BlueLine, DexxPieChart } from "../components/utils/DexxElements";
import { DexxBalance, DexxSubBalance } from "../components/typography/Typogtaphy";
import TableAndCSVDownload from "../components/TableAndCSVDownload";
import { Cell, nextSortStatus, SortingMethod, SortStatus } from "../components/TransactionTables/TransactionTypes";
import { TransactionTable } from "../components/TransactionTables/TransactionTable";
import TransactionContent from "../components/TransactionTables/TransactionContent";


export default function IssuerPortfolio(): JSX.Element {

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const [balance, setBalance] = useState(defaultBalance)
    const issuerTransactionsListState: any = useSelector((state: State) => state.issuerTransactions)
    const [primarySaleList, setPrimarySaleList] = useState<any>(defaultIssuerTransactionsList.primary_sales)
    const [liquidityPoolsList, setLiquidityPoolsList] = useState<any>(defaultIssuerTransactionsList.liquidity_pools)
    const [liquidationsList, setLiquidationsList] = useState<any>(defaultIssuerTransactionsList.buybacks)
    const [p2pList, setP2pList] = useState<any>(defaultIssuerTransactionsList.peer_to_peer)
    const [tokenizationsList, setTokenizationsList] = useState<any>(defaultIssuerTransactionsList.tokenizations)
    const [closeList, setCloseList] = useState<any>(defaultIssuerTransactionsList.close_all)
    const [arraySecurities, setArraySecurities] = useState<any[]>([])

    const [rsSecurities, setRsSecurities] = useState<any[]>([])
    const [rsSecuritiesFormatted, setRsSecuritiesFormatted] = useState<any[]>([])

    const [sortBy, setSortBy] = useState<{ id: string, mode: SortStatus }>({ id: "date", mode: "reverse" })
    const [filters, setFilters] = useState<{ id: string, value: string }[]>([])
    const [hiddenColumns, setHiddenColumns] = useState<boolean>(true)
    const [tableRows, setTableRows] = useState<any[][]>([])

    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const userCtx = detailAccountState.payload

    const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0

    useEffect(() => {
        actions.getIssuerTransactions()
        actions.getIssuerBalance(setBalance)
    }, [])

    useEffect(() => {
        if (issuerTransactionsListState.payload) {
            setPrimarySaleList(issuerTransactionsListState.payload.primary_sales ? 
                issuerTransactionsListState.payload.primary_sales : [])
            setLiquidityPoolsList(issuerTransactionsListState.payload.liquidity_pools ? 
                issuerTransactionsListState.payload.liquidity_pools : [])
            setLiquidationsList(issuerTransactionsListState.payload.buybacks ? 
                issuerTransactionsListState.payload.buybacks : [])
            setP2pList(issuerTransactionsListState.payload.peer_to_peer ? 
                issuerTransactionsListState.payload.peer_to_peer : [])
            setTokenizationsList(issuerTransactionsListState.payload.tokenizations ? 
                issuerTransactionsListState.payload.tokenizations : [])
            setCloseList(issuerTransactionsListState.payload.close_all ? 
                issuerTransactionsListState.payload.close_all : [])
        }
    }, [issuerTransactionsListState.payload])

    const arrayColor = ["#8CD9CF", "#FFCF74", "#AF6CFC", "#E95C7B", "#C887DF", "#A1AEBC"]
    const arrayColorText = ["succ1", "warning", "violet", "error1", "succ8", "gray5"]

    const headersSec = ["Asset name", "Cat", "Ticker", "Quantity Owned", "Available Quantity", "Price", "Value", "Portfolio weight", ""]
    const alignmentSec = ["left", "left", "center", "right", "right", "right", "right", "right", "center"]

    const rowsSec: any[] = []
    const formattedSec: any[] = []

    useEffect(() => {
        let count = 0
        let others = {
            title: "Others",
            value: 0,
            color: ""
        }
        let arrayS = []
        if (balance.balance) {
            for (let r of balance.balance.security_entries) {
                for (let e of r.class_balance) {
                    let security = {
                        title: e.symbol,
                        value: parseFloat(e.available_value),
                        color: "",
                        colorName: ""
                    }
                    arrayS.push(security)
                }
            }
            arrayS.sort((a: any, b: any) => (b.value - a.value))
            let final = []
            for (let e of arrayS) {
                if (count < 5) {
                    e.color = arrayColor[count]
                    e.colorName = arrayColorText[count]
                    final.push(e)
                } else {
                    others.value += e.value
                }
                count++
            }
            if (others.value > 0) {
                others.color = arrayColor[5]
                final.push(others)
            }
            setArraySecurities(final)
        }
    }, [balance])

    useEffect(() => {
        if (balance) {
            if (balance.balance) {
                for (let r of balance.balance.security_entries) {
                    for (let e of r.class_balance) {
                        rowsSec.push([e.asset_name, r.class, e.symbol, euroQtyFormat(e.quantity), euroQtyFormat(e.available_quantity), euroFormat(e.current_price) + " " + e.currency,
                        euroFormat(parseFloat(e.value)), euroFeesFormat(e.pct) + "%",
                        <Link to={"/asset/" + e.asset_uuid} className="grid justify-center">
                            <DexxButton label={"Details"} color1="white" color2="primary"
                                className="px-2 rounded-lg"
                                fontClasses="text-xs" />
                        </Link>])

                        formattedSec.push([e.asset_name, r.class, e.symbol, parseInt(e.quantity), parseInt(e.available_quantity), euroFormat(e.current_price) + " " + e.currency,
                        euroFormat(parseFloat(e.value)), euroFeesFormat(e.pct) + "%"])

                        if (rowsSec.length) setRsSecurities(rowsSec)
                        if (formattedSec.length) setRsSecuritiesFormatted(formattedSec)
                    }
                }
            }
        }
    }, [balance])

    const headers = ['Asset / Security tokens', 'transaction history']
    const [toBeShown, setToBeShown] = useState(headers.map((_, i) => false))
    function handleClick(id: number) {
        var tmp = toBeShown.map((b, i) => i === id ? !b : b)
        setToBeShown(tmp)
    }

    function updateSortState(id: string) {
        var status: SortStatus = "normal"
        if (id === sortBy.id)
            status = nextSortStatus(sortBy.mode)
        setSortBy({ id: id, mode: status })
    }

    const transactionTable = new TransactionTable([
        {
            id: "date",
            header: "Date",
            content: (t: any, _: any) => formatDatetimeOffset(t.timestamp, offset),
            sort: () => { updateSortState("date") },
            alignment: "right"
        },
        {
            id: "status",
            header: "Status",
            content: (t: any, _: any) => t.status,
            sort: () => { updateSortState("status") },
            alignment: "left",
        },
        {
            id: "response",
            header: "Response",
            content: (t: any, type: any) => (type === "liq") ?
                (t.status === "Executed" ? "Success" : "Failed") :
                (t.response.OK === true ? "Success" :
                    (t.response.ERROR ? t.response.ERROR : "Failed")),
            sort: () => { updateSortState("response") },
            alignment: "left",
            hide: true
        },
        {
            id: "cat",
            header: "Cat",
            content: (t: any, _: any) => t.asset_category,
            sort: () => { updateSortState("cat") },
            alignment: "left"
        },
        {
            id: "typeToken",
            header: "Type of Sec. Token",
            content: (t: any, _: any) => t.asset_type,
            sort: () => { updateSortState("typeToken") },
            alignment: "left"
        },
        {
            id: "name",
            header: "Token name",
            content: (t: any, _: any) => t.asset_name ? t.asset_name : t.asset_a_name,
            sort: () => { updateSortState("name") },
            alignment: "left",
        },
        {
            id: "ticker",
            header: "Ticker",
            content: (t: any, _: any) => (t.ticker ? t.ticker : 
                (t.token_ticker ? t.token_ticker : t.token_a_ticker)),
            sort: () => { updateSortState("ticker") },
            alignment: "center"
        },
        {
            id: "nTokens",
            header: "N. of tokens",
            content: (t: any, type: any) => {
                switch (type) {
                    case "ps":
                        if (t.txn_operation === "create" || t.txn_operation === "update")
                            return quantityFormat(t.total_supply)
                        return ""
                    case "lp":
                        return t.amount_a ? quantityFormat(t.amount_a) : ""
                    case "liq":
                        return t.quantity ? quantityFormat(t.quantity) : ""
                    case "p2p":
                        return t.total_token_amount ? quantityFormat(t.total_token_amount) : ""
                    case "token":
                        return t.total_supply ? quantityFormat(t.total_supply) : ""
                    case "close":
                        return ""
                }
                return ""
            },
            alignment: "right"
        },
        {
            id: "price",
            header: "Price",
            content: (t: any, type: any) => {
                switch (type) {
                    case "ps":
                        if (t.txn_operation === "create" || t.txn_operation === "update")
                            return "€" + euroFormat(t.price_per_unit)
                        return ""
                    case "lp":
                        return t.amount_b ? "€" + euroFormat(t.amount_b) : ""
                    case "liq":
                    case "p2p":
                        return t.price_per_unit ? "€" + euroFormat(t.price_per_unit) : ""
                    case "token":
                        return t.proposed_price ? "€" + euroFormat(t.proposed_price) : ""
                    case "close":
                        return ""
                }
                return ""
            },
            alignment: "right"
        },
        {
            id: "buyerFees",
            header: "Buyer fees",
            content: (t: any, type: any) => {
                if (type === "ps" && (t.txn_operation === "create" || t.txn_operation === "update"))
                    return t.response.FEES && t.response.FEES.buyer ? "€" + euroFormat(t.response.FEES.buyer.amount) : ""
                else if (type === "liq")
                    return t.fees ? "€" + euroFormat(t.fees) : ""
                else
                    return ""
            },
            alignment: "left",
            hide: true
        },
        {
            id: "sellerFees",
            header: "Seller fees",
            content: (t: any, type: any) => {
                if (type === "ps" && (t.txn_operation === "create" || t.txn_operation === "update"))
                    return (t.response.FEES && t.response.FEES.seller) ?
                        "€" + euroFormat(t.response.FEES.seller.amount) : ""
                else
                    return ""
            },
            alignment: "left",
            hide: true
        },
        {
            id: "total",
            header: "Total",
            content: (t: any, type: any) => {
                switch (type) {
                    case "ps":
                        if (t.txn_operation === "create" || t.txn_operation === "update")
                            return t.response.FEES && t.response.FEES.buyer ?
                                ("€" + euroFormat(t.price_per_unit * t.total_supply + t.response.FEES.buyer.amount)) :
                                ("€" + euroFormat(t.price_per_unit * t.total_supply))
                        return ""
                    case "lp":
                        return t.amount_b ? "€" + euroFormat(parseInt(t.amount_b) * 2) : ""
                    case "liq":
                        return t.quantity && t.price_per_unit ?
                            "€" + euroFormat(parseFloat(t.quantity) * parseFloat(t.price_per_unit) +
                                parseFloat(t.fees)) : ""
                    case "p2p": 
                    case "token":
                        return "€" + euroFormat(parseFloat(t.price_per_unit) * parseFloat(t.total_token_amount))
                    case "close":
                        return ""
                }
            },
            alignment: "right"
        },
        {
            id: "transType",
            header: "Trans type",
            content: (t: any, type: any) => t.txn_operation + (type === "p2p" ? "(" + t.p2p_type + ")" : ""),
            sort: () => { updateSortState("transType") },
            alignment: "left"
        },
        {
            id: "counterpart",
            header: "Counterpart",
            content: (t: any, _: any) => t.txn_category,
            sort: () => { updateSortState("counterpart") },
            alignment: "left",
            hide: true
        },
        {
            id: "hash",
            header: "Hash on blockchain",
            content: (t: any, _: any) =>
                <Link to={"/blockchain/transaction/" + t.trx_identifier}>
                    {t.trx_identifier}
                </Link>,
            alignment: "left",
            toCsv: (t: any, _: any) => t.trx_identifier
        }

    ]);

    let tableContent2: any[][] = [
        ...transactionTable.getAllContent(primarySaleList, "ps", hiddenColumns),
        ...transactionTable.getAllContent(p2pList, "p2p", hiddenColumns),
        ...transactionTable.getAllContent(tokenizationsList, "token", hiddenColumns),
        ...transactionTable.getAllContent(liquidationsList, "liq", hiddenColumns),
        ...transactionTable.getAllContent(liquidityPoolsList, "lp", hiddenColumns),
        ...transactionTable.getAllContent(closeList, "close", hiddenColumns)
    ]

    const sortingMethods: { id: string, sort: SortingMethod }[] = [
        {
            id: "date",
            sort: (a: Cell[], b: Cell[]) => {
                return (new Date(formatDatetimeOffset(a[tableContent.getIndex("date")], 0)) <
                    new Date(formatDatetimeOffset(b[tableContent.getIndex("date")], 0))) ? -1 : 1
            }
        },
        {
            id: "status",
            sort: (a: Cell[], b: Cell[]) => {
                const index: number = tableContent.getIndex("status")
                return (a[index] > b[index]) ? 1 : -1
            }
        },
        {
            id: "response",
            sort: (a: Cell[], b: Cell[]) => {
                const index: number = tableContent.getIndex("response")
                return (b[index]) ? ((a[index] > b[index]) ? 1 :
                    ((b[index] > a[index]) ? -1 : 0)) : -1
            }
        },
        {
            id: "cat",
            sort: (a: Cell[], b: Cell[]) => {
                const index: number = tableContent.getIndex("cat")
                return (a[index] > b[index]) ? 1 : -1
            }
        },
        {
            id: "typeToken",
            sort: (a: Cell[], b: Cell[]) => {
                const index: number = tableContent.getIndex("typeToken")
                return (a[index] > b[index]) ? 1 : -1
            }
        },
        {
            id: "name",
            sort: (a: Cell[], b: Cell[]) => {
                const index: number = tableContent.getIndex("name")
                return (a[index] > b[index]) ? 1 : -1
            }
        },
        {
            id: "ticker",
            sort: (a: Cell[], b: Cell[]) => {
                const index: number = tableContent.getIndex("ticker")
                return (a[index] > b[index]) ? 1 : -1
            }
        },
        {
            id: "transType",
            sort: (a: Cell[], b: Cell[]) => {
                const index: number = tableContent.getIndex("transType")
                return (a[index] > b[index]) ? 1 : -1
            }
        },
        {
            id: "counterpart",
            sort: (a: Cell[], b: Cell[]) => {
                const index: number = tableContent.getIndex("counterpart")
                return (a[index] > b[index]) ? 1 : -1
            }
        }
    ]

    let tableContent = new TransactionContent(tableContent2, sortingMethods)

    let tableContentToCsv: any[][] = [
        ...transactionTable.getAllContent(primarySaleList, "ps", true, true),
        ...transactionTable.getAllContent(p2pList, "p2p", true, true),
        ...transactionTable.getAllContent(tokenizationsList, "token", true, true),
        ...transactionTable.getAllContent(liquidationsList, "liq", true, true),
        ...transactionTable.getAllContent(liquidityPoolsList, "lp", true, true),
        ...transactionTable.getAllContent(closeList, "close", true, true)
    ]

    useEffect(() => {
        let tableContent2: any[][] = [
            ...transactionTable.getAllContent(primarySaleList, "ps", hiddenColumns),
            ...transactionTable.getAllContent(p2pList, "p2p", hiddenColumns),
            ...transactionTable.getAllContent(tokenizationsList, "token", hiddenColumns),
            ...transactionTable.getAllContent(liquidationsList, "liq", hiddenColumns),
            ...transactionTable.getAllContent(liquidityPoolsList, "lp", hiddenColumns),
            ...transactionTable.getAllContent(closeList, "close", hiddenColumns)]

        let tableContent = new TransactionContent(tableContent2, sortingMethods)
        setTableRows(tableContent.getRows(sortBy, filters))
    }, [hiddenColumns, sortBy, toBeShown, filters])

    return <>
        <div className="h-full">
            <div className="flex place-content-center">
                <div className="min-w-2/3">
                    <BlueLine className="hidden md:block" />
                    <div className="grid md:flex gap-8 justify-center">
                        <DexxPieChart data={arraySecurities} />
                        <div className="w-full text-white font-normal align-middle grid">
                            <div className="md:flex justify-evenly items-center mb-4 md:mb-0">
                                <div className="py-4">Issued tokens value
                                    <DexxBalance className="text-3xl md:text-4xl lg:text-5xl">
                                        &euro;{balance ? euroFormat(balance.balance.available_total) : euroFormat(0)}
                                    </DexxBalance>
                                </div>
                                <div className="md:h-14 lg:h-16">
                                </div>
                            </div>
                            <BlueLine />
                            <div className="md:flex justify-evenly md:gap-8 gap-4 grid mt-4 md:mt-0 md:flex-wrap">
                                {arraySecurities.map((sec: any, i: number) =>
                                    <DexxSubBalance label={sec.title} color={sec.colorName}
                                        balance={euroFormat(sec.value)} key={i.toString()} />)
                                }
                            </div>
                        </div>
                    </div>
                    <BlueLine className="hidden md:block" />
                    <div className="flex justify-evenly mt-5 gap-2 flex-wrap">
                        <Link to="/issuer/dashboard"><DexxButton label={"My dashboard"} className="rounded-full px-4 md:px-8 font-semibold
                            bg-gray6" color1="gray8" borderColor="gray6" />
                        </Link>
                        <Link to="/issuer/asset/new">
                            <DexxButton label={"New Asset"} className="rounded-full px-4 md:px-8 font-semibold 
                            bg-succ2_light" color1="dark_blue" borderColor="succ2" />
                        </Link>
                    </div>
                </div>
            </div>

            <div className=" mx-neg40 mt-5">
                <div className="">
                    <DexxTableTitle label={"Asset / Security tokens (" + rsSecurities.length + ")"}
                        active={toBeShown[0]} dark={false} className="pl-8 md:pl-16"
                        onClick={() => handleClick(0)} />
                    {toBeShown[0] ?
                        <TableAndCSVDownload fileName={"securities.csv"} TableHeaders={headersSec}
                            TableFormatted={rsSecuritiesFormatted} TableRows={rsSecurities} AlignContent={alignmentSec} />
                        : <></>}
                    <DexxTableTitle label={"transaction history (" + tableContent.length + ")"}
                        active={toBeShown[1]} className="pl-8 md:pl-16" dark={true}
                        onClick={() => handleClick(1)} />
                    {toBeShown[1] ?
                        <TableAndCSVDownload fileName={"history-transactions.csv"} TableHeaders={transactionTable.getHeaders(hiddenColumns, true)}
                            TableFormatted={tableContentToCsv} AlignContent={transactionTable.getAlignments(hiddenColumns)}
                            TableRows={tableRows} colors={"dark"}
                            filters={{ value: filters, fun: setFilters }}
                            issuer extend={{ value: hiddenColumns, fun: () => setHiddenColumns(!hiddenColumns) }} />
                        : <></>}
                </div>
            </div>
            <div className="flex justify-center mt-5">
                <BackButton />
            </div>
        </div>
    </>
}