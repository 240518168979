import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import LabelAndDescription from "../components/LabelAndDescription";
import { BackButton, DexxButton, LightButton } from "../components/light/LightButton";
import TabsMenu from "../components/TabsMenu";
import { BigTitle } from "../components/typography/Typogtaphy";
import PrettyJSON from "../components/utils/PrettyJSON";
import { actionCreators } from "../state";
import { euroFormat, quantityFormat } from "../utils";

export default function BlockchainLookup(props: any): JSX.Element {
    const hash = props.match.params.trx ? "" + props.match.params.trx : ""
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const [getTrxDetails, setGetTrxDetails] = useState([])
    const [trxDetails, setTrxDetails] = useState({
        transaction: {
            status: 0,
            ok: null,
            response: {
                header: {
                    txn_id: "",
                    txn_type: "",
                    timestamp: "",
                },
                payload: {
                    quantity: "",
                    price: "",
                    txn_category: "",
                    txn_type: "",
                    txn_operation: ""
                }
            }
        },
        response: {
            ok: null
        },
        verification: {
            ok: true,
            response: {}
        }
    })

    useEffect(() => {
        actions.getBlockchainTransactionDetails(hash, setGetTrxDetails)
    }, [])

    useEffect(() => {
        if (getTrxDetails.length > 0)
            setTrxDetails(getTrxDetails[1])
    }, [getTrxDetails])

    let timestamp = ""
    if (trxDetails?.transaction?.response?.header?.timestamp) {
        let date = new Date(parseInt(trxDetails.transaction.response.header.timestamp) * 1000)
        timestamp = date.toUTCString()
    }


    function showOverview() {
        return <>
            {
                <div className="grid lg:px-20 gap-2 mt-4 lg:mt-0">
                    <LabelAndDescription label={"Hash"} description={hash} alternate={true} className="col-span-2" />
                    <LabelAndDescription label={"transaction type"} description={trxDetails?.transaction.response?.header.txn_type}
                        alternate={true} />
                    <LabelAndDescription label={"transaction category"}
                        description={trxDetails?.transaction.response.payload?.txn_category} alternate={true} />
                    <LabelAndDescription label={"transaction operation"}
                        description={trxDetails?.transaction.response.payload?.txn_operation} alternate={true} />
                    <LabelAndDescription label={""} description={""} alternate={true} />
                    {(trxDetails?.transaction.response.payload ? <>
                        {trxDetails?.transaction.response.payload.price ?
                            <LabelAndDescription label={"price"} description={euroFormat(trxDetails?.transaction.response.payload?.price) + "€"}
                                alternate={true} /> :
                            <></>}
                        <LabelAndDescription label={"quantity"} description={quantityFormat(trxDetails?.transaction.response.payload?.quantity + "")}
                            alternate={true} />
                        <LabelAndDescription label={"timestamp"} description={timestamp}
                            alternate={true} />
                    </> :
                        <></>)}
                </div>}
        </>
    }

    function showJson() {
        return (
            <div className="grid lg:mx-20 gap-4">
                <div className="flex place-items-center font-roboto text-white text-lg">
                    <a
                        href={`data:text/json;charset=utf-8,${encodeURIComponent(
                            JSON.stringify(trxDetails)
                        )}`}
                        download={"transaction-details.json"}
                    >
                        <DexxButton label={"Download"} className=" rounded-full px-5 bg-succ2" color1="succ2" color2="primary" color3="transparent"/>
                    </a>
                </div>
                <div className=" text-sm">
                    <PrettyJSON json={trxDetails} />

                </div>
            </div>
        )
    }

    return <>
        <div className="">
            <BigTitle title={"Transaction details"} />

            <div className="flex uppercase text-tertiary font-semibold h-6 place-items-center place-content-center">
                Status:
                {(trxDetails?.response.ok === true) ? <div className="h-6 w-fit px-3 rounded-xl bg-succ2
                        text-green-800 normal-case ml-5 flex place-items-center">
                    <div className="h-2 w-2 bg-green-800 rounded-xl mr-2 "></div>
                    Success
                </div> :
                    ((!trxDetails?.response.ok && trxDetails?.response.ok === false) ?
                        <div className="h-6 w-fit px-3 rounded-xl bg-error1 text-red-800 normal-case ml-5 flex place-items-center">
                            <div className="h-2 w-2 bg-red-800 rounded-xl mr-2"></div>
                            Fail
                        </div> :
                        <div className="h-6 w-fit px-3 rounded-xl bg-warning text-error1 normal-case ml-5 flex place-items-center">
                            <div className="h-2 w-2 bg-error1 rounded-xl mr-2 "></div>
                            Loading
                        </div>)
                }
            </div>
            <TabsMenu headers={["Overview", "Json"]} contents={[showOverview(), showJson()]} />
            <div className="flex justify-center mt-10">
                <BackButton />
            </div>

        </div>

    </>
}