import GenericTab from "../utils/GenericTab";
import { DexxButton } from "../../light/LightButton";

export function InlineContent({label, content}: {label: string, content: JSX.Element | string}): JSX.Element {
    return <>
        <div className="flex bg-white_lightest rounded-md px-4 py-2 shadow-xl shadow-black/50 text-white 
            gap-4 lg:text-xl md:text-lg items-center justify-between text-base">
            <div>{label}</div>
            <div>{content}</div>
        </div>
    </>
}

function InactiveButton({ label }: { label: string }): JSX.Element {
    return <DexxButton label={label} className="md:px-5 px-2 bg-white_lighter rounded-full 
        font-semibold " noCursor noHoverOnSelected noBorder />
}

export default function TokenControlFunctionsTab(props: {tokenInfo: any, onClickLiquidation: Function}): JSX.Element {
    const buyback: boolean = !props.tokenInfo.buyback
    return <>
        <GenericTab className="gap-2 lg:px-40">
            <InlineContent label={"Liquidation"} content={
                buyback ? <DexxButton label={"liquidation"} className="px-5 bg-succ2 rounded-full font-semibold "
                    color1="succ2" color2="primary" color3="transparent" onClick={() => props.onClickLiquidation()} />
                    : <></>} />
            <InlineContent label={"Deliver a dividend"} content={
                buyback ? <InactiveButton label={"Deliver"} /> : <></>} />
            <InlineContent label={"Freeze / Unlock tokens"} content={
                buyback ? <InactiveButton label={"Freeze / Unlock"} /> : <></>} />
            <InlineContent label={"Pay a fee"} content={
                buyback ? <InactiveButton label={"Pay"} /> : <></>} />
            <InlineContent label={"Execute Buyback option"} content={
                buyback ? <InactiveButton label={"Execute"} /> : <></>} />
            <InlineContent label={"Execute Buyback obbligation"} content={
                buyback ? <InactiveButton label={"Execute"} /> : <></>} />
            <InlineContent label={"Send a report"} content={
                buyback ? <InactiveButton label={"Sends"} /> : <></>} />
        </GenericTab>
    </>
}