import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux";
import { defaultAssetInfo, defaultSecurityToken } from "../PoolContext";
import { actionCreators } from "../state";
import { BigTitle } from "../components/typography/Typogtaphy";
import { BackButton } from "../components/light/LightButton";
import TokenBody from "../components/tokenBody/TokenBody";
import TokenGeneralDetails from "../components/tokenDetails/TokenGeneralDetails";

export default function SecurityTokenRoute(props: any): JSX.Element {
    const uuid = props.match.params.uuid
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [tokenInfo, setTokenInfo] = useState(defaultSecurityToken)
    const [assetInfo, setAssetInfo] = useState(defaultAssetInfo)
    const [media, setMedia] = useState("")


    useEffect(() => {
        actions.getTokenInfo(uuid, setTokenInfo)
        actions.getAssetInfo(uuid, setAssetInfo)
        actions.getAssetMedia(uuid, setMedia)
    }, [])

    // If the token doesn't have an uuid, then it isn't invalid and nothing is shown.
    if (!tokenInfo || !tokenInfo.uuid || tokenInfo.uuid === "0") {
        return <div className="grid justify-center text-white text-4xl h-48 content-center">Asset not found</div>
    }

    return (
        <>
            <div>
                <BigTitle title={tokenInfo.asset_name} />
            </div>
            <div className="">
                <TokenGeneralDetails info={{type: "sc", content: tokenInfo}} />
            </div>
            <div className="grid pb-10">
                <TokenBody origin={"detail"} lpCount={0} isLogged={true} media={media}
                    tokenInfo={tokenInfo} assetInfo={assetInfo} completeView={false}/>
                <div className="flex mt-10 justify-center">
                    <BackButton />
                </div>

            </div>

        </>)
}