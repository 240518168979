import { CSVDownloadButton } from "./light/LightButton"
import { DexxTable } from "./light/LightTable"
import { InputFilter, SelectFilter } from "./light/TableFilters"

export interface TableFilter {
    active: boolean,
    handleChange: Function,
}

export default function TableAndCSVDownload(props: {
    fileName: string, TableHeaders: any[],
    TableFormatted: any[], FormattedHeader?: any[], TableRows: any, AlignContent: any,
    filters?: { value: { id: string, value: string }[], fun: Function }, issuer?: boolean, extend?: { value: boolean, fun: Function },
    colors?: any
}): JSX.Element {

    const transTypeFilters = props.issuer ? ["all", "tokenize",
        "pause", "delete", "update", "create", "resume", "end", "start",
        "set_status", "close_lps", "fill", "close_p2ps", "deny", "approve"] :
        ["all", "buy", "add", "remove", "swap_buy", "swap_sell", "fill", "close_p2ps",
            "delete", "create", "sell"]
    const bsf = transTypeFilters.map((s: string) => {
        return {
            name: (s.charAt(0).toUpperCase() + s.slice(1)).replace("_", " "), val: s
        }
    })

    return <>
        <div className="grid w-full bg-platinum " id={props.fileName}>
            <div className={"flex flex-col md:flex-row md:items-center " + (props.filters ? " mb-4" : "")}>
                <div className={"flex " + (props.filters ? " mb-4 md:mb-0" : "")}>
                    {<CSVDownloadButton headers={props.FormattedHeader ? props.FormattedHeader : props.TableHeaders}
                        table={props.TableFormatted} fileName={props.fileName} />}
                </div>
                {props.filters ? (
                    <div className="flex">
                        <SelectFilter label="Status"
                            filters={props.filters}
                            values={[{ name: "All", val: "all" }, { name: "Executed", val: "executed" },
                            { name: "Pending", val: "pending" }, { name: "Failed", val: "failed" }]} id={"status"} />
                        <SelectFilter label="Response" filters={props.filters} id="response"
                            values={[{ name: "All", val: "all" }, { name: "Success", val: "success" },
                            { name: "Failure", val: "failure" }]} />
                        <SelectFilter label="Category" filters={props.filters} id="cat"
                            values={[{ name: "All", val: "all" }, { name: "Art", val: "art" },
                            { name: "AIF", val: "aif" }, { name: "Sportex", val: "sportex" },
                            { name: "Collectables", val: "collectables" }]} />
                        <SelectFilter label={"Trans Type"} filters={props.filters} id="transType"
                            values={bsf} />
                        <InputFilter label="Ticker" id="ticker" filters={props.filters}
                            className="ml-5 mr-5 capitalize" />
                    </div>) : <></>
                }
                {props.extend ?
                    <div
                        className="grid ml-8 mt-6 md:mt-0 cursor-pointer text-xs md:text-sm text-primary"
                        onClick={() => { if (props.extend) props.extend.fun() }}>
                        <div>Expand</div>
                        <div className="flex items-center">
                            <div className=" h-2 w-6 bg-gray5 rounded-full"></div>
                            <div className={"w-3 h-3  rounded-full " +
                                (props.extend.value ? " bg-gray8 ml-neg24 " : " bg-succ2 ml-neg12 ")}>
                            </div>

                        </div>

                    </div>
                    : <></>}
            </div>
            <DexxTable headers={props.TableHeaders} rows={props.TableRows} alignment={props.AlignContent}
                colors={props.colors}/>
        </div>
    </>
}
