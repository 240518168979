import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useEffect, useState } from 'react';

function handleChangeFilter(event: SelectChangeEvent, id: string, filters: { value: { id: string, value: string }[], fun: Function }) {
    var tmp = [...filters.value]
    if (!tmp)
        return
    const index = tmp.findIndex((f) => f.id === id)
    if (index >= 0) {
        tmp.splice(index, 1)
    }
    tmp.push({ id: id, value: event.target.value as string })
    filters.fun(tmp);
}

export function SelectFilter(props: {
    label: String, values: any[], id: string,
    filters: { value: { id: string, value: string }[], fun: Function }, className?: string
}): JSX.Element {

    const [val, setVal] = useState<string>("")

    var index = props.filters?.value.findIndex((f) => f.id === props.id)
    if (!index)
        index = 0

    useEffect(() => {
        setVal(props.filters?.value[index] ? props.filters?.value[index].value : "all")
    }, [props.filters])

    return <>
        <Box className="min-w-20 ml-5">
            <FormControl>
                <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={val}
                    label="Type"
                    onChange={(e: any) => handleChangeFilter(e, props.id, props.filters)}
                >
                    {props.values.map(({ name, val }, i) => <MenuItem key={i} value={val}>{name}</MenuItem>)}
                </Select>
            </FormControl>
        </Box>
    </>
}

export function InputFilter(props: {
    label: String, id: string,
    filters: { value: { id: string, value: string }[], fun: Function }, className?: string
}): JSX.Element {

    const [val, setVal] = useState<string>("")
    var index = props.filters?.value.findIndex((f) => f.id === props.id)
    if (!index)
        index = 0

    useEffect(() => {
        setVal(props.filters?.value[index] ? props.filters?.value[index].value : "")
    }, [props.filters])

    return <>
        <Box
            component="form"
            className={props.className}
            noValidate
            autoComplete="off"
        >
            <TextField id="outlined-basic" label={props.label} variant="outlined" value={val}
                onChange={(e: any) => handleChangeFilter(e, props.id, props.filters)} />
        </Box>
    </>
}