export default function LabelAndDescription(props: {label: string, description: string, 
        className?: string, alternate?: boolean}) : JSX.Element {

    return props.description ?
        <div className={" bg-white_lightest rounded-md p-2 shadow-xl shadow-black/50 " + 
            props.className + (props.alternate ? " text-white" : " text-primary")}>
            
            <div className="capitalize font-normal text-xs md:text-sm leading-7 tracking-wider">
                {props.label}
            </div>
            <div className="font-semibold text-base md:text-lg tracking-wider">
                {props.description}
            </div>
        </div>
        : <></>
}

export function LabelAndInlineValue(props: {label: any, value: any, colorVal?: string, colorLabel?:string}) {
        
    let cV = "background"
    let cL = "background"
    if(props.colorVal) cV = props.colorVal
    if(props.colorLabel) cL = props.colorLabel
    return <>
        <div className={"text-" + cL + " font-light text-base md:text-xl md:my-2 my-1"}>
            {props.label}<span className={"font-semibold text-" + cV}>{props.value}</span>
        </div>
    </>
}