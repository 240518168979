
import ListOfInformation from "../utils/ListOfInformation"
import GridTab from "../utils/GridTab"
import TabInnerTitle from "../utils/TabInnerTitle"

export default function AssetDetailsTab({ asset }: { asset: any }): JSX.Element {
    const infoChar = asset.characteristics
    const infoIRR = asset.irr
    const infoFund = asset.fund_terms
    const infoOther = asset.other
    var dealElems: any[] = []
    var irrElems: any[] = []
    var fundTermsElems: any[] = []
    var otherElems: any[] = []
    const dealLabels = ["Borrower Profile", "Target Company size", "Loans / Bonds", "Average Rating",
        "Bullet / Amortizing", "Minimum Rating", "Floating / Fixed", "Typical Loan To Value",
        "Primary / Secondary", "Type of Collateral", "Sponsor / Corporate", "Max / Average net leverage",
        "Covenant", "Speed of deployment", "Deal FIMART Euro Voucher", "Target Deal Size", "Fund FIMART Euro Voucher",
        "Number Of Deals", "Max / Typical Term Per Deal", "Expected Term Per Deal"]
    const irrLabels = ["Target Gross Irr", "Target Cash Yield", "Distribution Policy"]
    const fundTermsLabels = ["First Close", "Final Close", "Fund Term", "Ramp-Up",
        "Investment Period", "Capital Restitutions"]
    const otherLabels = ["AIFM", "Auditor", "Reporting", "Distributor", "Custiodian", "Administrator",
        "Nav. Frequency", "Share Classes", "Incentive Fees", "General Partner", "Legal Structure",
        "Management Fees", "Sub Distributor", "Portfolio Manager"]

    try {
        dealElems = [infoChar.borrower_profile, infoChar.target_company_size, infoChar.loans_or_bonds,
        infoChar.average_rating, infoChar.bullet_or_amortizing, infoChar.minimum_rating, infoChar.floating_or_fixed,
        infoChar.typical_loan_to_value, infoChar.primary_or_secondary, infoChar.type_of_collateral,
        infoChar.sponsor_or_corporate, infoChar.max_average_net_leverage, infoChar.covenant, infoChar.speed_of_deployment,
        infoChar.deal_currency, infoChar.target_deal_size, infoChar.fund_currency, infoChar.number_of_deals,
        infoChar.max_typical_term_per_deal, infoChar.expected_term_per_deal]
        irrElems = [infoIRR.target_gross_irr, infoIRR.target_cash_yield, infoIRR.distribution_policy]
        fundTermsElems = [infoFund.first_close, infoFund.final_close, infoFund.fund_term,
        infoFund.ramp_up, infoFund.investment_period, infoFund.capital_restitutions]
        otherElems = [infoOther.AIFM, infoOther.auditor, infoOther.reporting, infoOther.distributor, infoOther.custodian,
        infoOther.administrator, infoOther.nav_frequency, infoOther.share_classes, infoOther.incentive_fees, infoOther.general_partner,
        infoOther.legal_structure, infoOther.management_fees, infoOther.sub_distributor, infoOther.portfolio_manager]
    } catch (_) {
        dealElems = []
        irrElems = []
        fundTermsElems = []
    }

    return <>
        <GridTab>
            <TabInnerTitle title="Deal" />
            <ListOfInformation labels={dealLabels} contents={dealElems} />
            <TabInnerTitle title="IRR" />
            <ListOfInformation labels={irrLabels} contents={irrElems} />
            <TabInnerTitle title="Fund Terms" />
            <ListOfInformation labels={fundTermsLabels} contents={fundTermsElems} />
            <TabInnerTitle title="Other" />
            <ListOfInformation labels={otherLabels} contents={otherElems} />
        </GridTab>
    </>
}