import { bindActionCreators } from "@reduxjs/toolkit"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { actionCreators } from "../../../state"
import { P2P } from "../../../P2PContext";
import { DexxButton, DisabledButton } from "../../light/LightButton";
import { TitleColor1 } from "../../typography/Typogtaphy";
import P2PBuyPopup from "../../popup/P2PBuyPopup";
import P2PCreateOfferPopup from "../../popup/P2PCreateOfferPopup";
import { euroFormat } from "../../../utils";
import Cookies from 'universal-cookie';
import Popup, { DexxPopup } from '../../Popup'
import GenericTab from "../utils/GenericTab";
import CentralTabContent from "../utils/CentralTabContent";
import TableShadow from "../../light/TableShadow";


export default function P2PTokenTab(props: {
    p2ps: P2P[], tokenInfo: any
    refreshList: Function, isAmericanAndsportex?: boolean
}): JSX.Element {

    const cookies = new Cookies()
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const [showCreateOfferPopup, setShowCreateOfferPopup] = useState<boolean>(false)
    const [showBuyPopup, setShowBuyPopup] = useState<boolean>(false)
    const [p2p, setP2P] = useState<P2P>()
    const [responseDelete, setResponseDelete] = useState<any[]>([0, undefined])

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    const [confirmPopUp, setConfirmPopUp] = useState<boolean>(false)

    function handleClosePopUp() {
        setTitlePopUp("")
        setMsgPopUp("")
        setConfirmPopUp(false)
        setShowPopUp(false)
    }

    function handleAction() {
        setTitlePopUp("Confirm your action")
        setMsgPopUp("Are you sure you want to delete your P2P Offer?")
        setConfirmPopUp(true)
        setShowPopUp(true)
    }

    function handleConfirmation() {
        handleClosePopUp()
        if (p2p && p2p.uuid) {
            actions.deleteP2POffer(p2p.uuid, setResponseDelete)
        }
        else {
            setTitlePopUp("Sorry!")
            setMsgPopUp("The offer you have selected does not exist.")
            setShowPopUp(true)
        }
    }

    useEffect(() => {
        if (responseDelete && responseDelete[0] !== 0) {
            switch (responseDelete[0]) {
                //case 200:
                case 204:
                    setTitlePopUp("Success")
                    setMsgPopUp("Your P2P Offer has been deleted.")
                    props.refreshList(true)
                    break
                default:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("We encountered an error deleting your P2P Offer.  Please, try again.")
                    setShowPopUp(true)
                //setMsgPopUp(response.length > 0 ? response[1] ? response[1].error : "Error" : "Error")
            }
        }
    }, responseDelete)

    let p2ps = props.p2ps ? props.p2ps : []
    let r = p2ps.filter((l) => l.type_name === "ask").map((l, idx) => {
        return [l.token_total_amount, l.token_left_amount,
        "€" + euroFormat(l.per_token_price),
        <div className="flex justify-start md:justify-end" key={idx}>
            {
                cookies.get("username") !== l.acc_uuid && props.tokenInfo.p2p_flag ?
                    <DexxButton label={"buy"} noBorder={true}
                        color1="succ2" color2="primary" color3="succ4" color4="white"
                        className="rounded-lg px-10 bg-succ2 font-normal"
                        onClick={() => { setShowBuyPopup(true); setP2P(l) }}
                    /> :
                    cookies.get("username") !== l.acc_uuid && !props.tokenInfo.p2p_flag ?

                        <DisabledButton label={"buy"}
                            className="px-10 text-primary bg-succ2 rounded-lg "
                            fontClasses=" text-base " />
                        :
                        (
                            <DexxButton label={"delete"} noBorder
                                color1="white" color2="primary" color3="succ4"
                                className="px-10 rounded-lg bg-warning font-normal"
                                onClick={() => { handleAction(); setP2P(l) }}
                            />
                        )
            }
        </div>]
    })
    return <>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp}
            btnConfirm={confirmPopUp} handleConfirm={handleConfirmation} />
        {props.isAmericanAndsportex ? (
            <Popup show={showBuyPopup} title={"Sorry!"} msg={"US citizens are not allowed to buy this token."+
                " Take a look at Investment Restrictions for more details."} 
                btnOk={true} close={() => { setShowBuyPopup(false) }} />
        ) : (
            <DexxPopup show={showBuyPopup} title={"Buy from P2P"}
                msg={<P2PBuyPopup tokenInfo={props.tokenInfo} p2p={p2p}
                    isShown={showBuyPopup} closeFunction={() => { setShowBuyPopup(false) }}
                    refreshList={props.refreshList} />}
                btnOk={false}
                close={() => { setShowBuyPopup(false) }} />
        )
        }

        <DexxPopup show={showCreateOfferPopup} title={"Create Offer"}
            type="alternate"
            msg={<P2PCreateOfferPopup tokenInfo={props.tokenInfo}
                isShown={showCreateOfferPopup}
                closeFunction={() => { setShowCreateOfferPopup(false) }}
                refreshList={props.refreshList} />}
            btnOk={false}
            close={() => { setShowCreateOfferPopup(false) }} />

        <GenericTab>
            <TitleColor1 className="text-2xl">
                Offers:</TitleColor1>
            <div className="w-full flex my-4">
                {props.tokenInfo.p2p_flag && !props.isAmericanAndsportex ?
                    <DexxButton label={"Create a new one"} className="
                        rounded-lg px-6 bg-succ2 text-primary font-normal"
                        color1="succ2" color2="transparent" color3="primary" color4="white" noBorder
                        onClick={() => { setShowCreateOfferPopup(true) }} />
                    :
                    <DexxButton label={"Create a new one"} className="
                        rounded-lg px-6 bg-succ2_lighter text-primary font-normal" noCursor noHoverOnSelected
                        color1="succ2" color2="transparent" color3="primary" color4="white" noBorder />
                }
            </div>
            {p2ps.length > 0 ?
                <TableShadow headers={["original qty", "remaining qty", "Price", ""]} rows={r} />
                :
                <CentralTabContent className="mt-20 text-white mb-10">
                    There are no offers yet.
                </CentralTabContent>}
        </GenericTab>
    </>
}