import { useSelector } from "react-redux"
import { Appraisals } from "../../../AssetContext"
import { euroFormat, formatDate } from "../../../utils"
import { TitleColor1 } from "../../typography/Typogtaphy"
import { ImagesSlideShow } from "../../utils/ImagesSlideShow"
import GenericTab from "../utils/GenericTab"
import { State } from "../../../state"
import { AssetCategory } from "../../../PoolContext"
import GridTab from "../utils/GridTab"
import ListOfInformation from "../utils/ListOfInformation"
import HTMLContent from "../utils/HTMLContent"

function DocumentsDownload(props: { documents: any[], isLogged: boolean }): JSX.Element {
    function getNameFromUrl(url: string) {
        var noVars = url.split('?')[0]
        var name = noVars.substring(noVars.lastIndexOf('/') + 1)
        name = name.replace("coownership___", "COOWNERSHIP AGREEMENT: ")
        name = name.replace("prospectus___", "ASSET TOKEN PROSPECTUS: ")
        name = name.replace("_", " ").replace("-", " ").split('.')[0]
        return name
    }
    var tmp: any[] = []
    try {
        tmp = props.documents.map((doc: any, i: number) =>
            <div className="" key={(i + 1).toString()}>
                <label className="art-doc-item">
                    <a href={doc.url} target={"_blank"}>
                        <div className="text-white tracking-wider uppercase hover:bg-white_lighter
                            font-base text-sm md:text-base border-solid border-1 border-white rounded-md px-3 py-1 
                            shadow-xl shadow-black/50">
                            {getNameFromUrl(doc.filename)}
                        </div>
                    </a>
                </label>
            </div>)
    } catch (_) { }
    let res = <></>
    if (props.isLogged)
        res = tmp.length > 0 ? <div className="flex flex-wrap gap-2">{tmp}</div> : <></>
    else
        res = <div className="text-white tracking-wider uppercase font-base text-sm md:text-base">Please Log in or register to see the token's documentation.</div>
    return res
}

function ShowDetails(props: { info: any, cat: AssetCategory }): JSX.Element {
    let labels: string[] = []
    let descr: string[] = []
    let info = props.info
    const userCtx = useSelector((state: State) => state.detailAccount).payload
    const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0

    switch (props.cat) {
        case "ART":
            labels = ["artist", "Artwork Title", "Location Of Art", "Signed", "Technique", "size", "Year", "value",
                "insurance", "percentage tokenized", "subtype", "owners", "loan amount", "term in months"]
            descr = [info.artist, info.artwork_title, info.art_location, info.signed, info.technique, info.size,
            info.year, info.estimate, info.insurance, info.pct_tokenized, info.subtype,
            (info.owners ? info.owners.map((i: any) => i + " ") : undefined), info.loan_amount,
            info.term_in_months]
            break
        case "COLLECTABLES":
            labels = ["Name", "Model", "Year", "Make", "VIN", "Odometer", "Exterior", "Interior", "Transmission",
                "Engine Type", "Size", "Location", "Market Value", "Conditions", "Appraisals"]
            descr = [info.name, info.model, info.year, info.make, info.vin, info.odometer?.odometer_value + " "
                + info.odometer?.odometer_unit, info.exterior_color, info.interior_color, info.transmission,
            info.engine_type, info.engine_size, info.location, info.market_value, info.condition,
            (info.appraisals ? info.appraisals.map((i: Appraisals) => <div>{formatDate(i.date, offset) + ": " +
                euroFormat(i.value) + " Euro"}</div>) : undefined), info.loan_amount, info.term_in_months]
            break
        case "AIF":
            const aifInfo = info.overview
            labels = ["Target Allocation / Seniority", "Main Risk And Return Characteristics", "Deal Type",
                "Target Fund Size", "Investment Period", "Fund Name"]
            descr = [aifInfo.target_allocation, aifInfo.risk_return_characteristics, aifInfo.deal_type, aifInfo.target_fund_size,
            aifInfo.investment_period, aifInfo.fund_name]
            break
        default:
    }
    if (props.info) {
        if (props.cat === "AIF") {
            return <>
                <div className="grid md:grid-cols-2 gap-4">
                    <ListOfInformation labels={labels} contents={descr} />
                </div>
            </>
        } else {
            return <>
                <GridTab noMargin>
                    <ListOfInformation labels={labels} contents={descr} />
                </GridTab>
            </>
        }
    }
    return <></>
}

export default function OverviewTab(props: { info: any, assetInfo: any, documents: any[], media: any[], isLogged: boolean }): JSX.Element {
    var description = props.info.description ? props.info.description : "..."
    const category = props.assetInfo.asset_category
    return <>
        <GenericTab>
            <div className="grid lg:grid-cols-2">
                <div className="grid place-content-center">
                    {category === "AIF" ?
                        <div className="grid gap-4">
                            <ShowDetails info={props.info} cat={category} />
                        </div>
                        :
                        <div className="">
                            <HTMLContent alternate={true} label={"Description:"}
                                description={description} className="mt-5" />
                        </div>

                    }
                </div>

                <div className="grid place-content-center justify-items-center overflow-hidden">
                    <ImagesSlideShow media={props.media} />
                </div>


            </div>
            <div className="my-10 flex">
                <DocumentsDownload documents={props.documents} isLogged={props.isLogged} />
            </div>
            {category !== "AIF" ?
                <ShowDetails info={props.info} cat={category} />
                : <></>}

            {props.assetInfo.issuer_description || props.assetInfo.issuer_web_site ? (
                <div className="text-center lg:mx-20">
                    {props.assetInfo.issuer_description ? (<>
                        <div className="text-tertiary mb-5 mt-10 text-bold"><TitleColor1>Issuer Description:</TitleColor1></div>
                        <div className="text-tertiary text-center mb-5 mt-5 whitespace-pre-wrap">{props.assetInfo.issuer_description}</div>
                    </>) : <></>
                    }
                    {props.assetInfo.issuer_web_site ? (<>
                        <div className="text-tertiary mb-5 mt-20">Visit issuer's website: <a className="text-succ2"
                            href={props.assetInfo.issuer_web_site} target="_blank" >{props.assetInfo.issuer_web_site}</a></div>
                    </>) : <></>
                    }
                </div>
            ) : <></>
            }
        </GenericTab>
    </>

}