import { Link } from "react-router-dom";
import { euroFormat, euroQtyFormat, formatDate } from "../../../utils";
import { DexxButton } from "../../light/LightButton";
import TableShadow from "../../light/TableShadow";
import GenericTab from "../utils/GenericTab";
import TabInnerTitle from "../utils/TabInnerTitle";

export default function LiquidityPoolIssuerTab({ lps, offset, onClick }: { lps: any[], offset: number, onClick: Function }): JSX.Element {
    const headersLP = ["Pool Name", "Status", "Date created", "Token A", "Amount", "Fimart Euro Voucher",
        "Amount", "LP Token Supply"]
    const rows = lps ?
        lps.map(t => [t.lp_name, t.status_name, formatDate(t.date_created, offset),
        t.token_a_ticker, euroQtyFormat(t.amount_a), t.token_b_ticker,
        euroFormat(t.amount_b), euroQtyFormat(t.lp_token_supply),
        <Link to={"/issuer/liquidity-pool/" + t.uuid}><DexxButton label={"LP Details"} noBorder
            color1="succ2" color2="primary" color3="succ4" color4="white"
            className="rounded-lg px-2 bg-succ2 font-normal border-0" /></Link>]
        ) : []
    return <>
        <GenericTab>
            <div>
                <TabInnerTitle title="Liquidity Pools" />
                <TableShadow headers={headersLP} rows={rows} />
            </div>
            <div className="mt-4 flex gap-10">
                <TabInnerTitle title="Create A Liquidity Pool" />
                <div className="flex align-middle items-center place-content-center">
                    <DexxButton label={"Create"} className="px-5 bg-succ2 rounded-full font-semibold "
                        color1="succ2" color2="primary" color3="transparent" onClick={() => onClick()} />
                </div>
            </div>
        </GenericTab>
    </>
}