import GenericTab from "./GenericTab";

export default function GridTab(props: any): JSX.Element {
    return <>
        <GenericTab noMargin={props.noMargin}>
            <div className=" grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-2 gap-x-2">
                {props.children}
            </div>
        </GenericTab>
    </>
}