import { DexxButton } from "../light/LightButton"

export default function GenericPopup(props: 
        {children?: any, className?: string, closeFunction?: Function,
        handleSubmit?: Function}) : JSX.Element {
    return <>
        <div className="w-128 h-full font-roboto mt-2">
            {props.children ? props.children : ""}
            <div className="flex justify-between px-6 pb-2">
                {
                    props.closeFunction ?
                        <DexxButton onClick={() => {if(props.closeFunction) props.closeFunction()}} 
                            label={"close"} fontClasses="text-sm font-medium"
                            className="rounded-full px-8 py-3 bg-white drop-shadow-n" 
                            color1='white' color2='gray5'/> :
                        <></>
                }
                {
                    props.handleSubmit ?
                        <DexxButton onClick={() => {if(props.handleSubmit) props.handleSubmit()}} 
                            label={"confirm"} fontClasses="text-sm  font-medium" 
                            className="rounded-full px-16 bg-white py-3" 
                            color1='white' color2='primary'/> :
                        <></>
                }
            </div>
        </div>
    </>
}

export function PopupLabelAndValue(props: {label: string, 
        value: string | number | JSX.Element | undefined,
        className? : string}) : JSX.Element {
    return <>
        <div className={"text-primary font-semibold text-sm "+
                props.className}>
            {props.label}
            <div className="font-normal text-sm text-navy_blue">{props.value}</div>
        </div>
    </>
}

export function PopupLabelAndValueInline(props: {label: string, 
        value: string | number | JSX.Element | undefined,
        className? : string}) : JSX.Element {
    return <>
        <div className=" flex gap-2">
            <div className="font-semibold text-primary uppercase">{props.label}</div>
            {props.value}
        </div>
    </>

}

export function PopupGrayLine(props: {className?: string}) : JSX.Element {
    return <>
        <div className={"bg-gray_lines h-px w-full mb-2 "+props.className} />
    </>
}

export function PopupTitle(props: {color: string,
        className?: string, children?: any}) : JSX.Element {
    return <>
        <div className={"uppercase text-" + props.color + " text-base font-medium col-span-6"}>
                    {props.children}</div>
    </>
}

export function PopupText(props: {children?: any, className?: string}) {
    return <div className={"font-thin text-sm "+props.className}>{props.children}</div>
}