import { bindActionCreators } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { BackButton, DexxButton, LightButton } from "../components/light/LightButton";
import { actionCreators, State } from "../state";
import { euroFormat, formatDate, formatExpiringDate, convertCurrency, euroQtyFormat } from "../utils";
import { Link } from "react-router-dom"
import {TabsMenuWithFilters} from "../components/TabsMenu";
import { Asset } from "../AssetContext";
import { PrimarySaleGet, LiquidityPool } from "../PoolContext";
import Cookies from 'universal-cookie';
import Popup from '../components/Popup'
import { DashboardTab, Filter, TableButton, addBtns } from "../components/utils/IssuerDashboardUtils";

export default function IssuerDashboard(): JSX.Element {
    interface IssuedTokens {
        initialized_tokens: any[],
        in_process_tokens: any[],
        submitted_tokens: any[],
        rejected_tokens: any[],
        denied_tokens: any[],
        approved_tokens: any[],
        listed_tokens: any[],
        liquidated_tokens: any[]
    }
    interface IssuedAssets {
        initialized_assets: any[],
        in_process_assets: any[],
        submitted_assets: any[],
        approved_assets: any[],
        denied_assets: any[],
        rejected_assets: any[],
        tokenization_in_process_assets: any[],
        tokenized_assets: any[]
    }
    var defaultIssuedTokens: IssuedTokens = {
        initialized_tokens: [],
        in_process_tokens: [],
        submitted_tokens: [],
        rejected_tokens: [],
        denied_tokens: [],
        approved_tokens: [],
        listed_tokens: [],
        liquidated_tokens: []
    }
    var defaultIssuedAssets: IssuedAssets = {
        initialized_assets: [],
        in_process_assets: [],
        submitted_assets: [],
        denied_assets: [],
        rejected_assets: [],
        approved_assets: [],
        tokenization_in_process_assets: [],
        tokenized_assets: []
    }

    const cookies = new Cookies()
    let history = useHistory();
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const userCtx = detailAccountState.payload
    const issuedTokensState = useSelector((state: State) => state.issuedTokens)
    const issuedAssetsState = useSelector((state: State) => state.issuedAssets)

    const [issuedTokens, setIssuedToken] = useState(defaultIssuedTokens)
    const [issuedAssets, setIssuedAssets] = useState(defaultIssuedAssets)
    const [issuedPrimarySales, setIssuedPrimarySales] = useState<PrimarySaleGet[]>([])
    const [issuedLiquidityPools, setIssuedLiquidityPools] = useState<LiquidityPool[]>([])
    
    /* FILTERS */
    const [showFilters, setShowFilters] = useState(false)
    const [showInProcessAssets, setShowInProcessAssets] = useState(true)
    const [showSubmittedAssets, setShowSubmittedAssets] = useState(true)
    const [showRejectedAssets, setShowRejectedAssets] = useState(true)
    const [showDeniedAssets, setShowDeniedAssets] = useState(true)
    const [showApprovedAssets, setShowApprovedAssets] = useState(true)
    const [showTokenizationSubmittedAssets, setShowTokenizationSubmittedAssets] = useState(true)
    const [showTokenizedAssets, setShowTokenizedAssets] = useState(true)
    
    const [showTokens, setShowTokens] = useState(true)
    const [showInProcessTokens, setShowInProcessTokens] = useState(true)
    const [showSubmittedTokens, setShowSubmittedTokens] = useState(true)
    const [showRejectedTokens, setShowRejectedTokens] = useState(true)
    const [showDeniedTokens, setShowDeniedTokens] = useState(true)
    const [showApprovedTokens, setShowApprovedTokens] = useState(true)
    const [showListedTokens, setShowListedTokens] = useState(true)
    
    const [showPrimarySales, setShowPrimarySales] = useState(true)
    const [showPSIncoming, setShowPSIncoming] = useState(true)
    const [showPSActive, setShowPSActive] = useState(true)
    const [showPSExpired, setShowPSExpired] = useState(true)
    const [filteredPS, setFilteredPS] = useState<PrimarySaleGet[]>([])
    const [filteredLP, setFilteredLP] = useState<LiquidityPool[]>([])

    const [showLiquidityPools, setShowLiquidityPools] = useState(true)

    const hdsExpiringTokens = ["cat", "type", "ticker", "total supply", "max circulating supply", "proposed price", "expiring"]
    const hdsTokens = ["cat", "type", "ticker", "total supply", "max circulating supply", "available tokens", "circulating supply", "proposed price", "txn id"]
    const hdsNotTokens = ["cat", "type", "ticker", "total supply", "max circulating supply",  "proposed price", "comment"]
    const hdsSubmittedTokens = ["cat", "type", "ticker", "total supply", "max circulating supply", "proposed price"]
    const hdsCommentedAssets = ["cat", "type", "issuer", "name", "comment"]
    const hdsAssets = ["cat", "type", "issuer", "name"]
    const hdsTokenizingAssets = ["cat", "type", "issuer", "name", "ticker"]
    const hdsTokenizedAssets = ["cat", "type", "issuer", "name", "ticker"]
    const hdsPrimarySales = ["ticker", "status", "start", "end", "listed supply", "Tokens sold", "on sale", "price"]
    const hdsLiquidityPools = ["LP Name", "status", "date created", "Token A", "token A amount", "Token B", "Token B Amount", "LP shares"]
    const hdsLiquidatedTokens = ["cat", "type", "issuer", "name", "date created", "price", "status"]

    let arrayTmp = window.location.href.split("#")
    let tabToshow = arrayTmp[1] && arrayTmp[1] !== "" ? arrayTmp[1] : ""
    
    const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0

    const [showPopup, setShowPopup] = useState<boolean>(false)
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
    const [typeDelete, setTypeDelete] = useState<string>("")
    const [uuidDelete, setUuidDelete] = useState<string>("")

    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    const [confirmPopUp, setConfirmPopUp] = useState<boolean>(false)
    const [responseDelete, setResponseDelete] = useState<any>()


    function handleClosePopUp(){
        setTitlePopUp("")
        setMsgPopUp("")
        setConfirmPopUp(false)
        setShowDeletePopup(false)
        setShowPopup(false)
    }

    useEffect(() => {
        if(showDeletePopup){
            setTitlePopUp("Confirm delete " + typeDelete)
            setMsgPopUp("Are you sure you want to delete the selected " + typeDelete + "?")
            setConfirmPopUp(true)
        }
    }, [showDeletePopup])

    function handleConfirmation() {
        handleClosePopUp()
        setShowDeletePopup(false)
        if(typeDelete === "asset")
            actions.deleteNotApprovedAsset(uuidDelete, setResponseDelete)
        else 
            actions.deleteNotApprovedToken(uuidDelete, setResponseDelete)
    }

    useEffect(() => {
        if(responseDelete && responseDelete[0] === 204) {
            actions.getIssuedAssets()
        } else if(responseDelete && responseDelete[0] !== 204){
            setTitlePopUp("Alert")
            if(responseDelete && responseDelete[0] === 403) 
                setMsgPopUp("You don't have permissions to create this request")
            if(responseDelete && responseDelete[0] === 404) 
                setMsgPopUp("The token you have selected cannot be deleted.")
            else 
                setMsgPopUp("We encountered an error saving your data.")
            setConfirmPopUp(false)
            setShowDeletePopup(false)
            setShowPopup(true)
        }
    }, [responseDelete])

    useEffect(() => {
        actions.getIssuedTokens()
        actions.getIssuedAssets()
        actions.getIssuerPrimarySales(setIssuedPrimarySales)
        actions.getIssuerLiquidityPools("", setIssuedLiquidityPools)
    }, [])
    
    useEffect(() => {
        if (issuedTokensState.payload)
            setIssuedToken(issuedTokensState.payload)
    }, [issuedTokensState.payload])

    useEffect(() => {
        if (issuedAssetsState.payload)
            setIssuedAssets(issuedAssetsState.payload)
    }, [issuedAssetsState.payload])

    useEffect(() => {
        let filtered = [...issuedPrimarySales]
        let array_status:String[] = []
        if(showPSIncoming) array_status.push("Initialized")
        if(showPSActive) array_status.push("Started","Stopped")
        if(showPSExpired) array_status.push("Ended")
        if(showPSIncoming || showPSActive || showPSExpired) 
            filtered = filtered.filter((i) => {return array_status.includes(i.status)})
        setFilteredPS(filtered)
    }, [showPSIncoming, showPSActive, showPSExpired, issuedPrimarySales])

    useEffect(() => {
        let filtered = [...issuedLiquidityPools]
        filtered = filtered.filter((i) => {return i.deleted === undefined || i.deleted === false})
        setFilteredLP(filtered)
    }, [issuedLiquidityPools])

    function addBtnsAndPopup(row: any[], btnLabels: string[], btnUrls: string[], typeDelete:string, 
            asset_uuid: any, token_uuid: any) {
        let tmp = btnLabels.map((b, i) => {return <div><TableButton label={b} link={btnUrls[i] + 
            asset_uuid} key={i}/></div>})
        let tmp2 = <div className="grid justify-center"><PopupDelete label={"Delete"} 
            uuid={token_uuid !== "" ? token_uuid : asset_uuid} type={typeDelete} /></div>
        return [...row, ...tmp, tmp2]
    }

    function getAssetRows(l: Asset[], btnLabels: string[], 
            btnUrls: string[], type: "asset" | "delete" | "token+delete" | "tokenized" | "commented", 
            tkns?: IssuedTokens): any[] {
        if(!l) return []
        let tokens = tkns ? tkns : defaultIssuedTokens
        switch(type) {
            case "asset":
                return l.map(t => addBtns([t.asset_category, t.asset_type, t.issuer_name, t.name], 
                        btnLabels, btnUrls, t.uuid))
            case "delete":
                return l.map(t => addBtnsAndPopup([t.asset_category, t.asset_type, t.issuer_name, 
                        t.name], btnLabels, btnUrls, "asset", t.uuid, ""))
            case "token+delete":
                return l.map(t => {
                        let getTokenUuid = tokens.submitted_tokens.filter((i) => 
                            {return i.asset_uuid === t.uuid})
                        // getting token_uuid to delete it
                        return addBtnsAndPopup([t.asset_category, t.asset_type, t.issuer_name, 
                            t.name, getTicker(t.uuid, tokens)], btnLabels, btnUrls, "token", 
                                t.uuid, getTokenUuid[0]?.uuid)})
            case "tokenized":
                return l.map(t => {
                        let getTokenUuid = tokens.submitted_tokens.filter((i) => 
                            {return i.asset_uuid === t.uuid})
                        // getting token_uuid to delete it
                        return addBtnsAndPopup([t.asset_category, t.asset_type, t.issuer_name, 
                            t.name, getTicker(t.uuid, tokens)], btnLabels, btnUrls, "token", 
                                t.uuid, getTokenUuid[0]?.uuid)})
            case "commented":
                return l.map(t => {
                        let a = [t.asset_category, t.asset_type, t.issuer_name, t.name, t.comment]
                        return addBtns(a, btnLabels, btnUrls, t.uuid)})
            default:
                return []
        }
    }

    function getTokenRows(l: any[], btnUrls: string[], btnLabels: string[], 
            type: "token" | "expiring" | "not-token" | "submitted" | "ps" | "lp" | "liquidated") : any[] {
        if(!l) return []
        switch(type) {
            case "token":
                return l.map(t => {
                    let a = [t.asset_class, t.asset_type, t.ticker, euroQtyFormat(t.total_supply), 
                        euroQtyFormat(t.total_supply * t.pct_tokenized / 100) + " ("+
                            t.pct_tokenized+"%)", t.total_available !== undefined ? 
                                euroQtyFormat(t.total_available) : euroQtyFormat(t.total_supply), 
                        euroQtyFormat(t.shares_issued), convertCurrency(t.currency_cd) + 
                            euroFormat(t.price === undefined ? t.proposed_price : t.price),
                        <Link to={"/blockchain/transaction/"+t.trx_chain_id}>{t.trx_chain_id}</Link>]
                    return addBtns(a, btnLabels, btnUrls, t.asset_uuid)})
            case "expiring":
                return l.map(t => {
                    let a = [t.asset_class, t.asset_type, t.ticker, euroQtyFormat(t.total_supply),  
                        euroQtyFormat(t.total_supply * t.pct_tokenized / 100) + " ("+
                            t.pct_tokenized+"%)", convertCurrency(t.currency_cd)+
                        euroFormat(t.price === undefined ? t.proposed_price : t.price), 
                        formatExpiringDate(t.date_created, offset) ]
                    return addBtns(a, btnLabels, btnUrls, t.asset_uuid)})
            case "not-token":
                return l.map(t => {
                    let a = [t.asset_class, t.asset_type, t.ticker, euroQtyFormat(t.total_supply), 
                        euroQtyFormat(t.total_supply * t.pct_tokenized / 100) + " ("+t.pct_tokenized+"%)",
                        convertCurrency(t.currency_cd)+euroFormat(t.price === undefined ? 
                            t.proposed_price : t.price), t.comment]
                    return addBtns(a, btnLabels, btnUrls, t.asset_uuid)})
            case "submitted":
                return l.map(t => {
                    let a = [t.asset_class, t.asset_type, t.ticker, euroQtyFormat(t.total_supply), 
                        euroQtyFormat(t.total_supply * t.pct_tokenized / 100) + " ("+t.pct_tokenized+"%)", 
                        convertCurrency(t.currency_cd)+euroFormat(t.price === undefined ? 
                            t.proposed_price : t.price)]
                    return addBtns(a, btnLabels, btnUrls, t.asset_uuid)})
            case "ps":
                return l.map(t => addBtns([t.token_ticker, t.status, formatDate(t.date_start, offset), 
                    formatDate(t.date_end, offset), euroQtyFormat(t.total_supply), 
                    euroQtyFormat(t.shares_issued), euroQtyFormat(t.total_supply - t.shares_issued), 
                    convertCurrency(t.currency_cd)+euroFormat(t.price)],btnLabels, btnUrls, t.uuid))
            case "lp":
                return l.map(t => addBtns([t.lp_name, t.status_name, formatDate(t.date_created, offset), 
                    t.token_a_ticker, euroQtyFormat(t.amount_a), t.token_b_ticker, euroFormat(t.amount_b), 
                    t.lp_token_supply], btnLabels, btnUrls, t.uuid))
            case "liquidated":
                return l.map(t => addBtns([t.asset_class, t.asset_type, t.ticker, t.asset_name, 
                    formatDate(t.date_created, offset), t.buyback_price, 
                    t.buyback_price ? "Liquidated" : "in process"], btnLabels, btnUrls, t.asset_uuid))
            default:
                return []
        }
    }

    function getTicker(asset_uuid: any, tokens: IssuedTokens){
        function filterFunction(s : any[]) {
            return s?.filter((t: any) => t.asset_uuid === asset_uuid)
        }
        let token = filterFunction(tokens?.in_process_tokens)
        if(token.length) return token[0].ticker 
        token = filterFunction(tokens.submitted_tokens)
        if(token.length) return token[0].ticker 
        token = filterFunction(tokens.rejected_tokens)
        if(token.length) return token[0].ticker 
        token = filterFunction(tokens.denied_tokens)
        if(token.length) return token[0].ticker 
        token = filterFunction(tokens.approved_tokens)
        if(token.length) return token[0].ticker 
        token = filterFunction(tokens.listed_tokens)
        if(token.length) return token[0].ticker 
    }

    function PopupDelete(props: { label: string, uuid: string, type: string }): JSX.Element {
        return <>
            <LightButton label={props.label} className="rounded m-1 font-normal px-2" 
                onClick={function(){setShowDeletePopup(true); setTypeDelete(props.type); setUuidDelete(props.uuid)}} />
        </>
    }

    function AssetFilters(): JSX.Element {
        return <>
            <Filter label="In Process Assets" checked={showInProcessAssets} 
                setFunction={setShowInProcessAssets} />
            <Filter label="Submitted Assets" checked={showSubmittedAssets} 
                setFunction={setShowSubmittedAssets} />
            <Filter label="Pending Notifications" checked={showRejectedAssets} 
                setFunction={setShowRejectedAssets} />
            <Filter label="Denied Assets" checked={showDeniedAssets} 
                setFunction={setShowDeniedAssets} />
            <Filter label="Approved Assets" checked={showApprovedAssets} 
                setFunction={setShowApprovedAssets} />
            <Filter label="Approved Assets" checked={showApprovedAssets} 
                setFunction={setShowApprovedAssets} />
            <Filter label={"Tokenization Submitted Assets"} checked={showTokenizationSubmittedAssets} 
                setFunction={setShowTokenizationSubmittedAssets} />
            <Filter label={"Tokenized Assets"} checked={showTokenizedAssets} 
                setFunction={setShowTokenizedAssets} />
        </>
    }

    function TokenFilters(): JSX.Element {
        return <>
            <Filter label={"Show tokens"} checked={showTokens} 
                setFunction={setShowTokens} />
            <Filter label={"Show Primary Sales"} checked={showPrimarySales} 
                setFunction={setShowPrimarySales} />
            <Filter label={"Show Liquidity Pools"} checked={showLiquidityPools} 
                setFunction={setShowLiquidityPools} />
            
            {showTokens || showPrimarySales ? (<div className="m-5 col-span-12"></div>) : "" }
            
            {showTokens ? (<>
                    <Filter label={"In Process Tokens"} checked={showInProcessTokens} 
                        setFunction={setShowInProcessTokens} />
                    <Filter label={"Submitted Tokens"} checked={showSubmittedTokens} 
                        setFunction={setShowSubmittedTokens} />
                    <Filter label={"Pending Notifications"} checked={showRejectedTokens} 
                        setFunction={setShowRejectedTokens} />
                    <Filter label={"Denied Tokens"} checked={showDeniedTokens} 
                        setFunction={setShowDeniedTokens} />
                    <Filter label={"Approved Tokens"} checked={showApprovedTokens} 
                        setFunction={setShowApprovedTokens} />
                    <Filter label={"Listed Tokens"} checked={showListedTokens} 
                        setFunction={setShowListedTokens} />
                </>) : ""}
            
            {showTokens && showPrimarySales ? (<div className="m-5 col-span-12"></div>) : "" }

            {showPrimarySales ? (<>
                    <Filter label={"Primary Sales - Incoming"} checked={showPSIncoming} 
                        setFunction={setShowPSIncoming} />
                    <Filter label={"Primary Sales - Active"} checked={showPSActive} 
                        setFunction={setShowPSActive} />
                    <Filter label={"Primary Sales - Expired"} checked={showPSExpired} 
                        setFunction={setShowPSExpired} />
                </>) : ""}

            <div className="m-5 col-span-12"></div>
        </>
    }

    function IssuerTab(props: {control: boolean, list: any[], label: string, index: number, 
        headers: any[], rows: any[], isAsset?: boolean}) {
        const [control, setControl] = useState<boolean>(window.sessionStorage.getItem(props.label) === "true")
        const [changeState, setChangeState] = useState<boolean>(false)

        useEffect(() => {
            if(changeState){
                window.sessionStorage.setItem(props.label, control.toString())
                setChangeState(false)
            }
        }, [control])

        return <>
            {props.control && props.list ? <>
                <DashboardTab label={props.label} headers={props.headers} rows={props.rows}
                    active={control} onClick={() => {setChangeState(true); setControl(!control)}} 
                    dark={props.index % 2 === 1} />
                </>
                : ""}
            </>
    }
    
    function AssetsTables(): JSX.Element {
        
        return <>
            <IssuerTab control={showInProcessAssets} list={issuedAssets.in_process_assets} 
                label={"In process Assets"} index={0} headers={[...hdsAssets, ...["", "", ""]]} 
                rows={getAssetRows(issuedAssets.in_process_assets, ["modify", "add info"], 
                    ["/issuer/asset/manage/", "/issuer/extra-info/"], "delete")} isAsset />
            <IssuerTab control={showSubmittedAssets} list={issuedAssets.submitted_assets} 
                label={"Submitted Assets"} index={1} headers={[...hdsAssets, ...["", ""]]} 
                rows={getAssetRows(issuedAssets.submitted_assets, 
                    ["detail"], ["/issuer/extra-info/"], "delete")} isAsset />
            <IssuerTab control={showRejectedAssets} list={issuedAssets.rejected_assets} 
                label={"Pending Notifications"} index={2} headers={[...hdsCommentedAssets, ...["", ""]]} 
                rows={getAssetRows(issuedAssets.rejected_assets, ["modify", "add info"], 
                    ["/issuer/asset/manage/", "/issuer/extra-info/"], "commented")} isAsset />
            <IssuerTab control={showDeniedAssets} list={issuedAssets.denied_assets}
                label={"Denied assets"} index={3} headers={[...hdsCommentedAssets, ...[""]]} 
                rows={getAssetRows(issuedAssets.denied_assets, ["detail"], 
                    ["/issuer/extra-info/"], "commented")} isAsset />
            <IssuerTab control={showApprovedAssets} list={issuedAssets.approved_assets} 
                label={"Approved assets"} index={4} headers={[...hdsAssets, ...["",""]]} 
                rows={getAssetRows(issuedAssets.approved_assets, ["detail", "tokenize"], 
                    ["/issuer/extra-info/", "/issuer/asset/tokenize/"], "asset")} isAsset />
            <IssuerTab control={showTokenizationSubmittedAssets} list={issuedAssets.tokenization_in_process_assets} 
                label={"Tokenization In Process assets"} index={5} headers={[...hdsTokenizingAssets, ...["", ""]]} 
                rows={getAssetRows(issuedAssets.tokenization_in_process_assets, 
                    ["detail"], ["/issuer/asset/tokenize/"], "token+delete",issuedTokens)} isAsset />
            <IssuerTab control={showTokenizedAssets} list={issuedAssets.tokenized_assets} 
                label={"Tokenized assets"} index={6} headers={[...hdsTokenizedAssets, ...["", ""]]} 
                rows={getAssetRows(issuedAssets.tokenized_assets, ["add info"],
                    ["/issuer/extra-info/"], "tokenized", issuedTokens)} isAsset />
        </>
    }

    function TokensTables(): JSX.Element {

        return <>
            <IssuerTab control={(showTokens && showInProcessTokens)} 
                list={issuedTokens.in_process_tokens} label={"In process tokens"} 
                index={0} headers={hdsExpiringTokens} rows={getTokenRows(issuedTokens.in_process_tokens, 
                    ["/issuer/asset/tokenize/"], ["modify"], "expiring")} />
            <IssuerTab control={showTokens && showSubmittedTokens} 
                list={issuedTokens.submitted_tokens} label={"Submitted tokens"} 
                index={1} headers={[...hdsSubmittedTokens, [""]]} rows={getTokenRows(issuedTokens.submitted_tokens, 
                    ["/issuer/tokens/"], ["details"], "submitted")} />
            <IssuerTab control={showTokens && showRejectedTokens} 
                list={issuedTokens.rejected_tokens} label={"Pending Notifications"} 
                index={2} headers={hdsNotTokens} rows={getTokenRows(issuedTokens.rejected_tokens, 
                    ["/issuer/asset/tokenize/"], ["modify"], "not-token")} />
            <IssuerTab control={showTokens && showDeniedTokens} 
                list={issuedTokens.denied_tokens} label={"Denied tokens"} 
                index={3} headers={hdsNotTokens} rows={getTokenRows(issuedTokens.denied_tokens, 
                   ["/issuer/tokens/"], ["details"], "expiring")} />
            <IssuerTab control={showTokens && showApprovedTokens} 
                list={issuedTokens.approved_tokens} label={"Approved tokens"} 
                index={4} headers={hdsTokens} rows={getTokenRows(issuedTokens.approved_tokens, 
                    ["/issuer/tokens/"], ["details"], "token")} />
            <IssuerTab control={(showTokens && showListedTokens) || showPrimarySales} 
                list={issuedTokens.listed_tokens} label={"Listed tokens"} 
                index={5} headers={[...hdsTokens, ...["", ""]]} rows={getTokenRows(issuedTokens.listed_tokens, 
                    ["/issuer/tokens/","/issuer/primary-sale/"], ["details","P. S. setup"], "token")} />
            <IssuerTab control={showPrimarySales}
                list={filteredPS} label={"Primary Sales"} index={6} 
                headers={[...hdsPrimarySales,...[""]]} rows={getTokenRows(filteredPS, 
                    ["/issuer/primary-sale/"], ["PS details"], "ps")} />
            <IssuerTab control={showLiquidityPools} 
                list={filteredLP} label={"Liquidity Pools"} index={7} 
                headers={[...hdsLiquidityPools,...[""]]} rows={getTokenRows(filteredLP, 
                    ["/issuer/liquidity-pool/"], ["LP details"], "lp")} />
            <IssuerTab control={showTokens} 
                list={issuedTokens.liquidated_tokens} label={"Liquidated tokens"} 
                index={8} headers={[...hdsLiquidatedTokens, ...[""]]} rows={getTokenRows(issuedTokens.liquidated_tokens, 
                    ["/issuer/tokens/"], ["details"], "liquidated")} />
        </>
    }

    return <>
        <div className="py-5 mx-neg40">
            <Popup show={showPopup} title={titlePopUp} msg={msgPopUp} btnOk={true} close={() => {setShowPopup(false)}} />
            <Popup show={showDeletePopup} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp} 
                btnConfirm={confirmPopUp} handleConfirm={handleConfirmation}/>
            <div className="flex pl-16">
                <div className="flex gap-4">
                    <Link to="/issuer/asset/new">
                        <DexxButton label={"New Asset"} className="rounded-full px-4 md:px-8 font-semibold 
                        bg-succ2_light" color1="dark_blue" borderColor="succ2" />
                    </Link>
                    <DexxButton label={"Filter"} className="rounded-full px-4 md:px-8 font-semibold" 
                        onClick={() =>setShowFilters(!showFilters)} />
                </div>
            </div>
            
            <div className="w-full">
                <TabsMenuWithFilters headers={["Assets", "Tokens"]} 
                    filters={[AssetFilters(), TokenFilters()]} showFilters={showFilters}
                    contents={[AssetsTables(), TokensTables()]}
                    tabToShow={tabToshow} classNameHdsSelected=" text-xl underline " />
            </div>

            <div className="m-10"></div>
            <div className="flex justify-center mt-5">
                <BackButton previousPage="/issuer/portfolio"/>
            </div>

        </div>
    </>
}