import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux";
import { defaultLiquidityPool, defaultAssetInfo, defaultSecurityToken, LiquidityPool } from "../PoolContext";
import { actionCreators, State } from "../state";
import { BackButton, BuySellButton } from "../components/light/LightButton";
import Popup, { DexxPopup } from "../components/Popup";
import { BigTitle, TabTitle } from "../components/typography/Typogtaphy";
import SwapLPPopup from "../components/popup/SwapLPPopup";
import Cookies from 'universal-cookie';
import TokenBody from "../components/tokenBody/TokenBody";
import TokenGeneralDetails from "../components/tokenDetails/TokenGeneralDetails";
const cookies = new Cookies();
export interface LPandPrice {
    uuid: string
    price: string
}

export default function SecondaryMarketRoute(props: any): JSX.Element {
    const token_uuid = props.match.params.uuid
    const lpParam = props.match.params.lp
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [token_a, setToken_a] = useState(defaultSecurityToken)
    const [allTokenLiquidityPools, setAllTokenLiquidityPools] = useState<LiquidityPool[]>([])
    const [approvedTokenLiquidityPools, setApprovedTokenLiquidityPools] = useState<LiquidityPool[]>([])
    const [lpCount, setLpCount] = useState<number>(0)
    const [p2p, setP2p] = useState<any[]>([])
    const [tabWithMarket, setTabWithMarket] = useState<string>("")

    const [cheaper, setCheaper] = useState<LPandPrice>({ "uuid": "", "price": "999999999999999" })
    const [expensive, setExpensive] = useState<LPandPrice>({ "uuid": "", "price": "0" })

    const [buyFromCheaper, setBuyFromCheaper] = useState<LiquidityPool>(defaultLiquidityPool)
    const [sellToExpensive, setSellToExpensive] = useState<LiquidityPool>(defaultLiquidityPool)
    const [quote, setQuote] = useState<any>()

    const [assetInfo, setAssetInfo] = useState(defaultAssetInfo)
    const [media, setMedia] = useState("")
    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [isBuy, setIsBuy] = useState<boolean>(true)

    const basicAccountState = useSelector((state: State) => state.basicAccount)
    const basicInfo = basicAccountState.payload
    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const userCtx = detailAccountState.payload
    const [isAmericanAndSportex, setIsAmericanAndSportex] = useState<boolean>(false)
    const isLogged = cookies.get("logged_in")

    useEffect(() => {
        if (token_uuid !== 0 && token_uuid !== "0") {
            actions.getTokenInfoByTokenUUID(token_uuid, setToken_a, isLogged)
            actions.getAllLiquidityPoolRequests(setAllTokenLiquidityPools, { "token_a_uuid": token_uuid })
        }
    }, [token_uuid, basicInfo, isLogged])

    useEffect(() => {
        if (userCtx && token_a)
            setIsAmericanAndSportex(userCtx?.tax_residence === "United States" && token_a.asset_class === "SPORTEX" ? true : false)
    }, [token_a, userCtx])

    useEffect(() => {
        if (token_a && token_a.asset_uuid) {
            actions.getAssetInfo(token_a.asset_uuid, setAssetInfo, isLogged)
            actions.getAssetMedia(token_a.asset_uuid, setMedia, isLogged)

        }
        if (token_a && token_a.uuid) {
            if (isLogged) {
                actions.getP2P({
                    token_a_uuid: token_a.uuid
                }, setP2p)
            }
        }
    }, [token_a])

    useEffect(() => {
        if (isLogged) {
            if (allTokenLiquidityPools) {
                var filteredLP = allTokenLiquidityPools.filter((lp: any) => lp.token_a_uuid === token_uuid && lp.status_name === "Approved")
                setApprovedTokenLiquidityPools(filteredLP)
                setLpCount(filteredLP.length)

                if (lpParam || (!lpParam && filteredLP.length))
                    setTabWithMarket("lp")

                filteredLP.forEach((lp, i) => {
                    let r = {
                        lp_uuid: lp.uuid,
                        token_a_uuid: token_uuid,
                        amount_a: 1,
                        is_buy: true
                    }
                    actions.getLPQuote(r, setQuote)
                })
            }
        }
    }, [allTokenLiquidityPools])

    useEffect(() => {
        if (quote && quote[0] === 200) {
            if (parseFloat(quote[1].gross_amount) < parseFloat(cheaper.price))
                setCheaper({ "uuid": quote[1].lp_uuid, "price": quote[1].gross_amount })
            if (parseFloat(quote[1].gross_amount) > parseFloat(expensive.price))
                setExpensive({ "uuid": quote[1].lp_uuid, "price": quote[1].gross_amount })
        }
    }, [quote])

    useEffect(() => {
        if (cheaper.uuid !== "") {
            var c: any = allTokenLiquidityPools.filter((lp: any) => lp.uuid === cheaper.uuid && lp.status_name === "Approved")
            setBuyFromCheaper(c[0])
        }
        if (expensive.uuid !== "") {
            var e: any = allTokenLiquidityPools.filter((lp: any) => lp.uuid === expensive.uuid && lp.status_name === "Approved")
            setSellToExpensive(e[0])
        }
    }, [cheaper, expensive])

    useEffect(() => {
        if (p2p) {
            let tmp: any = p2p.filter((o: any) => o.deleted === undefined || o.deleted === false)
            if (isLogged && !lpParam && tabWithMarket === "" && tmp.length > 0)
                setTabWithMarket("p2p")
        }
    }, [p2p, p2p[1]])

    function reloadOnClosePopup() {
        actions.getAllLiquidityPoolRequests(setAllTokenLiquidityPools, { "token_a_uuid": token_uuid })
    }

    return (
        <>
            {(!basicInfo?.is_investor && !basicInfo?.kyc_submitted) ? (
                <Popup show={showPopUp} title={"Sorry!"} msg={"Please complete the KYC in order to proceed with the purchase"} 
                    btnOk={true} close={() => { setShowPopUp(false) }} />
            ) : isAmericanAndSportex ? (
                <Popup show={showPopUp} title={"Sorry!"}
                    msg={isBuy ?
                        "US citizens are not allowed to buy this token. Take a look at Investment Restrictions for more details."
                        :
                        "US citizens are restricted from purchasing this token. View this token’s Investment Restrictions for more information."}
                    btnOk={true} close={() => { setShowPopUp(false) }} />
            ) : (
                <DexxPopup show={showPopUp} title={isBuy ? "Buy From Liquidity Pool" : "Sell To Liquidity Pool"}
                    type={isBuy ? "success" : "alternate"}
                    msg={<SwapLPPopup isBuy={isBuy} buyLP={buyFromCheaper} sellLP={sellToExpensive} tokenName={assetInfo.name}
                        closeFunction={function () { setShowPopUp(false); actions.getAllLiquidityPoolRequests(setAllTokenLiquidityPools, { "token_a_uuid": token_uuid }) }} />}

                    btnOk={false} close={() => { setShowPopUp(false) }} />
            )}
            <div className="mx-neg20">
                <TabTitle className="flex place-content-center">{assetInfo.asset_category}</TabTitle>
                <BigTitle title={assetInfo ? assetInfo.name : ""} />
                <div className="flex flex-wrap w-full justify-between md:px-10 mb-10">
                    <div>
                        <BuySellButton inactive={!(isLogged && token_a.lp_flag && lpCount > 0)} label={"Sell"}
                            onClick={function () { setShowPopUp(!showPopUp); setIsBuy(false); }} />
                    </div>
                    <div>
                        <BuySellButton inactive={!(isLogged && token_a.lp_flag && lpCount > 0)} label={"Buy"}
                            onClick={function () { setShowPopUp(!showPopUp); setIsBuy(true); }} />
                    </div>
                </div>
                <TokenGeneralDetails info={{type: "sm", content: token_a}}/>
            </div>
            <div className="grid pb-10">
                {<TokenBody origin={"2m"} lpCount={lpCount} isLogged={isLogged} media={media} tokenInfo={token_a}
                    assetInfo={assetInfo} reload={reloadOnClosePopup} completeView={true} openedTab={tabWithMarket}
                    lpInfo={approvedTokenLiquidityPools} />}
            </div>
            <div className="flex justify-center mb-5">
                <BackButton previousPage={
                    props.location.state?.from ? props.location.state.from : "/invest#secondary"} />
            </div>
        </>)
}