import PieAndList from "../../PieAndList";
import GenericTab from "../utils/GenericTab";
import TabInnerTitle from "../utils/TabInnerTitle";

export default function CapTableTab({ tokenStats }: { tokenStats: any }): JSX.Element {
    return <>
        <GenericTab>
            <div>
                <TabInnerTitle title="Actual Token's Holders" />
                <PieAndList tokenStats={tokenStats && tokenStats.cap_table ?
                    tokenStats.cap_table : { rows: [] }} />
            </div>
        </GenericTab>
    </>
}