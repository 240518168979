import { euroFormat, formatDate, quantityFormat } from "../../utils"

export default function infoAggregator(info: { type: "sm" | "ps" | "sc" | "create", 
        content: any }, offset?: any): { label: string, content: string, color?: string }[] {
    const token = info.content
    switch (info.type) {
        case "ps":
            const offsetTimezone = offset ? offset : 0
            return [
                { label: "Cat", content: token.asset_category },
                { label: "Type", content: token.asset_type },
                { label: "Ticker", content: token.token_ticker },
                { label: "Start Date", content: formatDate(token.date_start, offsetTimezone) },
                { label: "End Date", content: formatDate(token.date_end, offsetTimezone) },
                { label: "Supply", content: quantityFormat(token.total_supply) },
                { label: "Available tokens", content: quantityFormat(token.total_supply - token.shares_issued) },
                { label: "Price", content: euroFormat(token.price) },
                { label: "Status", content: token.status }
            ]
        case "create":
            return [
                { label: "Cat", content: token.asset_class },
                { label: "Type", content: token.asset_type },
                { label: "Ticker", content: token.ticker },
                { label: "Name", content: token.asset_name },
                { label: "Total Supply", content: quantityFormat(token.total_supply) },
                { label: "Listing Limit", content: token.listing_limit + "%" },
                { label: "Proposed Price", content: euroFormat(token.price) },
                { label: "Available Tokens", content: token.total_available }
            ]
        case "sc":
            return [
                { label: "Cat", content: token.asset_class },
                { label: "Type", content: token.asset_type },
                { label: "Ticker", content: token.ticker },
                { label: "Supply", content: quantityFormat(token.total_supply) },
                { label: "Price", content: euroFormat(token.price) },
            ]

            break
        case "sm":
            const tokenChange = parseFloat(token.change24h)
            const change24hColor = tokenChange > 0 ? "greenMax" : 
                ((tokenChange === 0 || !tokenChange) ? "gray4" : "error1")
            const change24hSign = tokenChange > 0 ? "+" : ""
            return [
                { label: "Cat", content: token.asset_class },
                { label: "Type", content: token.asset_type },
                { label: "Ticker", content: token.ticker },
                { label: "Total Supply", content: quantityFormat(token.total_supply) },
                { label: "Last Token Price", content: euroFormat(token.price) },
                { label: "Total Asset Value", content: euroFormat(token.capitalization) },
                { label: "24h Change", content: change24hSign + euroFormat(token.change24h), color: change24hColor },
            ]
    }
    return []
}