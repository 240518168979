import { PieChart } from "react-minimal-pie-chart";
import TabInnerTitle from "./tokenBody/utils/TabInnerTitle";
import TableShadow from "./light/TableShadow";
import { euroFormat, euroQtyFormat } from "../utils";
import CentralTabContent from "./tokenBody/utils/CentralTabContent";


export default function PieAndList(props: { tokenStats: any }): JSX.Element {

    const countries = props.tokenStats ? Object.keys(props.tokenStats.rows) : []
    const countryvalues = props.tokenStats ? Object.values(props.tokenStats.rows) : []
    const colors = [
        { name: "seafoam", code: "#80C8C4" }, { name: "succ7", code: "#AC4BCE" }, { name: "peach", code: "#FFB37C" },
        { name: "succ6", code: "#4B58CE" }, { name: "succ8", code: "#C887DF" }, { name: "succ5", code: "#6772CD" },
        { name: "error1", code: "#E95C7B" }, { name: "succ2", code: "#7ADFAE" }, { name: "succ4", code: "#4494CE" },
        { name: "violet", code: "#AF6CFC" }, { name: "succ1", code: "#8CD9CF" }, { name: "error2", code: "#FFB37C" },
        { name: "background", code: "#E5E5E5" }, { name: "light_blue", code: "#1890E3" }, { name: "wild_blue_yonder", code: "#7A8EC0" }]
    let arrayCountry: any[] = []
    let arrayPie: any[] = []
    if (countries) {
        countries.forEach((country: string, i: number) => {
            let obj: any = countryvalues[i]
            let details = obj.entries && obj.entries.length ? obj.entries.length : 0
            let perc = (100 * obj.total_quantity / props.tokenStats.total_quantity)
            let countryInfo = {
                "label": country + " (" + details + ")",
                "qty": obj.total_quantity,
                "val": obj.total_value,
                "perc": perc,
                "col": colors[i].name
            }
            arrayCountry.push(countryInfo)
            arrayPie.push({ title: country, value: perc, color: colors[i].code })
        })
    }

    const mockCountries = [
        { val: "20", label: "Italy", col: colors[0].name },
        { val: "300", label: "Germany", col: colors[1].name },
        { val: "50", label: "Sweden", col: colors[2].name },
    ]
    const mockPie = [
        { title: "Italy", value: 10, color: colors[0].code },
        { title: "Germany", value: 70, color: colors[1].code },
        { title: "Sweden", value: 20, color: colors[2].code }
    ]

    return <>
        {mockCountries.length > 0 ? <>
            <div className="grid md:grid-cols-2 gap-4">
                <div className="gap-2 grid items-center">
                    {arrayCountry.map((country, i) => {
                        return <div className="uppercase flex items-center bg-white_lighter p-2 rounded-lg shadow-xl shadow-black/50 justify-between md:px-20">
                            <div className={"text-base md:text-lg text-" + country.col}>{country.label}</div>
                            <div className="text-white text-lg md:text-2xl">&euro;{euroFormat(country.val)}</div>
                        </div>
                    })}
                </div>
                <div className="flex place-content-center justify-evenly">
                    <div className="w-48 grid items-center self-center">
                        <PieChart className=""
                            data={arrayPie}
                            lineWidth={12}
                            paddingAngle={10}
                            animate={true}
                            animationDuration={3000}
                        />
                    </div>
                </div>
            </div>
            <div className="my-6">
                {countries.map((country, i) => {
                    let obj: any = countryvalues[i]
                    return <>
                        <TabInnerTitle title={country} />
                        <TableShadow headers={["Investor", "Quantity", "Value"]} rows={
                            obj.entries ? obj.entries.map((user: any) =>
                                [user.user_email, euroQtyFormat(user.quantity), euroFormat(user.value)]) : []} />
                    </>
                })}
            </div>
            <div className="text-white text-lg flex items-center ">
                <div className="bg-white_lighter rounded-lg shadow-xl shadow-black/50 px-4 py-2">
                    Total quantity: {props.tokenStats ? euroQtyFormat(props.tokenStats.total_quantity) : ""}
                </div>
            </div>
        </> : <>
            <CentralTabContent className="my-20 text-white">
                No Data
            </CentralTabContent>
        </>}
    </>
}