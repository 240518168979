import { useEffect, useState } from "react"
import { ChartData } from "../../utils/DexxChart"
import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { actionCreators } from "../../../state"
import DexxCandleChart from "../../utils/DexxCandleChart"

export default function SecondaryMarketStatistics(props: { tokenUuid: string }): JSX.Element {
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [tradeChartData, setTradeChartData] = useState<any[]>([])

    useEffect(() => {
        if (props.tokenUuid) {
            actions.getTradeMetricsToken(props.tokenUuid, "365", setTradeChartData)
        }
    }, [props.tokenUuid])

    return <>
        <div className="grid lg:mx-40 xl:mx-80">
            <div className="col-span-4 flex-col flex justify-center center h-full" id='container-chart'>
                <DexxCandleChart serie={tradeChartData ? tradeChartData.slice(0) : []}/>
            </div>
        </div>
    </>
}