import { SecurityToken } from "../PoolContext";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { actionCreators, State } from "../state";
import { useEffect, useState } from "react"
import TabsMenu from "./TabsMenu";
import { euroFormat, euroQtyFormat, formatDate } from "../utils";
import { LightButton, DexxButton, DisabledButton } from "./light/LightButton";
import LightTable from "./light/LightTable";
import { PrimarySaleGet, LiquidityPool } from "../PoolContext";
import { Link } from "react-router-dom"
import Cookies from 'universal-cookie';
import Popup, { DexxPopup } from '../components/Popup'
import LiquidationPopup from "../components/popup/LiquidationPopup"
import CreateLPPopup from "./popup/CreateLPPopup";
import SecondaryMarketStatistics from "./tokenBody/tabs/SecondaryMarketStatistics";
import TokenControlFunctionsTab from "./tokenBody/issuerTabs/TokenControlFunctionsTab";
import IssuanceStatusTab from "./tokenBody/issuerTabs/IssuanceStatusTab";
import InvestorBreakdown from "./tokenDetails/InvestorBreakdownTab";
import CapTableTab from "./tokenBody/issuerTabs/CapTableTab";
import PerformanceDetailsTab from "./tokenBody/issuerTabs/PerformanceDetailsTab";
import LiquidityPoolIssuerTab from "./tokenBody/issuerTabs/LiquidityPoolIssuerTab";

const cookies = new Cookies();

export default function IssuerTokenBody(props: { tokenInfo: SecurityToken, media: any }): JSX.Element {

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [tokenStats, setTokenStats] = useState<any>()
    const [issuedPrimarySales, setIssuedPrimarySales] = useState<PrimarySaleGet[]>([])
    const [issuedLiquidityPools, setIssuedLiquidityPools] = useState<LiquidityPool[]>([])
    const [filteredLP, setFilteredLP] = useState<LiquidityPool[]>([])
    const [tokenPSs, setTokenPSs] = useState<PrimarySaleGet[]>([])

    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const userCtx = detailAccountState.payload
    const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0
    const isDev = cookies.get("env") === "develop"

    const [liquidationPriceRequest, setLiquidationPriceRequest] = useState<any[]>([])
    const [liquidityPoolCreationRequest, setLiquidityPoolCreationRequest] = useState<any[]>([])

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    const [showLiquidationPopUp, setShowLiquidationPopUp] = useState<boolean>(false)
    const [showCreateLPPopUp, setShowCreateLPPopUp] = useState<boolean>(false)
    const [nextSuffix, setNextSuffix] = useState<number>(0)

    let isLiquidated = !(props.tokenInfo.buyback_price && props.tokenInfo.buyback_price === "0")

    let tokensSold: any
    try {
        tokensSold = parseInt(props.tokenInfo.shares_issued)
    } catch (e) { tokensSold = 0 }

    function handleClosePopUp() {
        setTitlePopUp("")
        setMsgPopUp("")
        setShowPopUp(false)
    }


    useEffect(() => {
        if (liquidationPriceRequest.length > 1) {
            setShowPopUp(true)
            switch (liquidationPriceRequest[0]) {
                case 200:
                case 204:
                    setTitlePopUp("Success")
                    setMsgPopUp("We are reviewing your Liquidation request.")
                    break;
                case 400:
                case 403:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("We encountered an error saving your data.")
                    break
                case 404:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("We encountered an error saving your data.")
                    break;
                default:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("Please try again, we encountered an error.")
                    break;
            }
        }
    }, [liquidationPriceRequest])

    useEffect(() => {
        if (liquidityPoolCreationRequest.length > 1) {
            setShowPopUp(true)
            switch (liquidityPoolCreationRequest[0]) {
                case 200:
                case 204:
                    setTitlePopUp("Success")
                    setMsgPopUp("We are reviewing your Liquidity Pool request.")
                    break;
                case 400:
                case 403:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("We encountered an error saving your data.")
                    break
                case 404:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("We encountered an error saving your data.")
                    break;
                default:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("We encountered an error saving your data.")
                    break;
            }
        }
    }, [liquidityPoolCreationRequest])

    useEffect(() => {
        if (props.tokenInfo.uuid && props.tokenInfo.uuid !== "0") {
            actions.getTokenStats(props.tokenInfo.uuid, setTokenStats)
            actions.getIssuerLiquidityPools(props.tokenInfo.uuid, setIssuedLiquidityPools)
        }
        actions.getIssuerPrimarySales(setIssuedPrimarySales)

    }, [props.tokenInfo])

    useEffect(() => {
        if (props.tokenInfo.uuid) {
            const tmp = issuedPrimarySales.filter(v => v.token_uuid === props.tokenInfo.uuid)
            setTokenPSs(tmp)
        }
    }, [issuedPrimarySales])

    useEffect(() => {
        let filtered = [...issuedLiquidityPools]
        filtered = filtered.filter((i) => { return i.deleted === undefined || i.deleted === false })
        setFilteredLP(filtered)
        setNextSuffix(issuedLiquidityPools ? issuedLiquidityPools.length : 0)
    }, [issuedLiquidityPools])

    function ColoredTab(props: { label: string, color: string, index: number, headers: any, rows: any }): JSX.Element {
        return <div>
            <>
                {props.rows.length > 0 ?
                    <LightTable headers={props.headers} rows={props.rows}
                        colorHd={"gradient-to-r from-" + props.color + " to-gray3"}
                        color1={"gradient-to-r from-" + props.color + "_lighter  to-gray1"}
                        color2={"gradient-to-r from-" + props.color + "_light to-gray2"} />
                    :
                    <div className="bg-white">
                        <div className={"font-roboto uppercase text-gray pl-10 py-2 bg-" + props.color + "_light"}>None</div>
                    </div>}

            </>
        </div>
    }

    function TableButton(props: { label: string, link: string }): JSX.Element {
        return <>
            <Link className="grid justify-center" to={props.link}><LightButton label={props.label} className="px-2 rounded m-1 font-normal" /></Link>
        </>
    }

    function showLP() {
        return <>
            <LiquidityPoolIssuerTab lps={filteredLP} offset={offset} onClick={() => {
                setShowCreateLPPopUp(true)
            }} />
        </>
    }

    function showTokenControlFunction() {
        return (<>
            <TokenControlFunctionsTab tokenInfo={props.tokenInfo} onClickLiquidation={() => {
                setShowLiquidationPopUp(true)
            }} />
        </>)
    }

    const headers = [...["Issuance Status", "Investor breakdown", "Cap table"],
    ...(isDev ? [...["Market statistics", "Liquidity pool"], ...
        (isLiquidated ? [] : ["Token control functions"]), ...["Performance details"]] :
        [...["Liquidity pool"], ...(isLiquidated ? [] : ["Token control functions"])])]
    return <>
        <DexxPopup show={showLiquidationPopUp} title={"Liquidation"}
            msg={<LiquidationPopup tokensSold={tokensSold} price={parseFloat(props.tokenInfo.price)} uuid={props.tokenInfo.uuid}
                setLiquidationPriceRequest={setLiquidationPriceRequest} closeFunction={() => setShowLiquidationPopUp(false)} />}
            btnConfirm={false} close={() => { setShowLiquidationPopUp(false) }} btnOk={false} />
        <DexxPopup show={showCreateLPPopUp} title={"Create Liquidity Pool"}
            msg={<CreateLPPopup
                closeFunction={function () {
                    setShowCreateLPPopUp(false);
                    actions.getIssuerLiquidityPools(props.tokenInfo.uuid, setIssuedLiquidityPools)
                }}
                setLiquidityPoolCreationRequest={setLiquidityPoolCreationRequest}
                tokenA={props.tokenInfo}
                lpNum={nextSuffix} />}
            helpMsg={
                <div className="p-5 font-roboto font-base text-primary text-lg">
                    <div>
                        For creation of a liquidity pool select the desired initial
                        price of the token and then the XEUR you will allocate.
                    </div>
                    <div>
                        The number of asset tokens required will be calculated for you.
                    </div>
                    <div>
                        For more detailed information
                        <Link to={"/support"} className="text-succ2">click here</Link>.
                    </div>
                </div>
            }
            btnConfirm={false} close={() => { setShowCreateLPPopUp(false) }} btnOk={false} />
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp}></Popup>
        <div className="">
            <TabsMenu
                headers={headers}
                contents={[...
                    [<IssuanceStatusTab tokenInfo={props.tokenInfo} stats={tokenStats} primarySales={tokenPSs} offset={offset} />,
                    <InvestorBreakdown tokenStats={tokenStats} />, <CapTableTab tokenStats={tokenStats} />],
                ...isDev ?
                    [...[
                        <SecondaryMarketStatistics tokenUuid={props.tokenInfo.uuid} />, showLP()], ...
                    (isLiquidated ? [] : [showTokenControlFunction()]), ...[<PerformanceDetailsTab />]] :
                    [...[
                        showLP()], ...(isLiquidated ? [] : [showTokenControlFunction()])]]} />
        </div>
    </>
}