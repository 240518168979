import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { euroFormat, quantityFormat } from "../../utils";
import LabelAndInput from "../LabelAndInput";
import { DexxButton } from "../light/LightButton";
import Popup from "../Popup";
import { PopupLabelAndValue } from "./GenericPopup";
import { DexxNumericInput } from "../utils/Input";

export default function LiquidationPopup(props: {tokensSold: number, price: number, uuid: string, setLiquidationPriceRequest: Function
                            closeFunction: Function}) : JSX.Element {

    const [liquidationPrice, setLiquidationPrice] = useState<number>(0)
    const [totalAmount, setTotalAmount] = useState<number>(0)
    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [showConfirmPopUp, setConfirmShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    const minLiquidationPrice = 0
    const [balance, setBalance] = useState<any>()

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    function handleLiquidation() {
        if(liquidationPrice) {
            let price = parseFloat((liquidationPrice+"").replace(",", "."))
            if(price <= minLiquidationPrice) {
                setShowPopUp(true)
                setTitlePopUp("Sorry!")
                setMsgPopUp("Please increase your liquidation price ("+liquidationPrice+"). "+
                    "The price must be greater than the minimum price allowed ("+minLiquidationPrice+").")
            } else if(balancePT < totalAmount){
                setShowPopUp(true)
                setTitlePopUp("Sorry!")
                setMsgPopUp("Insufficient funds available to complete the liquidation. Please deposit additional funds to your wallet.")
            } else {
                setConfirmShowPopUp(true)
            }  
        }     
    }

    useEffect(() => {
        actions.getIssuerBalance(setBalance)
    }, [])

    useEffect(() => {
        setTotalAmount(liquidationPrice ? parseFloat(liquidationPrice.toString().replace(",", ".")) * props.tokensSold : 0)
    }, [liquidationPrice])

    let balancePT = balance?.payment_tokens?.available_total

    return <>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={() => {setShowPopUp(false)}} />
        <Popup show={showConfirmPopUp} title={"Confirm your action"} 
            msg={<div>Are you sure you would like to liquidate this Token? This cannot be undone.<br/>Price: {liquidationPrice}</div>} btnOk={true} btnConfirm={true} 
            close={() => {setConfirmShowPopUp(false)}} handleConfirm={() => {
                actions.sendLiquidationPrice({token_uuid: props.uuid, 
                    price: liquidationPrice ? parseFloat((liquidationPrice+"").replace(",", ".")) : 0}, 
                    props.setLiquidationPriceRequest); setConfirmShowPopUp(false)}}></Popup>
        <div className="w-128 h-full font-roboto mt-4">
            <div className="pl-16">
                <div className="uppercase text-succ1 text-base font-medium">
                    FIMART euro voucher</div>
                <div className="text-primary text-xl font-urbanist font-normal">
                    {euroFormat(balancePT)+"€"}</div>
            </div>
            <div className="bg-gray_lines h-px w-full my-4"/>
            <div className="text-base mx-7 mb-12">
                <div className="font-normal text-primary">
                    <div className="font-semibold">
                        Asset Liquidation:
                    </div>
                    <div>
                        As an issuer you can execute this Liquidation action to remove an asset from the market. 
                        You define the liquidation price and visualize the total balance you need to liquidate the offer. 
                        All the tokens will be burn and investors will receive in return Fimart Euro Vouchers proportional 
                        to the amount of tokens he/she has.
                    </div>
                </div>
                <div className="font-semibold mx-7 mt-5 grid grid-cols-2 gap-4">
                    <PopupLabelAndValue label={"Tokens sold:"} 
                        value={quantityFormat(props.tokensSold)} />
                    <PopupLabelAndValue label={"Price:"} 
                        value={euroFormat(props.price)+"€"} />
                    <PopupLabelAndValue label={"Liquidation price *:"} value={
                            <DexxNumericInput value={liquidationPrice} fun={setLiquidationPrice} />} />
                    <PopupLabelAndValue label={"Total amount:"} value={
                        euroFormat(totalAmount)+"€"} />
                    
                </div>
            </div>
            <div className="flex justify-between px-6 pb-2">
                <DexxButton onClick={() => {props.closeFunction()}} label={"close"} fontClasses="text-sm font-medium"
                    className="rounded-full px-8 py-3 bg-white drop-shadow-n" color1='white' color2='gray5'/>
                <DexxButton onClick={() => {handleLiquidation()}} label={"confirm"} 
                    fontClasses="text-sm  font-medium" className="rounded-full px-16 bg-white py-3" 
                    color1='white' color2='primary'/>
            </div>
            
        </div>
    </>
}