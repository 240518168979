export interface TableColumn {
    id: string
    header: string,
    content: Function,
    alignment: "left" | "right" | "center",
    sort?: Function
    hide?: boolean
    toCsv?: Function
}

export class TransactionTable {
    columns: TableColumn[];

    constructor(columns: TableColumn[] = []) {
        this.columns = columns
    }

    addColumn(column: TableColumn) {
        this.columns.push(column)
    }

    getHeaders(hiddenColumns: boolean, sort?: boolean): (string|{name: string, sort: Function})[] {
        var headers = []
        for (const c of this.columns) {
            if(!(hiddenColumns && c.hide)){
                if(sort && c.sort)
                    headers.push({name: c.header, sort: c.sort})
                else
                    headers.push(c.header)
            }
        }
        return headers
    }
    
    getValueFromRow(r: any, id: string, type: string, toCsv?: boolean): any {
        const col = this.columns.filter((c) => c.id === id)
        if(col.length > 0){
            if(toCsv && col[0].toCsv)
                return col[0].toCsv(r, type)    
            return {id: col[0].id, value: col[0].content(r, type) }
    }
        return ""
    }

    getRowValues(r: any, type: string, hiddenColumns: boolean, toCsv?: boolean): any[] {
        var ret = []
        for (const c of this.columns) {
            if(!(hiddenColumns && c.hide))
                ret.push(this.getValueFromRow(r, c.id, type, toCsv))
        }
        return ret
    }

    getAllContent(rows: any[], type: string, hiddenColumns: boolean, toCsv?: boolean): any[][] {
        var ret = []
        for (const r of rows) {
            ret.push(this.getRowValues(r, type, hiddenColumns, toCsv))
        }
        return ret
    }

    getAlignments(hiddenColumns: boolean): string[] {
        var ret = []
        for (const c of this.columns) {
            if(!(hiddenColumns && c.hide))
                ret.push(c.alignment)
        }
        return ret
    }
}