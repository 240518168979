import { useEffect, useState } from "react";
import { OptimizedImage, SortedImages, sortImages } from "../../functions/images";
import { actionCreators } from "../../state";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Dialog } from "@mui/material";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";



export function ImagesSlideShow(props: { media: any }): JSX.Element {
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [images, setImages] = useState<OptimizedImage[]>([])
    const [sortedImages, setSortedImages] = useState<OptimizedImage[]>([])
    const [unsortedImages, setUnsortedImages] = useState<OptimizedImage[]>([])
    const [counter, setCounter] = useState<number>(0)
    const [showImage, setShowImage] = useState<boolean>(false)

    function incrementCounter() {
        counter >= images.length - 1 ? setCounter(0) : setCounter(counter + 1)
    }
    function decrementCounter() {
        counter <= 0 ? setCounter(images.length - 1) : setCounter(counter - 1)
    }

    useEffect(() => {
        if (props.media) {
            let imgs: SortedImages = sortImages(
                props.media.filter((m: any) => m.media_type_name === "Image"))
            actions.getAllImagesURL(imgs.sorted_images, setSortedImages)
            actions.getAllImagesURL(imgs.others.map((i) => {
                return { filename_small: "", filename_big: i, name: i, url_big: "", url_small: "" }
            }), setUnsortedImages)
        }
    }, [props.media])

    useEffect(() => {
        if (sortedImages.length > 0 || unsortedImages.length > 0) {
            setImages([...sortedImages, ...unsortedImages])
        }
    }, [sortedImages, unsortedImages])

    return <>
        <div className={" h-111 place-content-center grid items-center max-w-2xl px-2 " +
            (images.length > 1 ? " content-end" : "")}>
            {(images.length > 0) ? <>
                <LazyLoadImage src={images[counter].url_big}
                    className="max-h-96 text-tertiary text-sm font-roboto max-w-full object-cover"
                    placeholderSrc={images[counter].url_small} effect="blur"
                    onClick={() => setShowImage(true)} />
                <Dialog
                    sx={{ height: "100%" }}
                    maxWidth="md"
                    open={showImage}
                    onClose={() => setShowImage(false)}>
                    <TransformWrapper>
                        <TransformComponent>
                            <img src={images[counter].url_big} alt={""}
                                className=" w-full h-full xl:min-h-50 text-primary tex-sm font-roboto"
                                loading="eager" decoding="async" />
                        </TransformComponent>
                    </TransformWrapper>
                </Dialog>
            </> : <></>}
            {images.length > 1 ? <>
                <div className=" h-12 flex place-content-center items-center bg-white_lightest rounded-lg shadow-xl shadow-black/50">

                    <button onClick={(() => { decrementCounter() })}
                        className={"text-white bg-transparent border-0 text-3xl active:animate-ping " +
                            "cursor-pointer transition ease-in-out shadow-xl shadow-black/50"}>{"<"}</button>
                    <div className="flex">
                        {images.map((_, i) => <div className={" rounded-xl m-1 h-5 w-5  border-3 border-solid cursor-pointer " +
                            " shadow-xl shadow-black/50 " + (counter === i ? " bg-white_light border-white" :
                                " border-white bg-white_lighter")}
                            key={i} onClick={() => { setCounter(i) }}></div>)}
                    </div>
                    <button onClick={(() => { incrementCounter() })}
                        className={"text-white bg-transparent border-0 text-3xl active:animate-ping content-center " +
                            "cursor-pointer shadow-xl shadow-black/50 "}>{">"}</button>
                </div></> : <></>}
        </div>
    </>
}