import PieAndList from "../PieAndList";
import GenericTab from "../tokenBody/utils/GenericTab";
import TabInnerTitle from "../tokenBody/utils/TabInnerTitle";

export default function InvestorBreakdown({ tokenStats }: { tokenStats: any }): JSX.Element {
    return <>
        <GenericTab>
            <div>
                <TabInnerTitle title="Investors from Primary Sales" />
                <PieAndList tokenStats={(tokenStats && tokenStats.investor_breakdown) ?
                    tokenStats.investor_breakdown : { rows: [] }} />
            </div>
        </GenericTab>
    </>
}