import React, { useContext, useEffect, useState} from "react"
import { UserContext } from '../UserContext'
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux";
import { defaultAsset, Asset, AssetCreation } from "../AssetContext";
import { actionCreators, State } from "../state";
import { useHistory  } from 'react-router-dom'
import LabelAndInput from "../components/LabelAndInput"
import { BackButton, DexxButton, LightButton } from "../components/light/LightButton";
import Popup from '../components/Popup'
import {formValidationClearErrors, formValidationCheckObject} from '../utils'
import { PageDescription, TitleColor1 } from "../components/typography/Typogtaphy";

export default function IssueAssetRoute(props: any): JSX.Element {

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const createAsset = useSelector((state: State) => state.createAsset)
    const [asset, setAsset] = useState<Asset>(defaultAsset)

    const [assetTypesByCat, setAssetTypesByCat] = useState<any>([])

    const [assetCurrencies, setAssetCurrencies] = useState<any>([])

    // Asset Fields:
    const[assetUuid, setAssetUuid] = useState(/*props.match.params.uuid ? parseInt(props.match.params.uuid) : */"0")
    if(assetUuid === "0" && props.match.params.uuid)
        setAssetUuid(props.match.params.uuid)
    /*else
        setAssetUuid(-1)*/

    const[assetName, setAssetName] = useState<string>("")
    const[issuerName, setIssuerName] = useState<string>("")
    const[assetCategoryName, setAssetCategoryName] = useState<string>("")
    const[assetTypeName, setAssetTypeName] = useState<string>("")
    const[currencyCode, setCurrencyCode] = useState<string>("")
    const[assetExtraInfo, setAssetExtraInfo] = useState<any>({})

    const[saved, setSaved] = useState(false)

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    function handleClosePopUp(){
        setTitlePopUp("")
        setMsgPopUp("")
        setShowPopUp(false)
    }

    const userCtx = useContext(UserContext)
    let history = useHistory<{category: string}>()

    useEffect(() => {
        if(userCtx && userCtx.name) setIssuerName(userCtx.name)
    }, [userCtx])

    var newAsset: AssetCreation = {
        //uuid: uuid,
        //account_issuer_uuid: assetName,
        asset_category: assetCategoryName,
        name: assetName,
        asset_type: assetTypeName,
        currency_cd: currencyCode,
        issuer_name: issuerName,
        extra_info: assetExtraInfo,
    }

    function handleExtraInfoRedirect() {
        var extraInfoTemplate = "/issuer/dashboard"
        if(assetUuid !== "0") {
            extraInfoTemplate = "/issuer/extra-info/"+assetUuid
        }
        history.push(extraInfoTemplate)
    }

    useEffect(() => {
        if(history.location && history.location.state && history.location.state.category) {
            setAssetCategoryName(history.location.state.category)
            actions.getAssetTypesByCategory(history.location.state.category, setAssetTypesByCat)
        }
    }, [history])

    useEffect(() => {
        if(assetUuid && assetUuid !== "0") actions.getAssetInfo(assetUuid, setAsset)
        actions.getAssetCurrencies(setAssetCurrencies)
    }, [])

    useEffect(() => {
        if(saved && createAsset.payload){
            switch(createAsset.payload[0]) {
                case 200:
                    if(createAsset.payload[1].error){
                        setTitlePopUp("Sorry")
                        //setMsgPopUp(createAsset.payload[1].error)
                        setMsgPopUp("We encountered an error saving your data.")
                        setShowPopUp(true)
                    } else {
                        setAssetUuid(createAsset.payload[1].asset_uuid)
                        setTitlePopUp("Success")
                        setMsgPopUp("Your data has been saved.")
                        setShowPopUp(true)
                    }
                    setSaved(false)
                    break
                case 400:
                    if(createAsset.payload[1].error){
                        setTitlePopUp("Sorry!")
                        //setMsgPopUp(createAsset.payload[1].error)
                        setMsgPopUp("We encountered an error saving your data.")
                        setShowPopUp(true)
                    } else {
                        setTitlePopUp("Sorry!")
                        setMsgPopUp("We encountered an error saving your data.")
                        setShowPopUp(true)
                    }
                    setSaved(false)
                    break
                case 403:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("We encountered an error saving your data.")
                    setShowPopUp(true)
                    setSaved(false)
                    break
                case 500:
                    setMsgPopUp("Sorry")
                    setMsgPopUp("We encountered an error saving your data.")
                    setShowPopUp(true)
                    setSaved(false)
                    break
                default:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("We encountered an error saving your data.")
                    setShowPopUp(true)
                    setSaved(false)
            }
            if(createAsset.payload[1].asset_uuid) actions.getAssetInfo(createAsset.payload[1].asset_uuid, setAsset)
        }

    }, [createAsset.payload])

    useEffect(() => {
        if(asset && assetUuid !== "0") {
            let info: Asset = asset
            setAssetName(info.name)
            setIssuerName(info.issuer_name)
            setAssetCategoryName(info.asset_category)
            setAssetTypeName(info.asset_type)
            setCurrencyCode(info.currency_cd)
            setAssetExtraInfo(info.extra_info)
            actions.getAssetTypesByCategory(info.asset_category, setAssetTypesByCat)
        }
    }, [asset, assetUuid])

    function handleSaveAsset(e: React.MouseEvent) {
        formValidationClearErrors()
        const checkAsset = {
            asset_name: assetName,
            issuer_name: issuerName,
            currency_code: currencyCode,
            asset_type_name: assetTypeName
        }
        let count = formValidationCheckObject(checkAsset)
        if(count === 0){
            let u = newAsset
            if(assetUuid !== "0") u.uuid = assetUuid
            setSaved(true)
            actions.createAsset(newAsset)
        } else {
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please complete the highlighted fields.")
            setShowPopUp(true)
        }
    }

    return (<>
            <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp} ></Popup>
            <div className="grid lg:grid-cols-4">
                <div className="grid justify-items-center xl:mt-40 md:mt-20 mt-10 col-span-1">
                    <PageDescription title={<div>{assetCategoryName+" Asset"}<br></br>Creation</div>} 
                        description={""} />
                </div>

                <div className="md:grid md:px-20 px-5 md:grid-cols-2 mt-10 lg:col-span-2" >
                    <div className="col-span-2 text-3xl mb-4 font-light"><TitleColor1>/ Asset Info</TitleColor1></div>

                    <div className={" font-roboto tracking-wider px-1 md:mr-10"}>
                        <label className="w-full text-tertiary text-base py-4">Class</label>
                        <input className="w-full py-4 bg-transparent border-t-0 border-l-0 border-r-0
                             border-solid border-tertiary border-b-1 leading-none
                            text-tertiary text-base font-urbanist pl-2 " 
                            value={assetCategoryName} disabled />
                    </div>
                    
                    <LabelAndInput required id={"assetTypeName"} label={"Type *"} 
                        value={assetTypeName} fun={(e: string) => {setAssetTypeName(e)}} 
                        type={"select"} className="md:mr-10"
                        options={assetTypesByCat ?
                            [{value: "", text: ""}].concat(assetTypesByCat.map((type: any) => {
                                return {value: type, text: type}})):
                            [{value: "", text: ""}]}/>
                    
                    <LabelAndInput required id={"assetName"} label={"Asset Name *"} value={assetName} 
                        fun={setAssetName} type={"text"} className={"col-span-2 md:mr-10"}/>
                    <LabelAndInput required id={"issuerName"} label={"Issuer Name *"} value={issuerName} 
                        fun={setIssuerName} type={"text"} className={"md:mr-10"} />
                    <LabelAndInput required id={"currencyCode"} label="FIMART Euro Voucher *" 
                        value={currencyCode} fun={(e: string) => setCurrencyCode(e)} 
                        type={"select"} className="md:mr-10"
                        options={assetCurrencies ?
                            [{value: "", text: ""}].concat(assetCurrencies.map((currency: any) => {return {value: currency, text: currency}})) :
                            [{value: "", text: ""}]}/>
                    <div className="col-span-2">
                        <div className="flex w-full mb-3 mt-6 md:my-6 gap-5 place-content-center ">
                            <BackButton className="px-5 rounded-full"/>
                            <DexxButton label={"Add Extra info"} onClick={handleExtraInfoRedirect}
                                className="rounded-full px-5 hidden md:block" color2="warning"/>
                            <DexxButton label={"save"} className="rounded-full px-5"
                                color2="succ2" onClick={handleSaveAsset}/>
                            {/*assetUuid !== "0" ? */ /*: <></>*/
                            }
                        </div>
                        <div className="flex w-full place-content-center md:my-6 md:hidden">
                            <DexxButton label={"Add Extra info"} onClick={handleExtraInfoRedirect}
                                className="rounded-full px-5" color2="warning"/>
                        </div>
                    </div>
                </div>
                
            </div>
    </>)
}
