import { Cell, SortingMethod, SortStatus } from "./TransactionTypes"

export default class TransactionContent {
    rows: Cell[][]
    length: number
    sortingMethods: { id: string, sort: SortingMethod }[]

    constructor(rows: Cell[][] = [], sortingMethods?: { id: string, sort: SortingMethod }[]) {
        this.rows = rows
        this.length = rows.length
        this.sortingMethods = sortingMethods ? sortingMethods : []
    }

    addSortingMethods(sortingMethods: { id: string, sort: SortingMethod }[]) {
        this.sortingMethods = sortingMethods
    }

    getValue(id: string, row: Cell[]) {
        const filtered = row.filter((c: Cell) => c.id === id)
        if (filtered.length > 0)
            return filtered[0].value
        return ""
    }

    getSortMethod(id: string) {
        const el = this.sortingMethods.filter((s) => s.id === id)[0]
        return el.sort
    }

    getIndex(id: string): number {
        try {
            const find = this.rows[0].map((c: Cell, index: number) => 
                { if (c.id === id) return index; else return -1 })
            const filtered = find.filter((n: number) => n !== -1)
            if(filtered.length > 0)
                return filtered[0]
            return -1
        } catch (_) {
            return -1
        }
    }

    filterBy(id: string, filter: string, rows: any[][]): any[][] {
        const r = rows
        if(filter === "all")
            return r
        const index = this.getIndex(id)
        if(index >= 0) {
            r.forEach((c: any[]) => {if(c[index] === "") console.log(c)})
            var filtered = r.filter((c: any[]) => c[index].toLowerCase()
                .includes(filter.toLowerCase()))
            if(id === "transType" && (filter === "sell" || "buy"))
                filtered = filtered.filter((c: any[]) => !["buyer", "seller"]
                    .some(w => c[index].toLowerCase().includes(w)))
            return filtered
        }
        return []
    }

    getRows(sortColumn: { id: string, mode: SortStatus }, filters: { id: string, value: string }[],
        rows?: Cell[][]): any[][] {
        const r = rows ? rows : this.rows
        const values = r.map((r: Cell[]) => r.map((c: Cell) => c.value))
        var result = values.sort(this.getSortMethod(sortColumn.id))
        if (sortColumn.mode === "reverse")
            result = result.reverse()
        for (const f of filters) {
            result = this.filterBy(f.id, f.value, result)
        }
        return result
    }
}