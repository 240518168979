import { useEffect, useState } from "react";
import TabsMenu from "../TabsMenu";
import { DataRoomTab } from "./tabs/DataRoomTab";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import SecondaryMarketStatistics from "./tabs/SecondaryMarketStatistics";
import Cookies from 'universal-cookie';
import OverviewTab from "./tabs/OverviewTab";
import { AssetCategory, defaultPrimarySaleGet } from "../../PoolContext";
import { TokenOverviewTab } from "./tabs/TokenOverviewTab";
import InvestmentLimitsTab from "./tabs/InvestmentLimitsTab";
import LoggedOutTab from "./tabs/LoggedOutTab";
import P2PTokenTab from "./tabs/P2PTokenTab";
import LiquidityPoolTab from "./tabs/LiquidityPoolTab";
import AssetDetailsTab from "./tabs/AssetDetailsTab";

const cookies = new Cookies();

export default function TokenBody(props: {
    origin: string; lpCount: number; lpInfo?: any; reload?: Function; isLogged: boolean,
    media: any, tokenInfo: any, assetInfo: any, openedTab?: any, completeView?: any}): JSX.Element {
        
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [documents, setDocuments] = useState<{ url: string, filename: string }[]>([])
    const [primarySaleDetails, setPrimarySaleDetails] = useState(defaultPrimarySaleGet)
    const [p2p, setP2p] = useState<any[]>([])
    const [refreshP2pList, setRefreshP2pList] = useState<boolean>(false)
    const [onlyActiveP2p, setOnlyActiveP2p] = useState<any[]>([])
    const isDev = cookies.get("env") === "develop"
    const category: AssetCategory = props.assetInfo.asset_category
    const type: string = props.assetInfo.asset_type

    var headers: string[] = []
    var labels: string[] = []
    var contents: any[] = []
    var assetInfo = {}
    var infoArray = []

    interface Tab {
        header: string,
        content: JSX.Element,
        label: string,
        hideFor: AssetCategory[],
        hide?: boolean,
    }

    switch (category) {
        case "ART":
            infoArray = (type === "ART Lending") ?
                props.assetInfo.extra_info.art_lending.sub_assets :
                props.assetInfo.extra_info.art_investing.sub_assets
            break
        case "AIF":
            infoArray = (type === "Private Debt") ?
                props.assetInfo.extra_info.private_debt.sub_assets :
                props.assetInfo.extra_info.private_equity.sub_assets
            break
        case "COLLECTABLES":
            infoArray = props.assetInfo.extra_info.cars.sub_assets
            break
        case "SPORTEX":
            const extra = props.assetInfo.extra_info ? props.assetInfo.extra_info : {}
            infoArray = extra.equestrian.sub_assets
            break
    }

    assetInfo = infoArray.length > 0 ? infoArray[0] : {}

    const tabManager: Tab[] = [
        {
            header: "Asset Overview",
            content: <OverviewTab info={assetInfo} assetInfo={props.assetInfo}
                documents={documents} media={props.media} isLogged={props.isLogged} />,
            label: "",
            hideFor: []
        },
        {
            header: "Asset Details",
            content: <AssetDetailsTab asset={assetInfo} />,
            label: "details",
            hideFor: ["ART", "COLLECTABLES", "SPORTEX"]
        },
        {
            header: "Token Overview",
            content: <TokenOverviewTab tokenInfo={props.tokenInfo} info={assetInfo}
                primarySaleDetails={primarySaleDetails} category={category} />,
            label: "token",
            hideFor: []
        },
        {
            header: "Investment Restrictions",
            content: <InvestmentLimitsTab tokenInfo={props.tokenInfo}
                price={primarySaleDetails.price} />,
            label: "restr",
            hideFor: [],
        },
        {
            header: "Market Statistics",
            content: <SecondaryMarketStatistics tokenUuid={props.tokenInfo.uuid} />,
            label: "stats",
            hideFor: [],
            hide: !isDev
        },
        {
            header: "Liquidity Pool",
            content: props.isLogged ? 
                <LiquidityPoolTab reload={props.reload} lpInfo={props.lpInfo} lpCount={props.lpCount}
                    origin={props.origin} tokenInfo={props.tokenInfo} isLogged={props.isLogged} /> : 
                <LoggedOutTab />,
            label: "lp",
            hideFor: [],
            hide: !props.completeView
        },
        {
            header: "P2P",
            content: (props.isLogged ?
                <P2PTokenTab p2ps={onlyActiveP2p} tokenInfo={props.tokenInfo} refreshList={setRefreshP2pList} /> :
                <LoggedOutTab />),
            label: "p2p",
            hideFor: [],
            hide: !props.completeView
        },
        {
            header: "Data Room",
            content: <DataRoomTab documents={documents} />,
            label: "docs",
            hideFor: []
        }
    ]

    tabManager.forEach((tab: Tab) => {
        if (props.tokenInfo && (!(tab.hideFor.includes(props.tokenInfo.asset_class) || tab.hide))) {
            headers.push(tab.header)
            labels.push(tab.label)
            contents.push(tab.content)
        }
    });

    useEffect(() => {
        if (props.media) {
            if (props.isLogged) actions.getDocuments(props.media, setDocuments)
        }
    }, [props.media])

    useEffect(() => {
        if (props.tokenInfo && props.tokenInfo.uuid)
            actions.getPrimarySale("token_uuid=" + props.tokenInfo.uuid, setPrimarySaleDetails)
    }, [props.tokenInfo])

    useEffect(() => {
        if (props.isLogged) {
            if (props.tokenInfo && props.tokenInfo.uuid)
                actions.getP2P({
                    token_a_uuid: props.tokenInfo.uuid
                }, setP2p)
            setRefreshP2pList(false)
        }
    }, [props.tokenInfo, refreshP2pList])

    useEffect(() => {
        if (p2p) {
            let tmp: any = p2p.filter((o: any) => o.deleted === undefined || o.deleted === false)
            setOnlyActiveP2p(tmp)
        }
    }, [p2p])

    return <>
        <div className="">
            <TabsMenu headers={headers} labels={labels} contents={contents}
                openedTab={props.openedTab} />
        </div>
    </>
}