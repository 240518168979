import fileImg from '../../../media/file.png'
import CentralTabContent from '../utils/CentralTabContent'
import GenericTab from '../utils/GenericTab'

function getNameFromUrl(url: string) {
    var noVars = url.split('?')[0]
    var name = noVars.substring(noVars.lastIndexOf('/') + 1)
    name = name.replace("coownership___", "COOWNERSHIP AGREEMENT: ")
    name = name.replace("prospectus___", "ASSET TOKEN PROSPECTUS: ")
    name = name.replace("_", " ").replace("-", " ").split('.')[0]
    return name
}

export function DataRoomTab(props: { documents: any[] }): JSX.Element {
    var tmp: any[] = []
    try {
        tmp = props.documents.map((doc: any, i: number) =>
            <div className="pb-5" key={(i + 1).toString()}>
                <a href={doc.url} target={"_blank"} >
                    <div className="flex items-center bg-white_lightest p-2 rounded-md shadow-xl shadow-black/50">
                        <span className="inline"><img src={fileImg} alt={doc.filename} className="h-10"></img></span>
                        <span className="text-tertiary tracking-wider uppercase hover:text-succ2 
                            font-base text-sm md:text-lg px-4 inline">
                            {getNameFromUrl(doc.filename)}
                        </span>
                    </div></a>
            </div>)
    } catch (e) { }
    const res = <GenericTab className=" md:px-40">
        {tmp.length > 0 ?
            tmp :
            <CentralTabContent className='text-white my-20'>No documents provided</CentralTabContent>}
    </GenericTab>
    return res

}