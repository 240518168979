import { useState } from "react";

export default function DropDownFaq(props: { title: string, children: any }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);

    return <div className="">
        <div className={"flex justify-between items-center cursor-pointer font-normal gap-2 " +
            "mt-2 p-4 hover:bg-white_light hover:text-primary " +
            (isOpen ? " bg-white_light text-primary rounded-t " : "bg-white_lightest rounded ")}
            onMouseEnter={() => setIsActive(true)}
            onMouseLeave={() => setIsActive(false)}
            onClick={() => setIsOpen(!isOpen)}>
            <div>{props.title}</div>
            <div>
                <div className={"transform h-4 w-4 ease-in-out border-solid border-0 transition-rotation delay-100 border-b-2 border-r-2 " +
                    (isOpen ?
                        " mt-2 border-primary rotate-225 " :
                        (" rotate-45 " + (isActive ? "border-primary" : "border-white_light")))
                } />
            </div>
        </div>
        {isOpen && <div className=" border-solid border-1 border-white_light bg-white_lightest p-4">
            {props.children}</div>}
    </div>
}