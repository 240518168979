export interface Cell {
    id: string,
    value: any
}

export type SortingMethod = (a: Cell[], b: Cell[]) => number
export type SortStatus = "normal" | "reverse"

export function nextSortStatus(current: SortStatus): SortStatus {
    const all: SortStatus[] = ["normal", "reverse"]
    const index = all.indexOf(current)
    const next = index === all.length - 1 ? 0 : index + 1
    return all[next]
}
