import TextOnlyPage from "../components/pageBuilders/TextOnlyPage";
import { FaqQuestion, FaqAnswer, BigTitle, PageTitle } from "../components/typography/Typogtaphy";

export default function DisclaimerRoute(): JSX.Element {
    return <>
        <TextOnlyPage>
            <PageTitle>Disclaimer</PageTitle>
            <FaqQuestion content="REVERSE SOLICITATION" />
            <div>
                DEXX S.A. (“<b>DEXX</b>” or the “<b>Company</b>”) is licensed by the Self Regulated
                Body SoFIT to operate as a financial intermediary. The company is not subject to FINMA
                supervision and can accept assets only for a limited amount (maximum CHF 1 Mio or
                maximum for 60 days). No interests are paid and the assets within DEXX SA are not
                protected by the Swiss deposit guarantee in case of bankruptcy.
            </div>
            <div>
                The Company does not actively offer its products or services in any country of the
                European Economic Area (“<b>EEA</b>”). It does not actively offer tokens qualifying
                as financial instruments in accordance with Directive 2014/65/EU (“<b>MiFID2</b>”)
                and is not authorized to operate in accordance with the MiFID2 framework. DEXX is not
                licensed or registered to operate as virtual asset service provider (VASP) pursuant to
                the national laws of EEA Member States, and does not hold any other license or
                registration in accordance with the laws of any such EEA jurisdiction.
            </div>
            <div>
                The Company does not perform processing activities that are related to the offer 
                any goods or services to data subjects in the European Union, or the monitoring of 
                their behaviour as far as their behaviour takes place within the European Union. The 
                personal data of the users will be processed in accordance with the Swiss Federal 
                Data Protection Act.
            </div>
            <div>
                Users that are resident in the EEA may become customers of DEXX on a reverse enquiry 
                basis, in the absence of any promotional, marketing or offering activities carried out 
                directly or indirectly by DEXX in the EEA.
            </div>
            <FaqQuestion content="FIMART Euro Voucher" />
            <div>
                FIMART Euro Voucher can only be used to purchase the tokens marketed and traded on 
                the platform.
            </div>
        </TextOnlyPage>
    </>
}