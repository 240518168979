import { euroFormat } from "../../../utils";
import { LabelAndInlineValue } from "../../LabelAndDescription";
import HTMLContent from "../utils/HTMLContent";
import GenericTab from "../utils/GenericTab";

export default function InvestmentLimitsTab(props: { tokenInfo: any, price: any }): JSX.Element {
    return <>
        <GenericTab>
            <div className="">
                <LabelAndInlineValue label={"MINIMUM INVESTMENT: "}
                    value={"EUR " + euroFormat(props.price) + " (1 Token)"} />
                <HTMLContent alternate={true} label={""}
                    description={props.tokenInfo.investment_limits}
                    className="mt-5 text-xl font-roboto text-tertiary" />
            </div>
        </GenericTab>
    </>
}