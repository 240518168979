import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { actionCreators } from "../../state"
import { euroQtyFormat, euroFormat, quantityFormat } from "../../utils"
import { DexxButton, DexxMaxButton } from "../light/LightButton"
import Popup from "../Popup"
import { DexxNumericInput, DexxSelectInput } from "../utils/Input"
import { LiquidityPool } from "../../PoolContext";

export default function CreateLPPopup(props: {closeFunction: Function, setLiquidityPoolCreationRequest:Function, tokenA: any, lpNum: number}) : JSX.Element {

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const [lpName, setLpName] = useState<string>("")
    const [issuedTokens, setIssuedTokens] = useState<any>()
    const [balance, setBalance] = useState<any>()

    const tokenAticker = props.tokenA.ticker
    const [tokenB, setTokenB] = useState<string>('XEUR')
    const [amountTokenA, setAmountTokenA] = useState<number>(0.00)
    const [amountTokenB, setAmountTokenB] = useState<number>(0)
    const [calcAmountTokenA, setCalcAmountTokenA] = useState<number>(0)
    const [calcAmountTokenB, setCalcAmountTokenB] = useState<number>(0.00)
    const [spotPrice, setSpotPrice] = useState<number>(0)
    const [maxTokenA, setMaxTokenA] = useState<number>(0)
    const [maxTokenB, setMaxTokenB] = useState<number>(0)
    const [lpShares, setLpShares] = useState<number>(0)
    const [liquidityPoolList, setLiquidityPoolList] = useState<LiquidityPool[]>([])

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")

    let issuedTokensList = [
        (issuedTokens?.listed_tokens) ? 
            issuedTokens.listed_tokens : []]

    useEffect(() => {
        actions.getIssuedTokens(setIssuedTokens)
        //actions.getIssuerBalance(setBalance)
        actions.getActualBalance(setBalance)
        actions.getAllLiquidityPoolRequests(setLiquidityPoolList, {})
    }, [])

    useEffect(()=> {
        if(props.lpNum === 0) setLpName("LP_"+tokenAticker+"_"+tokenB)
        else setLpName("LP_"+tokenAticker+"_"+tokenB+"_"+props.lpNum)
    }, [/*tokenA, issuedTokensList, liquidityPoolList*/props.lpNum])

    useEffect(() => {
        if(balance && balance.payment_tokens) {
            setMaxTokenB(balance.payment_tokens.available_total)
        }
        if(balance && balance.balance.security_entries) {
            let tokensFromClass = balance.balance.security_entries.filter((c:any) => { return c.class === props.tokenA.asset_class })
            if(tokensFromClass[0] && tokensFromClass[0].class_balance){
                let actualToken = tokensFromClass[0].class_balance.filter((t:any) => { return t.token_uuid === props.tokenA.uuid })
                if(actualToken[0] && actualToken[0]?.available_quantity)
                    setMaxTokenA(actualToken[0]?.available_quantity)
            }
        }
    }, [balance, props.tokenA])

    useEffect(() => {
        if(amountTokenB !== 0 && spotPrice !== 0) {
            let sp = parseFloat(spotPrice.toString().replace(",", "."))
            let amountB = parseFloat(amountTokenB.toString().replace(",", "."))
            // if desired_euro_amount > euro_available ==> desired_euro_amount == euro_available (update field in popup)
            if(amountB > maxTokenB) setAmountTokenB(maxTokenB)

            // desired_tokens_amount == ROUNDDOWN( desired_euro_amount / desired_price ; 0 decimals ) //internal, not shown
            let fixedAmountA = sp > 0 ? parseInt(Math.floor(amountB / sp).toFixed(0)) : 0
            
            // if desired_tokens_amount > tokens_available ==> tokens_amount == tokens_available
            let amount = fixedAmountA < maxTokenA ? fixedAmountA : maxTokenA
            setAmountTokenA(amount)

            setCalcAmountTokenA((amount))

            // euro_amount == ROUND( tokens_amount * desired_price ; 2 decimals) //it's the same for both cases
            setCalcAmountTokenB(Math.floor((amount * sp * 100))/100)
            
            //setAmountTokenB(Math.round((amount * spotPrice * 100))/100)
        } else if(amountTokenB === 0 || spotPrice === 0){
                setCalcAmountTokenA((0))
                setCalcAmountTokenB(0)
        }
    }, [maxTokenA, amountTokenB, spotPrice, maxTokenB])

    function handleMax(){
        if(spotPrice !== 0){
            let sp = parseFloat(spotPrice.toString().replace(",", "."))
            //When MAX is pressed, the calculation needs to take the min of both ways:

            // desired_euro_amount = ROUNDUP( tokens_available * desired_price ; 2 decimals )
            let fixedAmountB = Math.round(maxTokenA * sp * 100) / 100

            // if desired_euro_amount > euro_available ==> desired_euro_amount == euro_available
            if(fixedAmountB > maxTokenB) fixedAmountB = maxTokenB

            // update field desired_euro_amount
            setAmountTokenB(fixedAmountB)

            // tokens_amount == ROUNDDOWN( desired_euro_amount / desired_price ; 0 decimals )
            let fixedAmountA = Math.floor(parseInt((fixedAmountB / sp).toFixed(0)) * 100) / 100
            
            // update fields in box EFFECTIVE...
            setCalcAmountTokenA((fixedAmountA))
            setCalcAmountTokenB((Math.round((fixedAmountA * sp * 100))/100))
        } else {
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please specify the spot price.")
            setShowPopUp(true)
        }
    }
    
    useEffect(() => {
        if(calcAmountTokenA !== 0 && calcAmountTokenB !== 0) {
            setLpShares(Math.floor((calcAmountTokenA) * (calcAmountTokenB)))
        }
    }, [amountTokenA, amountTokenB, spotPrice])
    
    function handleSubmit() {
        if(calcAmountTokenA > 0 && calcAmountTokenB > 0 && lpName.length > 0 && 
            tokenAticker.length > 0 && tokenB.length > 0){

            if(calcAmountTokenB > maxTokenB){
                setTitlePopUp("Sorry!")
                setMsgPopUp("Insufficient funds available to complete transaction. Please deposit additional funds to your wallet.")
                setShowPopUp(true)

            } else {
                let ta = issuedTokensList.length > 0 ?
                    issuedTokensList[0].filter((t: any) => t.ticker === tokenAticker) : {uuid: ""}
                let request = {
                    lp_name: lpName,
                    token_a_uuid: ta[0].uuid,
                    amount_a: calcAmountTokenA,
                    token_b_uuid: "",
                    amount_b: calcAmountTokenB.toString().replace(",", ".")
                }
                actions.createLiquidityPool(request, props.setLiquidityPoolCreationRequest)
            }

        } else {
            setTitlePopUp("Sorry!")
            setMsgPopUp("You missed one or more required fields.")
            setShowPopUp(true)
        }
    }

    function labelAndValue(label: string, value: any, colorVal?: string, colorLabel?:string) {
        
        let cV = "gray-5"
        let cL = "primary"
        if(colorVal) cV = colorVal
        if(colorLabel) cL = colorLabel
        return <>
            <div className={"text-" + cL + " font-semibold text-sm"}>
                {label}
                <div className={"font-normal text-sm text-" + cV}>{value}</div>
            </div>
        </>
    }

    return <>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} 
            close={() => {setShowPopUp(false)}} />
        
        <div className="w-128 h-full font-roboto mt-2">
            <div className="pl-10 grid grid-cols-3 mb-2">
                <div className="col-span-2">
                    <div className={"text-succ1 text-sm font-medium"}>
                        My Balance</div>
                </div>
            </div>
            <div className="pl-10">
                <div className="grid grid-cols-2">
                    {labelAndValue(tokenAticker+":", quantityFormat(maxTokenA), "blue", "blue")}
                    {labelAndValue(tokenB+":", euroFormat(maxTokenB), "blue", "blue")}
                </div>
            </div>

            <div className="bg-gray_lines h-px w-full my-2"/>

            <div className="pl-10">
                <div className="grid grid-cols-2">
                    {labelAndValue("Asset Name:", props.tokenA.asset_name, "gray_lines", "gray_lines")}
                    {labelAndValue("Ticker:", tokenAticker, "gray_lines", "gray_lines")}
                </div>
            </div>

            <div className="bg-gray_lines h-px w-full mt-2"/>

            <div className={"text-sm pl-10 pt-5 pb-2 bg-gradient-to-r from-succ1 to-transparent"}>
                <div className="grid grid-cols-2 mb-1">
                    
                    <div className="font-semibold text-sm text-blue">Desired Currency Token
                        <DexxSelectInput value={tokenB} fun={setTokenB}
                            items={[/*"",*/"XEUR"]}
                            className="w-28 text-primary text-sm"/>
                    </div>
                    <div className="font-semibold text-sm text-blue">Desired Spot Price *
                        <DexxNumericInput value={spotPrice} className="w-3/4" fun={setSpotPrice}
                            max={maxTokenB}/>
                    </div>

                    <div className=""></div>

                    <div className="font-semibold text-blue mt-4">Desired {tokenB} Amount *
                        <DexxNumericInput value={amountTokenB/* <= calcAmountTokenB ? amountTokenB : calcAmountTokenB*/ } className="text-sm" max={maxTokenB}
                            fun={(v:any) => setAmountTokenB((v ? v : 0))}/>
                        <DexxMaxButton label={"MAX"} className="px-3 rounded-xl bg-greenMax mt-1" 
                            onClick={() => {
                            handleMax()
                        }}/>
                    </div>
                </div>
            </div>

            <div className="bg-gray_lines h-px w-full"/>

            <div className="pl-10 text-sm mt-2">
                <div className="font-semibold">LP Name:</div>
                <div className="mb-2">{lpName}</div>

                <div className="font-semibold mb-2">initial reserves for LP creation:</div>

                <div className="mb-3">
                    <div className="grid grid-cols-2">
                        {labelAndValue(tokenAticker+":", euroQtyFormat(calcAmountTokenA), "blue", "blue")}
                        {labelAndValue(tokenB, euroFormat(calcAmountTokenB), "blue", "blue")}
                    </div>
                </div>

            </div>

            <div className="bg-gray_lines h-px w-full mb-2"/>
            <div className="pl-10 mb-5 font-roboto">
                <div className="text-succ1 text-sm font-medium">
                    Review</div>
                <div className="text-primary text-sm font-urbanist font-normal">
                    Effective {tokenAticker}:
                    <strong>{euroQtyFormat(calcAmountTokenA)}</strong>
                </div>
                <div className="text-primary text-sm font-urbanist font-normal">
                    Effective {tokenB}:
                    <strong>{euroFormat(calcAmountTokenB)}</strong>
                </div>
                <div className="text-primary text-sm font-urbanist font-normal">
                    LP shares to receive:
                    <strong>{euroQtyFormat(lpShares)}</strong>
                </div>
            </div>

        </div>

        <div className="flex justify-between px-6 pb-2">
            <DexxButton onClick={() => {props.closeFunction()}} label={"close"} fontClasses="text-sm font-medium"
                className="rounded-full px-8 py-3 bg-white drop-shadow-n" color1='white' color2='gray5'/>
            <DexxButton onClick={() => {handleSubmit()}} label={"confirm"} 
                fontClasses="text-sm  font-medium" className="rounded-full px-16 bg-white py-3" 
                color1='white' color2='primary'/>
        </div>
    </>
}