import DropDownFaq from "../components/pageBuilders/DropDownFaq";
import TextOnlyPage from "../components/pageBuilders/TextOnlyPage";
import { PageTitle, TitleColor1 } from "../components/typography/Typogtaphy";

export default function IssuerFaqRoute() {
    return <>
        <TextOnlyPage>
            <PageTitle>DEXX Issuer Qualifications / FAQ</PageTitle>
            To become an Issuer of securities on the DEXX platform the Issuer must meet
            a range of criteria to ensure credibility, legal compliance, and financial
            capability.The Sections below provide more information about the specific
            criteria DEXX is looking for as we partner with prospective Issuers.
            <div className="h-4"></div>
            <DropDownFaq title="Proof of Asset Ownership">
                <ul>
                    <li>
                        Legal Title and Proof of Ownership: The Issuer must provide documentation
                        demonstrating clear and legal ownership of the asset (painting or racehorse),
                        such as:
                        <ul>
                            <li>
                                Title deed or certificate of authenticity for a painting.
                            </li>
                            <li>
                                Ownership registration or relevant contracts for a racehorse.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Valuation Report: Independent, professional valuation of the asset to confirm its worth.
                    </li>
                    <li>
                        No Encumbrances: The asset should be free of any liens, claims, or
                        legal disputes that might interfere with its sale or securitization.
                    </li>
                </ul>
            </DropDownFaq>
            <DropDownFaq title={"Market Credibility"}>
                <ul>
                    <li>
                        Track Record: The Issuer must have a proven track record of credible transactions
                        or involvement in the respective market (art or horse racing, for example). This can include:
                        <ul>
                            <li>
                                Established history of purchasing, selling, or managing high-value assets in the market.
                            </li>
                            <li>
                                Recognition from industry experts, curators, or racing professionals.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Reputation: The Issuer must have a good reputation in the relevant community, verified
                        through references, past transactions, or expert endorsements.

                    </li>
                    <li>
                        Professional Affiliation: Membership in relevant industry organizations
                        (e.g., art dealers' associations, racing associations).
                    </li>
                </ul>
            </DropDownFaq>
            <DropDownFaq title="Financial Requirements">
                <ul>
                    <li>
                        Liquid Funds for Legal and Transaction Costs: The Issuer must demonstrate the
                        ability to cover the costs of creating the necessary legal documents and meeting
                        regulatory requirements, such as:
                        <ul>
                            <li>
                                Legal Fees: Funds to cover attorney costs for drafting the prospectus,
                                preparing legal contracts, and ensuring regulatory compliance.

                            </li>
                            <li>
                                Regulatory Fees: Costs associated with registration, filing with the
                                relevant securities authorities, and platform fees.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Sufficient Working Capital: Proof of liquid assets or financial reserves to
                        manage the process and sustain the costs associated with asset management,
                        marketing, platform listing and liquidity pool funding.
                    </li>
                </ul>
            </DropDownFaq>
            <DropDownFaq title="Legal and Regulatory Compliance">
                <ul>
                    <li>
                        Regulatory Approval: The Issuer must ensure the asset complies with local
                        securities laws, including providing required documentation to regulatory
                        bodies (e.g., SEC for U.S. or equivalent in other jurisdictions).
                    </li>
                    <li>
                        Prospectus and Disclosure Requirements: The issuer must work with legal
                        advisors to create a comprehensive and accurate prospectus, including:
                        <ul>
                            <li>
                                Full asset description, valuation, and expected returns.
                            </li>
                            <li>
                                Risks and disclosures related to the asset (e.g., the potential
                                volatility in the art market or racing outcomes for horses).
                            </li>
                        </ul>
                    </li>
                    <li>
                        Anti-money Laundering (AML) and Know Your Customer (KYC) Compliance:
                        The issuer must meet the platform's AML and KYC requirements to prevent
                        fraudulent activity.
                    </li>
                </ul>
            </DropDownFaq>
            <DropDownFaq title="Asset Management and Maintenance">
                <ul>
                    <li>
                        Insurance: Proof of insurance for the asset (e.g., insurance for the
                        painting against theft or damage, or for the racehorse covering health,
                        injury, or other risks).
                    </li>
                    <li>
                        Proper Care and Preservation Plans: For art, this includes climate-controlled
                        storage, regular maintenance, and care plans. For racehorses, this would
                        involve a documented plan for their training, health care, and management.
                    </li>
                    <li>
                        Monitoring and Reporting: Commitment to regular updates on the asset's status
                        for the investors (e.g., periodic valuations, racehorse performance updates,
                        or any changes in the painting's condition).
                    </li>
                </ul>
            </DropDownFaq>
            <DropDownFaq title="Conflict of Interest and Transparency">
                <ul>
                    <li>
                        Conflict of Interest Disclosures: Any potential conflicts of interest must
                        be disclosed to the platform and investors. This includes personal relationships
                        with appraisers, dealers, or industry professionals.
                    </li>
                    <li>
                        Full Transparency: The issuer must be fully transparent regarding the asset's
                        history, condition, market trends, and any issues that could impact its value.
                    </li>
                </ul>
            </DropDownFaq>
            <DropDownFaq title="Ongoing Monitoring and Reporting Obligations">
                <ul>
                    <li>
                        Periodic Reports: The issuer must commit to regular reporting on the asset's
                        performance or condition, whether it's through independent assessments, auction
                        results, race results, or other verifiable data.
                    </li>
                    <li>
                        Dividend/Revenue Distribution Plan: If the asset generates revenue (e.g., race
                        winnings), the Issuer must outline how profit calculations will be calculated
                        and generate the required reporting for shareholders.
                    </li>
                </ul>
            </DropDownFaq>
            <div className="h-4"/>
            By meeting these criteria, the Issuer demonstrates their commitment to transparency, legal compliance, 
            and financial stability, which helps build trust with investors and ensures the platform operates 
            smoothly and legally.<br/>
            If you feel you meet the above criteria please send us an e-mail providing some 
            information to contact you to discuss the DEXX ISSUER opportunity at:
            <a href="mailto:info@dexx.finance">
                <TitleColor1 className="font-normal text-center mt-4">info@dexx.finance</TitleColor1>
            </a>
        </TextOnlyPage>
    </>
}