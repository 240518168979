import { AssetCategory } from "../../../PoolContext";
import { convertLegalFrameworkForFE, convertToSimpleDate, euroFormat, quantityFormat } from "../../../utils";
import LabelAndDescription from "../../LabelAndDescription";
import HTMLContent from "../utils/HTMLContent";
import GenericTab from "../utils/GenericTab";
import { InlineContent } from "../issuerTabs/TokenControlFunctionsTab";
import { Link } from "react-router-dom";

export function TokenOverviewTab(props: {
    category: AssetCategory, tokenInfo: any,
    primarySaleDetails: any, info: any
}): JSX.Element {
    const transactionID = props.tokenInfo.buyback_trx_chain_id ?
        props.tokenInfo.buyback_trx_chain_id : props.tokenInfo.trx_chain_id
    let totalValue = ""
    switch (props.category) {
        case "COLLECTABLES":
            totalValue = props.info.market_value
            break
        case "ART":
            totalValue = props.info.estimate
            break
        case "AIF":
        case "SPORTEX":
            totalValue = "unavailable"
    }
    return <>
        <GenericTab className=" md:mt-0">
            <div className="grid md:grid-cols-2 gap-2 lg:grid-cols-3 xl:grid-cols-4 ">
                <LabelAndDescription alternate label={"Asset Name "}
                    description={props.tokenInfo.asset_name} />
                <LabelAndDescription alternate label={"Token Ticker "}
                    description={props.tokenInfo.ticker} />
                {props.tokenInfo.legal_framework && props.tokenInfo.legal_framework !== "" ?
                    <LabelAndDescription alternate label={"Token Legal Framework "}
                        description={convertLegalFrameworkForFE(props.tokenInfo.legal_framework)} /> :
                    <></>}
                <LabelAndDescription alternate label={"Token Issuance Date "}
                    description={convertToSimpleDate(props.primarySaleDetails.date_start)} />
                <LabelAndDescription alternate label={"Percentage of Asset Tokenized "}
                    description={props.tokenInfo.pct_tokenized + "%"} />
                {totalValue === "unavailable" ? <></> :
                    <LabelAndDescription alternate label={"Tokenized Total Value"}
                        description={quantityFormat(totalValue)} />}
                <LabelAndDescription alternate label={"Token Supply"}
                    description={quantityFormat(props.tokenInfo.total_supply)} />
                <LabelAndDescription alternate label={"Tokens for Sale"}
                    description={quantityFormat(props.primarySaleDetails.total_supply)} />
                <LabelAndDescription alternate label={"Initial Token Price "}
                    description={euroFormat(props.primarySaleDetails.price) + " EUR"} />
            </div>
            {transactionID ? <div className="mt-4">
                <InlineContent label={"Transaction ID"} content={
                    <Link to={"/blockchain/transaction/" + transactionID}>{transactionID}</Link>
                } />
            </div> : <></>}
            <HTMLContent alternate={true} label={"TOKEN DESCRIPTION:"}
                description={props.tokenInfo.token_term_cond}
                liquidation_price={props.tokenInfo.buyback_price}
                liquidation_date={props.tokenInfo.date_buyback}
                className="mt-8 p-4 text-base font-roboto" />
        </GenericTab>
    </>
}