import { formatDatetime } from "../../../utils"
import TabInnerTitle from "./TabInnerTitle"

export default function HTMLContent(props: {
    label: string, description: string | any, className?: string,
    alternate?: boolean, liquidation_price?: string, liquidation_date?: string
}): JSX.Element {
    let liquidation = ""
    if (props.liquidation_date && props.liquidation_date !== "0001-01-01T01:01:01Z" &&
        props.liquidation_price && props.liquidation_price !== "0") {
        liquidation = "<br/>LIQUIDATION DATE: " + formatDatetime(props.liquidation_date) +
            "<br/><br/>LIQUIDATION PRICE: " + props.liquidation_price + ""
    }


    return props.description ?
        <div className={props.className + " bg-white_lightest rounded-lg p-4 shadow-xl shadow-black/50 " +
            (props.alternate ? " text-background" : " text-blue")}>

            {props.label ?
                <TabInnerTitle title={props.label} />
                : <></>}
            <div className="font-light tracking-wider whitespace-pre-wrap text-base lg:text-xl">
                <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
                <div dangerouslySetInnerHTML={{ __html: liquidation }}></div>
            </div>
        </div>
        : <></>
}