import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { actionCreators } from "../../state"
import { euroQtyFormat, euroFormat, quantityFormat } from "../../utils"
import { DexxButton } from "../light/LightButton"
import Popup from "../Popup"
import Slider from '@mui/material/Slider';


export default function RemoveLPPopup(props: {closeFunction: Function, uuidLP: string}) : JSX.Element {
    
    //let uuid = props.uuidLP
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const [detailsLPRequest, setDetailsLPRequest] = useState()
    const [issuedTokens, setIssuedTokens] = useState<any>()
    const [balance, setBalance] = useState<any>()

    const [uuid, setUuid] = useState<string>("")

    const [lpName, setLpName] = useState<string>("LP_")
    const [tokenA, setTokenA] = useState<string>("")
    const [tokenB, setTokenB] = useState<string>('XEUR')
    const [assetName, setAssetName] = useState<string>("")
    const [tokenAName, setTokenAName] = useState<string>("")
    const [tokenBName, setTokenBName] = useState<string>("")
    const [maxTokenA, setMaxTokenA] = useState<number>(0)
    const [maxIssuerTokenA, setMaxIssuerTokenA] = useState<number>(0)
    const [maxTokenB, setMaxTokenB] = useState<number>(0)

    const [lpAmountA, setLpAmountA] = useState<number>(0)
    const [lpAmountB, setLpAmountB] = useState<number>(0)
    const [shares, setShares] = useState<any[]>([])
    const [lpShares, setLpShares] = useState<number>(0)
    const [sharesOwned, setSharesOwned] = useState<number>(0)
    const [sharesOwnedPercTotal, setSharesOwnedPercTotal] = useState<number>(0)
    const [percToRemove, setPercToRemove] = useState<number>(0)
    const [sharesToRemove, setSharesToRemove] = useState<number>(0)
    const [tokenToReceive, setTokenToReceive] = useState<number>(0)
    const [xeurToReceive, setXeurToReceive] = useState<number>(0.00)


    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    //const [showConfirmPopUp, setConfirmShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    const [request, setRequest] = useState<any>()

    let issuedTokensList = issuedTokens?.listed_tokens ? issuedTokens.listed_tokens : []

    useEffect(() => {
        setUuid(props.uuidLP)
    }, [props.uuidLP])
    
    useEffect(() => {
        if(uuid) actions.getDetailsLiquidityPool(uuid, setDetailsLPRequest)
        actions.getIssuedTokens(setIssuedTokens)
        actions.getActualBalance(setBalance)
    }, [uuid])

    function updateShares() {
        if(detailsLPRequest && detailsLPRequest[0] === 200) {
            let l : any = detailsLPRequest[1]
            setTokenAName(l.token_a_ticker)
            setTokenBName(l.token_b_ticker)
            setTokenA(l.token_a_uuid)
            setTokenB(l.token_b_uuid)
            setLpName(l.lp_name)
            setAssetName(l.asset_a_name)
            setLpAmountA(l.amount_a)
            setLpAmountB(l.amount_b)
            actions.getInvestorShares(l.uuid, true, setShares)
         }
    }
    useEffect(() => {
        updateShares()
    }, [detailsLPRequest])

    useEffect(() => {
        if(shares && shares[0] && shares[0].LPWE.qty_lp_token > 0){
            setLpShares(shares[0].LP.lp_token_supply)
            setSharesOwned(shares[0].LPWE.qty_lp_token)
            setSharesOwnedPercTotal(100 * shares[0].LPWE.qty_lp_token / shares[0].LP.lp_token_supply)
        }
            
    }, [shares])

    useEffect(()=> {
        let tokens = issuedTokensList.filter((t: any) => t.uuid === tokenA)
        let newMax = tokens.length > 0 ? tokens[0].total_available : 0
        setMaxIssuerTokenA(newMax)
    }, [tokenA, issuedTokensList])

    useEffect(() => {
        if(balance && balance.payment_tokens) {
            setMaxTokenB(balance.payment_tokens.available_total)
        }

        let cont_investor_balance = 0
        if(balance && balance.balance) {
            for (let r of balance.balance.security_entries) {
                for (let e of r.class_balance) {
                    if(e.token_uuid === tokenA)
                        cont_investor_balance = e.available_quantity
                }
            }
        }
        setMaxTokenA(cont_investor_balance)
    }, [balance, tokenA])

    useEffect(() => {
        if(request && request.length > 0) {
            if(request[0] === 200 || request[0] === 204) {
                setTitlePopUp("Success")
                setMsgPopUp("Your request has been processed.")
                
            } else {
                setTitlePopUp("Sorry!")
                //setMsgPopUp(request && request[1] && request[1].error ? request[1].error : "There was an error while executing the request")
                setMsgPopUp("We encountered an error saving your data.")
            }
            setShowPopUp(true)
        }
    }, [request])

    function handleSubmit() {
        let requestInfo = {
            lp_uuid: uuid,
            lp_token_amount: sharesToRemove,
        }
        actions.removeLiquidityPool(requestInfo, setRequest)
    }

    function handleChangeSlider(event: Event, newValue: number | number[]){
        let value = newValue as number
        setPercToRemove(value);
        let sharesToRemove = Math.ceil(sharesOwned * value / 100)
        if(sharesToRemove !== sharesOwned * value / 100){
            setPercToRemove(Math.ceil(sharesToRemove / sharesOwned * 10000) / 100);
        }
        setSharesToRemove(sharesToRemove)
        let token = Math.floor(lpAmountA * sharesToRemove / lpShares)
        setTokenToReceive(token)
        let xeur = Math.floor(lpAmountB * sharesToRemove / lpShares * 100) / 100
        setXeurToReceive(xeur)
      };

    function labelAndValue(label: string, value: any, colorVal?: string, colorLabel?:string) {
        
        let cV = "gray-5"
        let cL = "primary"
        if(colorVal) cV = colorVal
        if(colorLabel) cL = colorLabel
        return <>
            <div className={"text-" + cL + " font-semibold text-sm"}>
                {label}
                <div className={"font-normal text-sm text-" + cV}>{value}</div>
            </div>
        </>
    }

    return <>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} 
            close={() => {setShowPopUp(false); props.closeFunction()}} />
        <div className="w-128 h-full font-roboto mt-1">
            
            <div className="pl-10 grid grid-cols-2">
                <div className="col-span-2">
                    <div className={"text-succ8 text-sm font-medium"}>
                        My Balance</div>
                </div>
            </div>
            <div className="pl-10">
                <div className="grid grid-cols-2">
                    {labelAndValue(tokenAName+":", quantityFormat(maxTokenA), "blue", "blue")}
                    {labelAndValue(tokenBName+":", euroFormat(maxTokenB), "blue", "blue")}
                </div>
            </div>

            <div className="bg-gray_lines h-px w-full my-1"/>

            <div className="pl-10">
                <div className="grid grid-cols-2">
                    {labelAndValue("Asset Name:", assetName, "gray_lines", "gray_lines")}
                    {labelAndValue("Ticker:", tokenAName, "gray_lines", "gray_lines")}
                </div>
            </div>

            <div className="bg-gray_lines h-px w-full my-1"/>

            <div className="pl-10 grid grid-cols-3">
                <div className="col-span-2">
                    <div className={"text-succ8 text-sm font-medium"}>
                        My LP Balance</div>
                </div>
            </div>
            <div className="pl-10">
                <div className="grid grid-cols-1">
                    {labelAndValue("SHARES:", quantityFormat(sharesOwned) + " (" + euroFormat(sharesOwnedPercTotal) + "% of the total)", "blue", "blue")}
                </div>
            </div>

            <div className="bg-gray_lines h-px w-full my-1"/>
            
            <div className="pl-10 mt-1">
                <div className={"text-succ8 font-normal text-sm"}>
                    Reserves of <span className="font-semibold">{lpName}</span>
                </div>
            </div>
            <div className="pl-10">
                <div className="grid grid-cols-2">
                    {labelAndValue(tokenAName+":", euroQtyFormat(lpAmountA), "gray_lines", "gray_lines")}
                    {labelAndValue(tokenBName+":", euroFormat(lpAmountB), "gray_lines", "gray_lines")}
                </div>
            </div>
            
            <div className="bg-gray_lines h-px w-full mt-1"/>

            <div className={"text-sm pt-2 pb-1 bg-gradient-to-r from-succ8 to-transparent text-center"}>

                <div className="mt-1">
                    How many shares will you remove from <span className="font-semibold uppercase">{lpName}</span>?
                </div>
                <div className="text-primary text-4xl font-urbanist font-semibold mt-1">
                    {quantityFormat(sharesToRemove)} 
                </div>

                <div className="justify-between gap-2 align-middle items-center px-10">
                    

                    <Slider
                        aria-label="% of Shares to remove"
                        defaultValue={percToRemove}
                        valueLabelDisplay="off"
                        step={1}
                        marks={false}
                        min={0}
                        max={100}
                        onChange={handleChangeSlider}
                        sx={{
                            height: 8,
                            color: '#C887DF',
                            '& .MuiSlider-thumb': {
                              borderRadius: '8px',
                              backgroundImage: 'url(https://dexx-public.s3.eu-south-1.amazonaws.com/image/logoCursor.png)',
                              backgroundSize: "contain",
                              backgroundPosition: "center center",
                              backgroundRepeat: "no-repeat",
                            },
                          }}
                    />
                </div>
                <div className="text-primary text-2xl font-urbanist font-semibold">
                    {Math.round(percToRemove) + "%"} 
                </div>
            </div>

            <div className="bg-gray_lines h-px w-full"/>

            <div className="pl-10 mt-1">
                <div className={"text-succ8 font-normal text-sm"}>
                    Reserves to receive from <span className="font-semibold">{lpName}</span>
                </div>
            </div>
            <div className="pl-10">
                <div className="grid grid-cols-2">
                    {labelAndValue(tokenAName+":", euroQtyFormat(tokenToReceive), "gray_lines", "gray_lines")}
                    {labelAndValue(tokenBName+":", euroFormat(xeurToReceive), "gray_lines", "gray_lines")}
                </div>
            </div>
            
            <div className="bg-gray_lines h-px w-full mt-1"/>

            <div className="pl-10 mt-2 mb-5 font-roboto">
                <div className="text-succ8 text-sm font-medium">
                    Review</div>
                <div className="text-primary text-sm font-urbanist font-normal">
                    % Share to redeem:
                    <strong>{Math.round(percToRemove) + "%"}</strong>
                </div>
                <div className="text-primary text-sm font-urbanist font-normal">
                    Share to redeem:
                    <strong>{euroQtyFormat(sharesToRemove)}</strong>
                </div>
            </div>

        </div>
        <div className="flex justify-between px-6 pb-2">
            <DexxButton onClick={() => {
                    updateShares();
                    props.closeFunction()}} 
                label={"close"} fontClasses="text-sm font-medium"
                className="rounded-full px-8 py-3 bg-white drop-shadow-n" color1='white' color2='gray5'/>
            <DexxButton onClick={() => {
                    handleSubmit()
                    updateShares()}} label={"confirm"} 
                fontClasses="text-sm  font-medium" className="rounded-full px-16 bg-white py-3" 
                color1='white' color2='primary'/>
        </div>
    </>
}