import  arrowsImg  from "../../media/arrows.png"
export default function LightTable(props: {headers: any[], rows: any[][], color1?: string, color2?: string, colorHd?: string, noBorders?: boolean, columnWidth?: any[]}) {

    const c1 = props.color1 ? props.color1 : ""
    const c2 = props.color2 ? props.color2 : c1
    const chd = props.colorHd ? props.colorHd : c1
    return <>
        <table className={"uppercase font-urbanist w-full text-primary bg-tertiary text-center border-collapse " + 
                            (props.columnWidth ? "lg:table-fixed" : "")}>

            <thead>
                <tr className={"leading-7 font-medium py-2 text-sm bg-"+chd}>
                    {props.headers.map((header, headerIndex) => 
                        <th key={headerIndex.toString()+header}
                            className={"border-solid border-0 border-secondary py-1 "+
                                (props.noBorders ? "" : " border-r-1 ") +
                                (props.columnWidth ? props.columnWidth[headerIndex] : "")}>

                                {header}</th>)}
                </tr>
            </thead>
            <tbody>
                {props.rows.map((row, rowIndex) =>
                    
                    <tr className={"font-code-pro font-medium text-base py-2 bg-"+(rowIndex % 2 === 0 ? c1 : c2)} 
                        key={rowIndex.toString()+row}>
                        {row.map((cell, colIndex) =>
                            <td key={rowIndex-colIndex+cell} className={"border-0 "+(props.noBorders ? " " : " border-r-1 ")+
                                " border-solid border-wild_blue_yonder leading-10 "+
                                    (colIndex%2===1 ? "text-primary" : "text-primary") }> 
                                {cell}
                            </td>
                        )}
                    </tr>                    
                )}
            </tbody>
        </table>
    </>
}

export function DescriptiveTable(props: {headers: any[], rows: any[][], color1?: string, color2?: string, colorHd?: string, noBorders?: boolean}) {
    const c1 = props.color1 ? props.color1 : ""
    const c2 = props.color2 ? props.color2 : c1
    const chd = props.colorHd ? props.colorHd : c1
    return <>
        <table className={"font-urbanist w-full text-white bg-transparent text-left border-collapse mt-5"}>
            <thead>
                <tr className={"leading-7 font-medium py-2 text-base text-center bg-transparent m-0"}>
                    {props.headers.map((header, headerIndex) => 
                        <th key={headerIndex.toString()+header}
                            className={"border-solid border-1 border-white py-1"}>
                                {header}</th>)}
                </tr>
            </thead>
            <tbody>
                {props.rows.map((row, rowIndex) =>
                    
                    <tr className={"font-code-pro font-medium text-sm py-2 bg-transparent"} 
                        key={rowIndex.toString()+row}>
                        {row.map((cell, colIndex) =>
                            <td key={rowIndex-colIndex+cell} className={"border-1 p-5"+
                                " border-solid border-white leading-2" }> 
                                {cell}
                            </td>
                        )}
                    </tr>                    
                )}
            </tbody>
        </table>
    </>
}

export function DexxTable(props: { headers: any[], rows: any[][], alignment: ("left" | "right" | "center")[], 
        className?: string, noPadding?: boolean, colors?: ("dark" | "light")}): JSX.Element {
    
    var headerBG = "moonstone_blue"
    var headerText = "white"
    if(props.colors && props.colors === "dark") {
        headerBG = "navy_blue"
        headerText = "white"
    }
    
    let sortedRows = props.rows
    
    return <>
    <div className="overflow-x-auto w-full ">
        <table className={"border-1 border-solid border-gray border-collapse bg-platinum "+
                " w-full "+ props.className}>
            <thead className="">
                <tr className={"bg-"+headerBG+" text-"+headerText+" whitespace-nowrap"}>
                    {props.headers.map((header, headerIndex) => 
                        <th key={headerIndex.toString()}
                            className={"border-solid border-0 border-gray py-1 text-xs md:text-sm capitalize " + (typeof header === "string" ? "" : "cursor-pointer")}
                            onClick={typeof header === "string" ? undefined :  () => header.sort()}>
                                {typeof header === "string" ? 
                                    <div className="flex justify-items-center justify-center items-center ">
                                        {header}
                                    </div> :
                                
                                    <div className="flex justify-items-center justify-center items-center hover:bg-primary hover:text-background">
                                        {header.name}
                                        <img src={arrowsImg} className=" ml-1"/>
                                    </div>}
                        </th>)}
                </tr>
            </thead>
            <tbody className="">
                {sortedRows.map((row, rowIndex) =>    
                    <tr className={"font-code-pro font-medium text-xs py-2 h-10 mx-8 whitespace-nowrap"} 
                        key={rowIndex.toString()+row}>
                        {row.map((cell, colIndex) =>
                            <td key={rowIndex-colIndex+cell} className={"border-t-1 border-0 border-r-1 "+
                                " bg-"+headerBG+(rowIndex % 2 === 0 ? "_light " : "_lighter ") +
                                " border-solid border-gray text-primary "+
                                " text-" + props.alignment[colIndex] + " " +
                                (props.noPadding ? "px-1" :
                                (colIndex === 0 ? " pl-1 md:pl-16 pr-1 " : 
                                    (colIndex === row.length - 1 ? " pr-1 md:pr-16 pl-1 " : "px-1 ")))}> 
                                {cell}
                            </td>
                        )}
                    </tr>                    
                )}
            </tbody>
        </table>
    </div>
    </>
}
