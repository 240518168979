// Should replace DexxChart

import { createChart, TickMarkType, Time } from "lightweight-charts";
import { useEffect, useState } from "react";

export interface ChartData {
    close: string,
    high: string,
    low: string,
    open: string,
    volume: string,
    reference_date: string
}

function SetRangeButton(props: { onChange: Function, label: string }): JSX.Element {
    return <div
        onClick={() => props.onChange()}
        className={"border-solid border-primary border-2 " +
            " bg-primary_lighter hover:bg-primary rounded-md px-2 py-1 cursor-pointer"}>
        {props.label}
    </div>
}

export default function DexxCandleChart(props: { serie: ChartData[] }): JSX.Element {

    let defaultRange = {from: 334, to: 364}
    const [range, setRange] = useState<{to: number, from: number}>(defaultRange)
    
    useEffect(() => {
        const areaSerie = props.serie.map((v) => { return { time: v.reference_date.substring(0, 10), value: parseFloat(v.volume) } })
        const candleSerie = props.serie.map((v) => {
            return {
                time: v.reference_date.substring(0, 10),
                open: parseFloat(v.open),
                high: parseFloat(v.high),
                low: parseFloat(v.low),
                close: parseFloat(v.close)
            }
        })
        
        const chartOptions = {
            layout: {
                textColor: 'white',
                background: { color: '#0A3C6099' },
            },
            grid: {
                vertLines: {
                    visible: false
                },
                horzLines: {
                    visible: false
                }
            }
        };
        const chart = createChart('container', chartOptions);
        
        const volumeSeries = chart.addHistogramSeries({
            color: '#6C8FA999',
            priceFormat: {
                type: 'volume',
            },
            priceScaleId: '',
        });
        volumeSeries.priceScale().applyOptions({
            scaleMargins: {
                top: 0.75,
                bottom: 0,
            },
        });

        volumeSeries.setData(areaSerie);
        const candlestickSeries = chart.addCandlestickSeries({
            upColor: '#26a69a', downColor: '#ef5350', borderVisible: true,
            wickUpColor: '#26a69a', wickDownColor: '#ef5350',
        });
        candlestickSeries.priceScale().applyOptions({ 
            scaleMargins: {
                top: 0.1,
                bottom: 0.4,
            },
        });
        candlestickSeries.setData(candleSerie);

        chart.timeScale().setVisibleLogicalRange(range)
        return () => {
            chart.remove();
        };


    }, [props.serie, range])

    return <>
        <div className="text-white">
            <div id="container" className="w-full min-h-50"></div>
            <div className=" text-xs mt-2">
                <div>TradingView Lightweight Charts™</div>
                <div>Copyright (с) 2024 TradingView, Inc. <a className="text-succ2" 
                    href="https://www.tradingview.com/ ">https://www.tradingview.com</a></div>
            </div>
            <div className="uppercase text-white flex justify-center
                            text-sm mt-4 gap-2">
                        <SetRangeButton onChange={() => { setRange({from: 358, to: 364}) }}
                            label={"1 week"} />
                        <SetRangeButton onChange={() => { setRange(defaultRange) }}
                            label={"1 month"} />
                        <SetRangeButton onChange={() => { setRange({from: 0, to: 364}) }}
                            label={"1 year"} />
                    </div>
        </div>
    </>
}